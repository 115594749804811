import React, { useEffect, useState } from 'react'
import Header from '../../components/Header'
import SearchInput from '../../components/SearchInput'
import { authAxios } from '../../helpers/axios'
import { toast } from 'react-toastify'
import FilterSelect from '../../components/FilterSelect'
import { useNavigate } from 'react-router-dom'
import ManageSample from './components/ManageSample'
import { base64ToBlob, dataURItoBlob, getDateFormat } from '../../helpers/utils'
import { ErrorToast, InfoToast } from '../../helpers/toasters'
import SortIcon from '../../images/sort.svg'
import RightIcon from '../../images/right.svg'
import FilterIcon from '../../images/filter.svg'
import RupeeIcon from '../../images/rupee.svg'
import { LIMIT } from '../../helpers/constants'
import ReactDataGrid from 'react-data-grid'
import { PlusIcon } from 'lucide-react'
import Pagination from '../../components/Pagination'

export default function Sample() {

    const [searchText,setSearchText] = useState('')
    const [samples,setSamples] = useState([])
    const [showCreate,setShowCreate] = useState({status:false,edit:null})

    const [cityFilters,setCityFilters] = useState([])
    const [routeFilters,setRouteFilters] = useState([])
    const navigation = useNavigate()
    const [sort,setSort] = useState('-createdAt');
    const [offset,setOffset] = useState(0);
    const [count,setCount] = useState(0)

    const RightContent = ()=>
{
    return <>
    <div className='flex justify-end'>
        <Pagination count={count} setOffset={setOffset} offset={offset}/>
        <div className="mr-2">
            <SearchInput value={searchText} setValue={(value)=>setSearchText(value)} placeholder={'Search Sample'} label={'Search Sample'} />
        </div>
        <div className="flex items-center">
            <button className='rounded-full' onClick={()=>setShowCreate({status:true,edit:false})}><PlusIcon fontSize={40} lightingColor={'#fff'} color='#182159' className='ml-2'/></button>
        </div>
        </div>
        </>
}

    const columns = [
        { key: 'sampleId', name: 'Sample ID', frozen: true },
        { key: 'sponsorName', name: 'Sponsor Name' ,width:250,frozen:true,renderCell:({row})=><p>{row.sponsor ? row.sponsor.name : 'Not Selected'}</p>},
        { key: 'testItemName', name: 'Test Item' ,width:250,renderCell:({row})=><p>{row.tids ? row.tids.testItemName : 'Not Linked'}</p>},
        { key: 'quantity', name: 'Total Qty.',width:150 },
        { key: 'status', name: 'Status',width:150,renderCell:({row})=>{return (row.tidsId && row.sponsorId) ? <p className='text-xs p-3 align-middle inline-block bg-green-200 py-1 rounded-md text-green-700 font-medium text-center'>Linked</p> : <p className='text-xs p-3 align-middle inline-block text-red-700 bg-red-200 font-medium py-1 rounded-md text-center'>Not Linked</p>}},
        { key: 'receivedDate', name: 'Date of Receipt' ,renderCell:({row})=><p>{getDateFormat(row.receivedDate)}</p>},
        { key: 'rackLocation',width:150, name: 'Rack Location',renderCell:({row})=>{
                let itemNames = [];
                row.rackitems.forEach(item => {
                    const itemName = `${item.rack.name}${item.row}-P${item.column}`;
                    itemNames.push(itemName);
                });
                return <p>{itemNames.join(', ')}</p>
        }},
        { key: 'referenceName',width:250, name: 'Reference Name',renderCell:({row})=><p>{(row.referenceName) ? row.referenceName : '-'}</p>},
        { key: 'isDamaged',width:100, name: 'Damaged?',renderCell:({row})=><p>{row.isDamaged ? 'Yes' : 'No'}</p>},
        { key: 'modeOfReceiving',width:150, name: 'Mode Of Receiving',renderCell:({row})=><p className='capitalize'>{(row.modeOfReceiving) ? row.modeOfReceiving : '-'}</p>},
      ];
    

    const [selectedFilters,setSelectedFilters] = useState({city:'',route:''})


    const onSortPress = (type)=>
    {
        if (sort === type) {
            if(sort === `-${type}`) setSort(type)
            else setSort(`-${type}`);
          } else {
            setSort(type);
          }
    }

    async function getSamples(){

        try 
        {
            let query = `&limit=${LIMIT}&offset=${offset}&orderBy=${sort}`;
                if(searchText) query+= `&search=${searchText}`;
            let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/sample?populate=true${query}`)
            if(res.data)
            {
                setSamples(res.data.samples)
                setCount(res.data.totalCount)
            }
        } catch (error) {
            toast('Error getting samples')
        }
    }
    useEffect(()=>
    {
        getSamples();
    },[sort,searchText,offset])


    const onSubmit = async(e,data,yearCode)=>
    {
        try 
        {
            e.preventDefault()
            let res;
            if(showCreate.edit)
            {
                res = await authAxios.put(`${process.env.REACT_APP_BASE_URL}/sample/${showCreate.edit}`,data) 
                InfoToast('Sample Updated')
                
            }
            else
            {
                res = await authAxios.post(`${process.env.REACT_APP_BASE_URL}/sample`,{...data,sampleId:`${yearCode}-${data.sampleId}`})
                InfoToast('Sample Created')
            }
            if(res.data)
            {
                await getSamples()
                setShowCreate({status:false,edit:null})
            }
            else toast('error updating retailer')
        } catch (error) {
            // console.log(error.response.data.error[0])
            ErrorToast(error.response.data.name)
        }
    }


  return (
    <>
        <Header title={'Sample Management'} RightContent={()=><RightContent/>}/>
        <div className='flex flex-1 bg-white w-full h-full overflow-scroll'>
        <ReactDataGrid
        rowHeight={40}
        onCellClick={(args)=>navigation(`/samples/${args.row.id}`)}
        rows={samples}
        className='w-full h-full'
      columns={columns}
      enableCellSelect={false}
    />
        </div>
        { showCreate.status ? <ManageSample onClose={setShowCreate} onSubmit={onSubmit} edit={showCreate.edit}/> : null}
    </>
  )
}



