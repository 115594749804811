import { useEffect, useState } from "react"
import Input from "../../../components/Input"
import Map from "../../../components/Map"
import Popup from "../../../components/Popup"
import Select from "../../../components/Select"
import { authAxios } from "../../../helpers/axios"
import ImageInput from "../../../components/ImageInput"
import CropPopup from "../../../components/CropPopup"
import { RETAILER_IMAGE_BASE_URL } from "../../../helpers/constants"
import { dataURItoBlob } from "../../../helpers/utils"
import SlidePopup from "../../../components/SlidePopup"

export default function ManageSample({onClose,edit=false,onSubmit,updateData})
{
    const [manageData,setManageData] = useState({sponsorId:'',tidsId:'',sampleId:'',quantity:'',referenceName:'',lotNumber:'',serialNumber:'',rackPosition:[]})
    const [sponsors,setSponsors] = useState([])
    const [hsn,setHsn] = useState([])
    const [showCrop,setShowCrop] = useState(false)
    const formName = 'editRetailer';
    const [racks,setRacks] = useState([])
    const [croppedImage,setCroppedImage] = useState()
    const [tests,setTests] = useState([])
    const [tids,setTids] = useState([])
    const [warehouses,setWarehouses] = useState([])
    const [sampleId,setSampleId] = useState() 
    const [rackInfo,setRackInfo] = useState(null)

    
        useEffect(()=>
        {
            async function getSponsors(){
                let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/sponsor`)
                setSponsors(res.data)
            }

            async function getSampleId(){
                let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/sample/initiate`)
                setSampleId(res.data)
                setManageData(prev=>({...prev,sampleId:res.data.nextSampleNumber}))
            }
            async function getWarehouses(){
                let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/warehouse`)
                setWarehouses(res.data)
            }
            // async function getHsn(){
            //     let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/hsn`)
            //     setHsn(res.data.data)
            // }
            // async function getSampleInfo(){
            //     let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/sample/${edit}`)
            //     // setSelectedTests()
            //     setManageData({...res.data,rackPosition:[]})
            // }
            // getSampleInfo()
            getSampleId()
            getWarehouses()
            getSponsors()
        },[])
        
        useEffect(()=>
        {
            async function getRacks(){
                if(manageData.warehouseId && manageData.warehouseId != '')
                {
                    let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/rack?warehouseId=${manageData.warehouseId}`)
                    setRacks(res.data)
                }
            }
            getRacks()
        },[manageData.warehouseId])


        useEffect(()=>
        {
            async function getTids(){
                if(manageData.sponsorId && manageData.sponsorId != '')
                {
                    let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/tids?sponsorId=${manageData.sponsorId}`)
                    setTids(res.data)
                }
            }
            getTids()
        },[manageData.sponsorId])
        
        useEffect(()=>
        {
            async function getRackInfo(){
                if(manageData.rackId && manageData.rackId != '')
                {
                    let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/rack/${manageData.rackId}?populate=true`)
                    setRackInfo(res.data)
                }
            }
            getRackInfo()
        },[manageData.rackId])

        const toggleRackPosition = ({ row, column }) => {
            // Check if an object with the same row and column values exists in the rackPosition array
            const existingIndex = manageData.rackPosition.findIndex(item => item.row === row && item.column === column);
        
            // If an object with the same row and column values exists, remove it from the array
            if (existingIndex !== -1) {
                const updatedRackPosition = [...manageData.rackPosition];
                updatedRackPosition.splice(existingIndex, 1);
                setManageData(prevState => ({
                    ...prevState,
                    rackPosition: updatedRackPosition
                }));
            } else {
                // If no object with the same row and column values exists, add the object to the array
                setManageData(prevState => ({
                    ...prevState,
                    rackPosition: [...prevState.rackPosition, { row, column }]
                }));
            }
        };
        






    return <><SlidePopup size="md" title={edit ? 'Update Sample' : 'Create Sample'} submitTitle={edit ? 'Update' : 'Create'} onClose={onClose} formName={formName}>
        <form onSubmit={(e)=>onSubmit(e,{...manageData},sampleId.yearCode)} id={formName} className='w-full'>
            <div className="grid grid-cols-2 gap-x-3 gap-y-0 w-full">
            <div>
                <label>Sponsor Name*</label>
                <Select placeholder={'Select Sponsor'} required={false} customLabel={'name'} options={sponsors} customValue={'id'} value={manageData.sponsorId} setValue={(value)=>setManageData(data=>({...data,sponsorId:value}))}/>
            </div>
            <div>
                <label>Sample Id</label>
                <div className="relative">
                    <p className="text-xs absolute top-[10.25px] left-4 z-10">{sampleId?.yearCode} - </p>
                    <Input extraClasses={'pl-11'} type='text' placeholder={'Sample Id'} required={true} value={manageData.sampleId} setValue={(value)=>setManageData(data=>({...data,sampleId:value}))}/>
                </div>
            </div>
            {!(manageData.tidsId && manageData.sponsorId) ? <div>
                <label>Reference Name</label>
                <Input type='text' placeholder={'Enter Reference Name'} required={false} value={manageData.referenceName} setValue={(value)=>setManageData(data=>({...data,referenceName:value}))}/>
            </div> : null}
            <div>
                <label>Date of Receipt</label>
                <Input type='date' placeholder={'Date of Receipt'} required={true} value={manageData.dateOfReceipt} setValue={(value)=>setManageData(data=>({...data,dateOfReceipt:value}))}/>
            </div>
            </div>
            <div className="grid grid-cols-2 gap-x-3 gap-y-0 w-full border-t border-t-gray-200 pt-6 my-4">
            <div>
                <label>Quantity</label>
                <Input type='number' placeholder={'Enter Quantity'} required={true} value={manageData.quantity} setValue={(value)=>setManageData(data=>({...data,quantity:value}))}/>
            </div>
            <div>
                <label>Lot Number</label>
                <Input type='text' placeholder={'Lot Number'} required={true} value={manageData.lotNumber} setValue={(value)=>setManageData(data=>({...data,lotNumber:value}))}/>
            </div>
            <div>
                <label>Serial Number</label>
                <Input type='text' placeholder={'Serial  Number'} required={true} value={manageData.serialNumber} setValue={(value)=>setManageData(data=>({...data,serialNumber:value}))}/>
            </div>
            <div>
                <label>Mode of Receiving</label>
                <Select placeholder={'Select Mode'} required={false} customLabel={'name'} options={[{name:'By Hand',id:'by-hand'},{name:'Courier',id:'courier'}]} customValue={'id'} value={manageData.modeOfReceiving} setValue={(value)=>setManageData(data=>({...data,modeOfReceiving:value}))}/>
            </div>
            <div>
                <label>Is Damaged?</label>
                <Select placeholder={'Select Yes/No'} required={false} customLabel={'name'} options={[{name:'Yes',id:true},{name:'No',id:false}]} customValue={'id'} value={manageData.isDamaged} setValue={(value)=>setManageData(data=>({...data,isDamaged:value}))}/>
            </div>
            <div>
                <label>Warehouse</label>
                <Select placeholder={'Select Warehouse'} required={false} customLabel={'name'} options={warehouses} customValue={'id'} value={manageData.warehouseId} setValue={(value)=>setManageData(data=>({...data,warehouseId:value}))}/>
            </div>
            <div>
                <label>Rack</label>
                <Select placeholder={'Select Rack'} required={false} customLabel={'name'} options={racks} customValue={'id'} value={manageData.rackId} setValue={(value)=>setManageData(data=>({...data,rackId:value}))}/>
            </div>

            <div>
                {rackInfo ? <RackItem manageData={manageData} item={rackInfo} onClick={toggleRackPosition} /> : null}
            </div>
            </div>
        </form>
    </SlidePopup>
    </>
}


const RackItem = ({item,onClick,manageData})=>
{

    const isItemInRackPosition = ({ row, column }) => {
        // Check if an object with the same row and column values exists in the rackPosition array
        return manageData.rackPosition.some(item => item.row === row && item.column === column);
    };

    const [rowCount, setRowCount] = useState(item.rackTypeInfo.rows);
    const [columnCount, setColumnCount] = useState(item.rackTypeInfo.columns);
    const [position,setPosition] = useState('')
    useEffect(()=>
    {
        setRowCount(item.rackTypeInfo.rows)
        setColumnCount(item.rackTypeInfo.columns)
    },[])


    return <div className='bg-[#fafafa] rounded-md overflow-hidden p-2'>
        <div className='text-center mb-2'>
            <p className='text-xs font-semibold uppercase text-[#959595]'>{item?.name}</p>
        </div>
        <div className={`grid grid-cols-${columnCount} grid-rows-${rowCount} gap-1 relative pl-4`}>
            {
                item?.rackitems?.map((item,index)=>
                {
                    return <RackItems onClick={onClick} manageData={manageData} item={item} key={index}/>
                })
            }
        </div>
    </div>
}

const RackItems = ({item,manageData,onClick})=>
{
    const [isSelected,setIsSelected] = useState(false)

    useEffect(()=>
    {
        if(manageData.rackPosition.some(it => it.row === item.row && it.column === item.column))
        {
            setIsSelected(true)
        }
        else setIsSelected(false)
    },[manageData.rackPosition])

    return <div onClick={()=>onClick({row:item.row,column:item.column})} className={`flex justify-center items-center w-full text-[9px] h-[20px] py-4 rounded-md ${!item.sampleId ? isSelected ? 'bg-[#182159] text-white' : 'bg-[#e3e3e3] hover:bg-[#c3c3c3] cursor-pointer' : 'bg-[#ff1111] cursor-not-allowed'}`}>{item.sampleId ? 'R' : 'E'}</div>
}
