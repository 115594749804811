import { useEffect, useState } from "react"
import Input from "../../../components/Input"
import Map from "../../../components/Map"
import Popup from "../../../components/Popup"
import Select from "../../../components/Select"
import { authAxios } from "../../../helpers/axios"
import ImageInput from "../../../components/ImageInput"
import CropPopup from "../../../components/CropPopup"
import { RETAILER_IMAGE_BASE_URL } from "../../../helpers/constants"
import { dataURItoBlob, getDateFormat } from "../../../helpers/utils"
import SlidePopup from "../../../components/SlidePopup"

export default function ManageProject({onClose,edit=false,onSubmit,updateData})
{
    const [manageData,setManageData] = useState({testItemName:'',file:'',sponsorId:'',uniqueNumber:'',mode:'live',tests:[{id:'',studyNumber:'',sdId:''}]})
    const [sponsors,setSponsors] = useState([])
    const [showCrop,setShowCrop] = useState(false)
    const formName = 'editRetailer';
    const [image,setImage] = useState(null)
    const [croppedImage,setCroppedImage] = useState()
    const [sds,setSds] = useState([])
    const [tests,setTests] = useState([])
    const [showTest,setShowTest] = useState(false)

    
        useEffect(()=>
        {
            async function getSponsors(){
                let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/sponsor`)
                setSponsors(res.data)
            }
            async function getTests(){
                let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/test`)
                setTests(res.data)
            }
            async function getSds(){
                let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/user?role=sd`)
                setSds(res.data)
            }
            async function getTidsInfo(){
                let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/tids/${edit}`)
                // setSelectedTests()
                setManageData({...res.data})
            }
            // getTidsInfo()
            getSponsors()
            getTests()
            getSds()
        },[])
       
        useEffect(()=>
        {
            async function getStudyNumber(){
                let res = await authAxios.post(`${process.env.REACT_APP_BASE_URL}/project/study-number`,{sponsorId:manageData.sponsorId})
                setManageData(prev=>({...prev,uniqueNumber:res.data.uniqueNumber,tests:[{...prev.tests[0],studyNumber:res.data.nextStudyNumber}]}))
                setShowTest(true)
            }
            if(manageData.sponsorId) getStudyNumber()
        },[manageData.sponsorId])
        
          const handleTestAdd = () => {
            setManageData(prev => {
                // Get the studyNumber of the last item in the tests array
                const lastStudyNumber = prev.tests.length > 0 ? prev.tests[prev.tests.length - 1].studyNumber : 0;
                // Increment the studyNumber by one
                const newStudyNumber = lastStudyNumber + 1;
              
                // Add the new item to the tests array with the incremented studyNumber
                return {
                  ...prev,
                  tests: [
                    ...prev.tests,
                    { testId: '', studyNumber: newStudyNumber, sdId: '' }
                  ]
                };
              });
          };


    const handleFileChange = (event) => {
        setManageData(prev=>({...prev,file:event.target.files[0]}));
    };





    return <><SlidePopup size="md" title={edit ? 'Update Project' : 'Create Project'} submitTitle={edit ? 'Update' : 'Create'} onClose={onClose} formName={formName}>
        <form onSubmit={(e)=>onSubmit(e,{...manageData})} id={formName} className='block grid-cols-2 gap-x-3 w-full'>
            <div className="grid grid-cols-2 gap-x-3 gap-y-0 w-full">
            
            <div className="col-span-1 mb-4">
                <label>TIDS Document*</label>
                <input className="text-input" type="file" placeholder={'Enter Test Item Name'} required={true} onChange={handleFileChange}/>
            </div>
            <div className="col-span-1 mb-4">
                <label>Mode</label>
                <Select placeholder={'Select Mode'} required={true} options={[{value:'draft',name:'Draft'},{value:'live',name:'Live'}]} value={manageData.mode} setValue={(value)=>setManageData(data=>({...data,mode:value}))}/>
            </div>
            <div>
                <label>Sponsor Name*</label>
                <Select placeholder={'Select Sponsor'} required={true} customLabel={'name'} options={sponsors} customValue={'id'} value={manageData.sponsorId} setValue={(value)=>setManageData(data=>({...data,sponsorId:value}))}/>
            </div>
            <div>
                <label>Test Item Name*</label>
                <Input placeholder={'Enter Test Item Name'} required={true} value={manageData.testItemName} setValue={(value)=>setManageData(data=>({...data,testItemName:value}))}/>
            </div>
            <div className="col-span-2">
                <label>Select Study Tests</label>
                {manageData.tests?.map((selectedTest, index) => (
                    <StudyItem index={index} item={selectedTest} tests={tests} uniqueNumber={manageData.uniqueNumber} sds={sds} setManageData={setManageData} data={manageData.tests[index]}/>
                ))}
                <button type="button" className="btn-xs" onClick={() => handleTestAdd()}>Add Test</button>
            </div>
            </div>
        </form>
    </SlidePopup>
    {showCrop ? <CropPopup image={image} setActive={setShowCrop} setImage={setCroppedImage}/>: null}
    </>
}


const StudyItem = ({item,sds,setManageData,tests,index,uniqueNumber})=>
{

    const updateValue = (type,newValue)=>
    {
        setManageData(prevState => {
            // Create a copy of the previous state array
            const newArray = [...prevState.tests];
        
            // Find the object at the specified index
            const updatedObject = { ...newArray[index] };
        
            // Update the nested field of the object
            if(type==='testId') updatedObject.id = newValue;
            else if(type === 'studyNumber') updatedObject.studyNumber = newValue
            else updatedObject.sdId = newValue;
        
            // Update the object in the array
            newArray[index] = updatedObject;
        
            // Return the updated state
            return { ...prevState,tests: newArray };
          });
    }
    return <div className="grid grid-cols-12 border border-gray-200 shadow-sm rounded-md my-4 bg-[#fcfcfc] w-full">
            <div className="col-span-3">
                <div className="relative z-0">
                    <div className="absolute top-0 left-0 h-full flex items-center pl-3 -z-10">
                        <p className="text-[13px] absolute whitespace-nowrap">{uniqueNumber} /</p> 
                    </div>
                    <input type='number' className=" px-3 py-2 pl-16 text-[13px] border-r border-r-gray-200 bg-transparent w-full" placeholder={''} required={true} value={item.studyNumber} onChange={(e)=>updateValue('studyNumber',e.target.value)}/>

                </div>
            </div>
            <div className="col-span-5 w-full">
            <select placeholder={'Select Test'} className="px-3 py-2 text-[13px] border-0 border-r border-r-gray-200 rounded-none bg-transparent mb-0 w-full" required={true} options={tests} value={item.id} onChange={(e)=>updateValue('testId',e.target.value)}>
                <option value={''}></option>
                {
                    tests.map((testItem,index)=>
                    {
                        return <option value={testItem.id} key={index}>{testItem.name}</option>
                    })
                }
            </select>
            </div>
            {/* <div className="w-full col-span-2">
            <input type='date' className="px-3 py-2 border-r text-[13px] border-r-gray-200 bg-transparent  w-full" placeholder={''} required={true} defaultValue={getDateFormat(item.expectedDeliveryDate)} onChange={(e)=>updateValue('date',e.target.value)}/>
            </div> */}
            <div className="w-full col-span-4">
            <select placeholder={'Select Sd'} className="px-3 text-[13px] py-2 border-0 rounded-none bg-transparent mb-0 w-full" required={true} options={sds} defaultValue={item.sdId} onChange={(e)=>updateValue('sd',e.target.value)}>
                <option value={''}>Select Sd</option>
                {
                    sds.map((sdItem,index)=>
                    {
                        return <option value={sdItem.id} key={index}>{sdItem.name}</option>
                    })
                }
            </select>
            </div>
    </div>
}