import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Sidebar from './components/Sidebar';
import Login from './pages/auth/Login';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import Order from './pages/orders/Order';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import {store, persistor} from './redux/store';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Dashboard from './pages/dashboard/Dashboard';
import Settings from './pages/settings/Settings';
import Retailer from './pages/retailer/Retailer';
import RetailerInfo from './pages/retailer/RetailerInfo';
import Product from './pages/products/Product';
import Leaderboard from './pages/leaderboard/Leaderboard';
import Schedule from './pages/schedule/Schedule';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import "react-datepicker/dist/react-datepicker.css";
import 'react-data-grid/lib/styles.css';
import 'react-tooltip/dist/react-tooltip.css'
import ForgotPassword from './pages/auth/ForgotPassword';
import ResetPassword from './pages/auth/ResetPassword';
import Users from './pages/users/Users';
import UserInfo from './pages/users/UserInfo';
import Consultant from './pages/consultants/Consultant';
import ConsultantInfo from './pages/consultants/ConsultantInfo';
import Sample from './pages/samples/Sample';
import Sponsor from './pages/sponsors/Sponsor';
import SponsorInfo from './pages/sponsors/SponsorInfo';
import Tids from './pages/tids/Tids';
import ProjectTimeline from './pages/timeline/ProjectTimeline';
import Project from './pages/project/Project';
import CreateProject from './pages/project/components/CreateProject';
import ProjectInfo from './pages/project/ProjectInfo';
import Study from './pages/study/Study';
import StudyInfo from './pages/study/StudyInfo';
import SampleTracker from './pages/samples/SampleTracker';
import SampleRequests from './pages/samples/SampleRequests';
import ProjectRequest from './pages/project/ProjectRequest';
import ProjectRequestInfo from './pages/project/ProjectRequestInfo';
import TechRequest from './pages/rd/tech-request/TechRequest';
import TechRequestInfo from './pages/rd/tech-request/TechRequestInfo';
import SampleInfo from './pages/samples/SampleInfo';
import QaAssignRequest from './pages/qa-head/QaAssignRequest';
import QaAssignRequestInfo from './pages/qa-head/QaAssignRequestInfo';
import Notification from './pages/notification/Notification';

const router = createBrowserRouter([
  {
    path:'/login',
    element:<Login/>
  },
  {
    path:'/forgot-password',
    element:<ForgotPassword/>
  },
  {
    path:'/reset-password',
    element:<ResetPassword/>
  },
  {
    path:'/',
    element:<Sidebar/>,
    children:[
      {
        path:'/',
        index:true,
        element:<Dashboard/>
        // element:
      },
      {
        path:'/notifications',
        element:<Notification/>
        // element:
      },
      {
        path:'qa-assign-request',
        element:<QaAssignRequest/>
      },
      {
        path:'qa-assign-request/:id',
        element:<QaAssignRequestInfo/>
      },
      {
        path:'users',
        element:<Users/>,
      },
      {
        path:'users/:id',
        element:<UserInfo/>,
      },
      {
        path:'leaderboard',
        element:<Leaderboard/>,
      },
      {
        path:'route-schedule',
        element:<Schedule/>,
      },
      {
        path:'project-timeline',
        element:<ProjectTimeline/>,
      },
      {
        path:'project',
        element:<Project/>,
      },
      {
        path:'project-request',
        element:<ProjectRequest/>,
      },
      {
        path:'project-request/:id',
        element:<ProjectRequestInfo/>,
      },
      {
        path:'tech-request',
        element:<TechRequest/>,
      },
      {
        path:'tech-request/:id',
        element:<TechRequestInfo/>,
      },
      {
        path:'project/:id',
        element:<ProjectInfo/>,
      },
      {
        path:'study',
        element:<Study/>,
      },
      {
        path:'study/:id',
        element:<StudyInfo/>,
      },
      {
        path:'create-project',
        element:<CreateProject/>,
      },
      {
        path:'sponsors',
        element:<Sponsor/>,
      },
      {
        path:'sponsors/:id',
        element:<SponsorInfo/>,
      },
      {
        path:'consultants',
        element:<Consultant/>,
      },
      {
        path:'consultants/:id',
        element:<ConsultantInfo/>,
      },
      {
        path:'samples',
        element:<Sample/>,
      },
      {
        path:'samples/:id',
        element:<SampleInfo/>,
      },
      {
        path:'sample-requests',
        element:<SampleRequests/>,
      },
      {
        path:'warehouse-tracker',
        element:<SampleTracker/>,
      },
      {
        path:'samples/:id',
        element:<></>,
      },
      {
        path:'tids',
        element:<Tids/>,
      },
      {
        path:'tids/:id',
        element:<></>,
      },
      {
        path:'settings',
        element:<Settings/>
      },
      // {
      //   path:'/activities'
      // }
    ]
  },
])

const contextClass = {
  success: "bg-blue-600",
  error: "bg-red-600",
  info: "bg-green-600",
  warning: "bg-orange-400",
  default: "bg-indigo-600",
  dark: "bg-white-600 font-gray-300",
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <RouterProvider router={router}/>
    </PersistGate>
  </Provider>
  <ToastContainer toastClassName={({ type }) => contextClass[type || "default"] +
        " relative flex p-1 min-h-10 rounded-md justify-between overflow-hidden cursor-pointer"
      }/>
</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
