import { createSlice } from "@reduxjs/toolkit";

export const authSlice = createSlice({
    name:'auth',
    initialState:{
        isLoggedIn:false,
        token:null,
        refreshToken:null,
        tokenExpiration:null,
        userName:null,
        email:null,
        role:null,
        lastNotification:null,
        notificationCheck:false
    },
    reducers:{
        login:(state,action)=>
        {
            console.log('action.payload',action.payload)
            return{
                ...state,
                isLoggedIn:true,
                id:action.payload.id,
                token:action.payload.token,
                refreshToken:action.payload.refreshToken,
                expirationTime:action.payload.expirationTime,
                userName:action.payload.userName,
                email:action.email,
                role:action.role,
              }
        },
        refreshToken:(state,action)=>
        {
            console.log('action.payload',action.payload)
            return{
                ...state,
                isLoggedIn:true,
                token:action.payload.token,
                refreshToken:action.payload.refreshToken,
                expirationTime:action.payload.expirationTime,
            }
        },
        updateLastNotification:(state,action)=>
        {
            console.log('action.payload',action.payload)
            return{
                ...state,
                lastNotification:action.payload.lastNotification
            }
        },
        updateNotificationCheck:(state,action)=>
            {
                console.log('action.payload',action.payload)
                return{
                    ...state,
                    lastNotification:action.payload.newId,
                    notificationCheck:action.payload.notificationCheck
            }
        },
        updateUser:(state,action)=>
        {
            console.log('action.payload',action.payload)
            return{
                ...state,
                userName:action.payload.name,
                email:action.payload.email,
                role:action.payload.role
              }
        },
        logout:(state,action)=>
        {
            return{
                isLoggedIn:false,
                token:null,
                userName:null,
                email:null,
                role:null,
              }
        }
    }
})

export const {login,logout,refreshToken,updateUser,updateLastNotification,updateNotificationCheck} = authSlice.actions

export default authSlice.reducer