import { useCallback, useEffect, useState } from "react"
import Input from "../../../components/Input"
import { authAxios } from "../../../helpers/axios"
import SlidePopup from "../../../components/SlidePopup"
import Loader from "../../../components/Loader"
import Select from "../../../components/Select"
import Popup from "../../../components/Popup"


export default function ManageQaAssignRequest({onClose,edit=false,onSubmit,updateData})
{
    const [manageData,setManageData] = useState({qaAssigned:''})
    const [sponsors,setSponsors] = useState([])
    const [samples,setSamples] = useState([])
    const formName = 'editRetailer';
    const [image,setImage] = useState(null)
    const [projectInfo,setProjectInfo] = useState({})
    const [croppedImage,setCroppedImage] = useState()
    const [sds,setSds] = useState([])
    const [tests,setTests] = useState([])
    const [showTest,setShowTest] = useState(false)
    const [loading,setLoading] = useState(true)
    const [qas,setQas] = useState([])

    const [file,setFile] = useState(null);

    
        useEffect(()=>
        {
        },[])
        
        useEffect(()=>
        {
            async function getQas(){
                let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/user?role=qa`)
                setQas(res.data)
                    setLoading(false)
            }
            // async function getProjectInfo(){
            //     let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/study/${updateData.id}`)
            //     setProjectInfo(res.data)
            // }
            // getProjectInfo()
            getQas()
        },[])
        
        const handleFileChange = (event) => {
            setManageData(prev=>({...prev,file:event.target.files[0]}));
        };




    return  <><Popup size="sm" title={edit ? 'Assign QA' : 'Assign QA'} submitTitle={edit ? 'Update' : 'Assign'} onClose={onClose} formName={formName}>
        { !loading ? <form onSubmit={(e)=>onSubmit(e,{...manageData})} id={formName} className='block items-start grid-cols-4 gap-x-3 w-full'>
            <div className="grid grid-cols-2 items-start col-span-2 gap-x-3 gap-y-0 px-4 w-full">

            {/* <div className="mb-4">
                <label>Sponsor Name*</label>
                <p className="text-xs">{projectInfo.sponsor?.name}</p>
            </div>
            <div className="mb-4">
                <label>Test Item Name</label>
                <p className="text-xs">{projectInfo.project?.tids?.testItemName}</p>
            </div> */}
            <div className="col-span-2 mb-2">
                <label>QA</label>
                <Select options={qas} setValue={(value)=>setManageData(prev=>({...prev,qaAssigned:value}))} placeholder={'Select QA'} customLabel={'name'} customValue={'id'} required={true} value={manageData.qaAssigned}/>
            </div>

            </div>
        </form> : <Loader/> }
    </Popup> 
    </> 
}
