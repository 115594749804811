import Popup from "../../../components/Popup"

export default function LiveProject({onClose,edit=false,onSubmit,updateData})
{
    return <><Popup size="md" title={'Move Project to Live'} submitTitle={'Move to Live'} onClose={onClose} formName={'liveProject'}>
        <form onSubmit={(e)=>onSubmit(e)} id={'liveProject'} className='block w-full'>
            <div className="mb-4">
                <p className="text-sm">Are you sure you want to move this project and its studies to live?</p>
            </div>
        </form>
    </Popup>
    </>
}
