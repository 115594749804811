import React, { useEffect, useState } from 'react'
import Header from '../../components/Header'
import SearchInput from '../../components/SearchInput'
import { authAxios } from '../../helpers/axios'
import { toast } from 'react-toastify'
import FilterSelect from '../../components/FilterSelect'
import { useNavigate } from 'react-router-dom'
import ManageSample from './components/ManageSample'
import { base64ToBlob, dataURItoBlob, getDateFormat } from '../../helpers/utils'
import { ErrorToast, InfoToast } from '../../helpers/toasters'
import SortIcon from '../../images/sort.svg'
import RightIcon from '../../images/right.svg'
import FilterIcon from '../../images/filter.svg'
import RupeeIcon from '../../images/rupee.svg'
import { LIMIT, SAMPLE_REQUEST_ACCEPTED, SAMPLE_REQUEST_CANCELLED, SAMPLE_REQUEST_INTIATED } from '../../helpers/constants'
import { CiBoxes } from 'react-icons/ci'
import ReactDataGrid from 'react-data-grid'
import { PiPlusBold } from 'react-icons/pi'
import { useSelector } from 'react-redux'
import Pagination from '../../components/Pagination'
import UpdateSampleRequest from './components/UpdateSampleRequest'

export default function SampleRequests() {

    const [searchText,setSearchText] = useState('')
    const [samples,setSamples] = useState([])
    const [showCreate,setShowCreate] = useState({status:false,edit:null})
    const [showUpdate,setShowUpdate] = useState(false)

    const [cityFilters,setCityFilters] = useState([])
    const [routeFilters,setRouteFilters] = useState([])
    const navigate = useNavigate()
    const [sort,setSort] = useState('-createdAt');
    const [offset,setOffset] = useState(0);
    const [count,setCount] = useState(0)
    const [requestId,setRequestId] = useState(false)
    const authInfo = useSelector(state=>state.auth)

    const columns = [
        { key: 'sampleId', name: 'Sample ID',width:120, frozen: true,renderCell:({row})=><p>{row.sample.sampleId}</p>},
        { key: 'sd', name: 'Study Director' ,renderCell:({row})=><p>{row.sd.name}</p>},
        { key: 'quantity',width:120, name: 'Total Qty.' },
        { key: 'receivedDate',width:120, name: 'Received On',renderCell:({row})=><p>{getDateFormat(row.createdAt)}</p> },
        { key: 'status',width:120, name: 'Status',width:200,renderCell:({row})=><p className={`text-xs ${row.status === SAMPLE_REQUEST_ACCEPTED ? 'bg-green-300' : row.status === SAMPLE_REQUEST_INTIATED ? 'bg-orange-200' : 'bg-gray-300'} rounded-full px-3 py-1 inline-block text-center mx-auto`}>{row.status === SAMPLE_REQUEST_ACCEPTED ? 'Approved' : row.status === SAMPLE_REQUEST_CANCELLED ? 'Cancelled' : 'Initiated'}</p>},
        { key: 'id', name: 'Action',renderCell:({row})=><button className={`btn-xs-inverted ${authInfo.role ==='sd' ? 'hidden' : 'block'}`} disabled={row.status !== SAMPLE_REQUEST_INTIATED} onClick={()=>
            {
                setShowUpdate(row.sampleId);
                setRequestId(row.id);
        }} type='button'>Update</button> },
    ];
    

    const [selectedFilters,setSelectedFilters] = useState({city:'',route:''})
    const RightContent = ()=>
    {
        return <>
        <div className='flex justify-end'>
            <Pagination offset={offset} setOffset={setOffset} count={count}/>
            <div className="mr-2">
                <SearchInput value={searchText} setValue={(value)=>setSearchText(value)} placeholder={'Search Sample Requests'} label={'Search Requests'} />
            </div>
            <div className="flex items-center">
                {authInfo.role === 'sd' ? <div className="flex items-center">
                <button className='rounded-md bg-[#e6e6e6] hover:bg-[#c3c3c3] transition-all p-2.5 flex items-center justify-center' onClick={()=>setShowCreate({status:true,edit:false})}><PiPlusBold fontSize={20} lightingColor={'#fff'} color='#182159'/></button>
            </div> : null}
            </div>
            </div>
            </>
    }


    async function getSamples(){

        try 
        {
            let query = `&limit=${LIMIT}&offset=${offset}&orderBy=${sort}`;
                if(searchText) query+= `&search=${searchText}`;
            let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/sample-request?populate=true${query}`)
            if(res.data)
            {
                setSamples(res.data.sampleRequests)
                setCount(res.data.totalCount)
            }
        } catch (error) {
            toast('Error getting samples')
        }
    }
    useEffect(()=>
    {
        getSamples();
    },[sort,searchText,offset])


    const onSubmit = async(e,data)=>
    {
        try 
        {
            e.preventDefault()
            let res;
            res = await authAxios.put(`${process.env.REACT_APP_BASE_URL}/sample-request/${requestId}`,{status:data.status,description:data.description}) 
            InfoToast('Sample Request Updated')
            await getSamples()
            setShowUpdate(false)
        } catch (error) {
            // console.log(error.response.data.error[0])
            ErrorToast(error.response.data.error[Object.keys(error.response.data.error)[0]])
        }
    }


  return (
    <>
        <Header title={'Sample Request Management'} RightContent={RightContent}/>
        <div className='flex flex-1 bg-white w-full overflow-scroll'>
        <ReactDataGrid
        rowHeight={40}
        rows={samples}
        className='w-full'
      columns={columns}
    //   renderers={rowGetter}
    //   rowsCount={tids.length}
    //   minWidth={800} // Minimum width for the table
    //   minHeight={400} // Minimum height for the table
      enableCellSelect={false}
    />
        </div>
        { showUpdate ? <UpdateSampleRequest requestId={requestId} id={showUpdate} onClose={setShowUpdate} onSubmit={onSubmit}/> : null}
    </>
  )
}

