import React, { useEffect, useState } from 'react'
import Header from '../../../components/Header'
import { authAxios } from '../../../helpers/axios'
import { toast } from 'react-toastify'
import Popup from '../../../components/Popup'
import Input from '../../../components/Input'
import { useNavigate, useParams } from 'react-router-dom'
import { ErrorToast, InfoToast } from '../../../helpers/toasters'
import Loader from '../../../components/Loader'
import ReactDataGrid from 'react-data-grid';
import { getDateFormat } from '../../../helpers/utils'
import ManageTechRequest from './components/ManageTechRequest'

export default function TechRequestInfo() {

    const {id} = useParams();
    const [projectInfo,setProjectInfo] = useState([])
    const [showEdit,setShowEdit] = useState(false)
    const [orders,setOrders] = useState([])
    const [activeTab,setActiveTab] = useState('study')
    const [loading,setLoading] = useState(true)
    const [stocks,setStocks] = useState([])
    const [submitting,setSubmitting] = useState(false)
    const [showUpdate,setShowUpdate] = useState(false)
    const navigate = useNavigate()

    const RightContent = ()=>
    {
        return <div>
          <button type='button' disabled={submitting} className='btn-md' onClick={()=>setShowUpdate(true)}>{!submitting ? 'Update Request' : 'Updating Request...'}</button>
        </div>
    }

    async function getProjectInfo(){

        try 
        {
            let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/project/${id}?populate=true`)
            if(res.data) setProjectInfo(res.data)
            setLoading(false)
        // let Ordersres = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/orders?distributorId=${id}&populate=true`)
        // if(Ordersres.data.data) setOrders(Ordersres.data.data)
    } catch (error) {
        setLoading(false)
        toast('Error getting Consultant Info')
        }
    }
    useEffect(()=>
    {
        getProjectInfo();
    },[])

    const onSubmit = async(e,data)=>
    {
        try 
        {
            e.preventDefault()

            let res;
            const formData = new FormData();
                console.log('formdata',data.file)
                // formData.append('file', data.file);
                formData.append('description',data.description)
                formData.append('appearanceConfirmation',data.appearanceConfirmation)
                formData.append('extractionRatio',data.extractionRatio)
                formData.append('dimensions',data.dimensions)
                formData.append('testingPart',data.testingPart)
                formData.append('studies',JSON.stringify(data.studies))
                formData.append('projectId',id)
                res = await authAxios.post(`${process.env.REACT_APP_BASE_URL}/project/tech-info`,formData,{
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }})
                setShowUpdate(false)
                await getProjectInfo()
                navigate('/tech-request')
                InfoToast('Request Completed')
        } catch (error) {
            // console.log(error.response.data.error[0])
            ErrorToast(error.response.data.error[Object.keys(error.response.data.error)[0]])
        }
    }


    const columns = [
        // { key: 'id', name: 'Sample ID', frozen: true, width: 150},
        { key: 'studyNumber', name: 'Study Number' ,width: 150,renderCell:({row})=><p>{projectInfo.project.consultantId ? projectInfo.project.consultant.uniqueNumber : projectInfo.project.sponsor.uniqueNumber}/{row.studyNumber}</p>},
        { key: 'testName', name: 'Study Name' ,width: 350,renderCell:({row})=><p>{row.test.name }</p>},
        { key: 'sd', name: 'Study Director' ,width: 200,renderCell:({row})=><p>{row.sdAssigned ? row.sd.name : 'Not Assigned'}</p>},
        { key: 'sdApproval', name: 'SD Approval' ,width: 150,renderCell:({row})=><p>{row.sdApproved ? 'Approved' : 'Not Yet Approved'}</p>},
        { key: 'sampleRequired', name: 'Sample Required' ,width: 100,renderCell:({row})=><p>{row.sampleRequired ? 1 : '1'}</p>},
        { key: 'sdApprovedOn', name: 'Approved On',renderCell:({row})=><p>{row.sdApprovedOn ? getDateFormat(row.sdApprovedOn) : '-'}</p>}
        // { key: 'expectedDeliveryDate', name: 'Expected Delivery Date',renderCell:({row})=><p>{row.sdApprovedOn ? getDateFormat(row.sdApprovedOn) : '-'}</p>}
      ];
    

  return (
    !loading ? <>
        <Header  parentLink='project-request' parentTitle='Project Request' title={projectInfo.name} RightContent={RightContent}/>
        <div className='block flex-1 overflow-scroll bg-white w-full'>
            <div className='w-full border-b border-b-gray-300 pb-10'>
                <p className='text-xs uppercase text-gray-400 font-medium mb-4'>Project Information</p>
            
            <div className='grid grid-cols-12 w-full'>
                <div className='col-span-3'>
                    <label>Sponsor</label>
                    <p className='text-sm'>{projectInfo.project?.sponsor?.name} ({projectInfo.project.sponsor.uniqueNumber})</p>
                </div>
                <div className='col-span-3'>
                    <label>Consultant</label>
                    <p className='text-sm'>{projectInfo.project?.consultantId ? `${projectInfo.project.consultant.name} (${projectInfo.project.consultant.uniqueNumber})` : '-'}</p>
                </div>
                <div className='col-span-3'>
                    <label>Sponsor</label>
                    <p className='text-sm'>{projectInfo.project.sponsor?.name}</p>
                </div>
            </div>
            </div>

            <div className='w-full border-b border-b-gray-300 py-10 pt-6'>
                <p className='text-xs uppercase text-gray-400 font-medium mb-4'>Studies Information</p>
                <div className='w-full'>
                <ReactDataGrid
                    rowHeight={40}
                    rows={projectInfo.studies}
                    className='w-full'
                columns={columns}
                enableCellSelect={false}
                />

                </div>
            </div>

            <div>

            </div>
            
        </div>
        { showUpdate ? <ManageTechRequest onClose={setShowUpdate} onSubmit={onSubmit} update={true} updateData={projectInfo}/> : null}
    </> : <Loader/>
  )
}
