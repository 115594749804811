import React, { useEffect, useState } from 'react'
import Header from '../../components/Header'
import SearchInput from '../../components/SearchInput'
import { authAxios } from '../../helpers/axios'
import { toast } from 'react-toastify'
import Popup from '../../components/Popup'
import Input from '../../components/Input'
import FilterSelect from '../../components/FilterSelect'
import Select from '../../components/Select'
import Map from '../../components/Map'
import { useNavigate, useParams } from 'react-router-dom'
import TabGroup from '../../components/TabGroup'
import { ErrorToast, InfoToast } from '../../helpers/toasters'
import Loader from '../../components/Loader'
import ReactDataGrid from 'react-data-grid';
import { getDateFormat } from '../../helpers/utils'
import { useSelector } from 'react-redux'
import { SAMPLE_REQUEST_ACCEPTED, SAMPLE_REQUEST_CANCELLED, SAMPLE_REQUEST_INTIATED } from '../../helpers/constants'

export default function SampleInfo() {

    const {id} = useParams();
    const [sampleInfo,setSampleInfo] = useState([])
    const [showEdit,setShowEdit] = useState(false)
    const [showAdd,setShowAdd] = useState(false)
    const authInfp = useSelector(state=>state.auth)
    const [orders,setOrders] = useState([])
    const authInfo = useSelector(state=>state.auth)
    const [activeTab,setActiveTab] = useState('study')
    const [loading,setLoading] = useState(true)
    const [stocks,setStocks] = useState([])
    const navigate = useNavigate()

    const sampleItemColumns = [
        { key: 'serialNumber', name: 'Serial Number',width:200 },
        { key: 'lotNumber', name: 'Lot Number',width:200 },
        { key: 'quantity', name: 'Quantity',width:150 },
        { key: 'receivedDate', name: 'Date of Receipt' ,renderCell:({row})=><p>{getDateFormat(row.receivedDate)}</p>},
        { key: 'isDamaged',width:100, name: 'Damaged?',renderCell:({row})=><p>{row.isDamaged ? 'Yes' : 'No'}</p>},
        { key: 'modeOfReceiving', name: 'Mode Of Receiving',renderCell:({row})=><p className='capitalize'>{(row.modeOfReceiving) ? row.modeOfReceiving : '-'}</p>},
      ];

    const RightContent = ()=>
    {
        return <div>
          {authInfo.role === 'sd' && authInfo.id === sampleInfo.sdAssigned ? <button type='button' className='btn-md' onClick={()=>setShowEdit(true)}>Approve Project</button>: null}
          {authInfo.role === 'tico' ? <button type='button' className='btn-md' onClick={()=>setShowAdd(true)}>Add Sample Lot</button>: null}
        </div> 
    }

    async function getSampleInfo(){

        try 
        {
            let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/sample/${id}?populate=true`)
            if(res.data) setSampleInfo(res.data)
            setLoading(false)
    } catch (error) {
        setLoading(false)
        toast('Error getting Sample Info')
        }
    }
    useEffect(()=>
    {
        getSampleInfo();
    },[])

    const onSubmit = async(e,data)=>
    {
        try 
        {
            e.preventDefault()
            let res = await authAxios.post(`${process.env.REACT_APP_BASE_URL}/study/approve/${id}`) 
            if(res.data)
            {
                await getSampleInfo()
                navigate('/study')
                setShowEdit(false)
            }
            else toast('error updating sample')
        } catch (error) {
            toast(error.response.data)
        }
    }

    const onAddSampleLot = async(e,data)=>
    {
        try 
        {
            e.preventDefault()
            let res = await authAxios.post(`${process.env.REACT_APP_BASE_URL}/sample/add-lot/${id}`,data) 
            await getSampleInfo()
            navigate('/study')
            setShowEdit(false)
        } catch (error) {
            ErrorToast(error.response.data.name)
        }
    }


    const columns = [
        { key: 'sampleId', name: 'Sample ID',width:120, frozen: true,renderCell:({row})=><p>{row.sample.sampleId}</p>},
        { key: 'sd', name: 'Study Director' ,renderCell:({row})=><p>{row.sd.name}</p>},
        { key: 'quantity',width:120, name: 'Total Qty.' },
        { key: 'receivedDate',width:120, name: 'Received On',renderCell:({row})=><p>{getDateFormat(row.createdAt)}</p> },
        { key: 'status',width:120, name: 'Status',width:200,renderCell:({row})=><p className={`text-xs ${row.status === SAMPLE_REQUEST_ACCEPTED ? 'bg-green-300' : row.status === SAMPLE_REQUEST_INTIATED ? 'bg-orange-200' : 'bg-gray-300'} rounded-full px-3 py-1 inline-block text-center mx-auto`}>{row.status === SAMPLE_REQUEST_ACCEPTED ? 'Approved' : row.status === SAMPLE_REQUEST_CANCELLED ? 'Cancelled' : 'Initiated'}</p>},
        // { key: 'id', name: 'Action',renderCell:({row})=><button onClick={()=>setShowUpdate(row.id)} type='button' className='btn-xs-inverted'>Update</button> },
    ];
    

  return (
    !loading ? <>
        <Header  parentLink='sample info' parentTitle='Sample Information' title={sampleInfo.name} RightContent={RightContent}/>
        <div className='block flex-1 overflow-scroll bg-white w-full'>
            <div className='w-full border-b border-b-gray-300 pb-10'>
                <p className='text-xs uppercase text-gray-400 font-medium mb-4'>Basic Information</p>
            
            <div className='grid grid-cols-12 w-full'>
                <div className='col-span-3'>
                    <label>Sample ID</label>
                    <p className='text-sm'>{sampleInfo.sample.sampleId}</p>
                </div>
                <div className='col-span-3'>
                    <label>Sponsor</label>
                    <p className='text-sm'>{sampleInfo.sponsorId ? `${sampleInfo.sponsor?.name} (${sampleInfo.sponsor?.uniqueNumber})` : 'Not Linked'}</p>
                </div>
                <div className='col-span-3'>
                    <label>Consultant</label>
                    <p className='text-sm'>{sampleInfo.consultantId ? `${sampleInfo?.consultant?.name} (${sampleInfo?.consultant?.uniqueNumber})` : 'Not Linked'}</p>
                </div>
                {!sampleInfo.tidsId ? <div className='col-span-3'>
                    <label>Reference Name</label>
                    <p className='text-sm'>{sampleInfo.sample.referenceName}</p>
                </div> : null}
            </div>
            </div>

            <div className='w-full border-b border-b-gray-300 py-10 pt-6 mb-6'>
                <p className='text-xs uppercase text-gray-400 font-medium mb-4'>TIDS Information</p>
                {sampleInfo.sample.tidsId ? <div className='grid grid-cols-12 gap-y-6 w-full'>
                    <div className='col-span-3'>
                        <label>Status</label>
                        <p className='text-sm'>{sampleInfo.sample.tidsId ? 'Linked' :'Not Linked'}</p>
                    </div>
                    <div className='col-span-3'>
                        <label>Test Item Name</label>
                        <p className='text-sm'>{sampleInfo.sample.tids?.testItemName}</p>
                    </div>
                    <div className='col-span-3'>
                        <label>Test Name</label>
                        <p className='text-sm'>{sampleInfo.sample.test?.name}</p>
                    </div>
                    <div className='col-span-3'>
                        <label>Sterility Type</label>
                        <p className='text-sm'>{sampleInfo.sample.tids?.sterilityType}</p>
                    </div>
                    <div className='col-span-3'>
                        <label>Acceptable to Cut</label>
                        <p className='text-sm capitalize'>{sampleInfo.sample.tids?.acceptableToCut ? 'Yes' : 'No'}</p>
                    </div>
                    <div className='col-span-3'>
                        <label>Appearance</label>
                        <p className='text-sm'>{sampleInfo.sample.tids?.appearance}</p>
                    </div>
                    <div className='col-span-6'>
                        <label>Description</label>
                        <p className='text-sm'>{sampleInfo.sample.tids?.description}</p>
                    </div>
                    <div className='col-span-3'>
                        <label>Sample Storage Condition</label>
                        <p className='text-sm'>{sampleInfo.sample.tids?.sampleStorageCondition}</p>
                    </div>
                    <div className='col-span-3'>
                        <label>GLR Storage Condition</label>
                        <p className='text-sm'>{sampleInfo.sample.tids?.glrStorageCondition}</p>
                    </div>
                </div> : <p className='text-xs text-[#959595]'>TIDS Not Linked Yet</p>}
            </div>

            <div>
            <div className='block flex-1 bg-white w-full border-b border-b-gray-300 py-10 pt-6 mb-6'>
            <p className='text-xs uppercase text-gray-400 font-medium mb-4'>Sample Lots</p>
        <ReactDataGrid rowHeight={40} rows={sampleInfo.sample.sampleitems} className='w-full h-full' columns={sampleItemColumns} enableCellSelect={false}/>
        </div>
            </div>
            
            <div className='mt-6'>
            <div className='block flex-1 bg-white w-full'>
            <p className='text-xs uppercase text-gray-400 font-medium mb-4'>Sample Requests</p>
        <ReactDataGrid rowHeight={40} rows={sampleInfo.sampleRequests} className='w-full h-full' columns={columns} enableCellSelect={false}/>
        </div>
            </div>
            
        </div>
        { showEdit ? <ApproveProject onClose={setShowEdit} onSubmit={onSubmit}/> : null}
        { showAdd ? <AddSampleLot onClose={setShowAdd} onSubmit={onAddSampleLot}/> : null}
    </> : <Loader/>
  )
}

const ApproveProject = ({onClose,onSubmit})=>
{
    return <Popup title={'Approve Study'} submitTitle={'Approve'} onClose={onClose} formName={'approveStudy'}>
    <form onSubmit={(e)=>onSubmit(e)} id={'approveStudy'} className='grid gap-x-3 w-full'>
        <div>
            <p>Are you sure you want to approve the study?</p>
        </div>
        

    </form>
</Popup>
}


const AddSampleLot = ({onClose,onSubmit})=>
{
    const [manageData,setManageData] = useState({quantity:'',lotNumber:'',serialNumber:'',isDamaged:false,modeOfReceiving:''})
    return <Popup title={'Add New Sample Lot'} submitTitle={'Add New Lot'} onClose={onClose} formName={'addSampleLot'}>
    <form onSubmit={(e)=>onSubmit(e,manageData)} id={'addSampleLot'} className='block w-full'>
        <div className="grid grid-cols-2 gap-x-3 gap-y-0 w-full">
            <div>
                <label>Date of Receipt</label>
                <Input type='date' placeholder={'Date of Receipt'} required={true} value={manageData.dateOfReceipt} setValue={(value)=>setManageData(data=>({...data,dateOfReceipt:value}))}/>
            </div>
            <div>
                <label>Quantity</label>
                <Input type='number' placeholder={'Enter Quantity'} required={true} value={manageData.quantity} setValue={(value)=>setManageData(data=>({...data,quantity:value}))}/>
            </div>
            <div>
                <label>Lot Number</label>
                <Input type='text' placeholder={'Lot Number'} required={true} value={manageData.lotNumber} setValue={(value)=>setManageData(data=>({...data,lotNumber:value}))}/>
            </div>
            <div>
                <label>Serial Number</label>
                <Input type='text' placeholder={'Serial  Number'} required={true} value={manageData.serialNumber} setValue={(value)=>setManageData(data=>({...data,serialNumber:value}))}/>
            </div>
            <div>
                <label>Mode of Receiving</label>
                <Select placeholder={'Select Mode'} required={false} customLabel={'name'} options={[{name:'By Hand',id:'by-hand'},{name:'Courier',id:'courier'}]} customValue={'id'} value={manageData.modeOfReceiving} setValue={(value)=>setManageData(data=>({...data,modeOfReceiving:value}))}/>
            </div>
            <div>
                <label>Is Damaged?</label>
                <Select placeholder={'Select Yes/No'} required={false} customLabel={'name'} options={[{name:'Yes',id:true},{name:'No',id:false}]} customValue={'id'} value={manageData.isDamaged} setValue={(value)=>setManageData(data=>({...data,isDamaged:value}))}/>
            </div>
            </div>
    </form>
</Popup>
}


const StockInfo = ({id})=>
{
    const [searchText,setSearchText] = useState('')
    const [cityFilters,setCityFilters] = useState([])
    const [routeFilters,setRouteFilters] = useState([])
    const [selectedFilters,setSelectedFilters] = useState({city:'',route:''})
    const [stocks,setStocks] = useState([])
    const [products,setProducts] = useState([])
    const [showUpdate,setShowUpdate] = useState(false)
    const [showReturnUpdate,setShowReturnUpdate] = useState(false)

    async function getStocks(){

        try 
        {
            let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/stock/${id}?populate=true`)
            let productres = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/product/${id}?populate=true`)
            if(res.data.data) setStocks(res.data.data)
        } catch (error) {
            toast('Error getting products')
        }
    }
    useEffect(()=>
    {
        getStocks();
    },[])
    
    
    const onSubmit = async(e,data)=>
    {
        try 
        {
            e.preventDefault()
            let productres = await authAxios.post(`${process.env.REACT_APP_BASE_URL}/stock`,{stockDistributorId:id,stockProductId:data.productId,stockQty:data.stockCount})
            await getStocks();
            setShowUpdate(null)
            InfoToast('Stock Updated')
        } catch (error) {
            ErrorToast('Error getting products')
        }
    }

    const onReturnSubmit = async(e,data)=>
    {
        try 
        {
            e.preventDefault()
            let productres = await authAxios.post(`${process.env.REACT_APP_BASE_URL}/stock/clear-return`,{stockDistributorId:id,stockProductId:data.productId})
            await getStocks();
            setShowReturnUpdate(null)
            InfoToast('Return stock cleared')
        } catch (error) {
            ErrorToast('Error clearing return products')
        }
    }

    return <><div className='flex justify-start h-full w-full self-stretch'>
        
<div className='flex flex-1 bg-white w-full overflow-scroll'>
{/* <table className='table-auto w-full flex-1'>
                <thead className='bg-[#f9f9f9] w-full'>
                    <tr className='w-full'>
                    <td><p>Image</p></td>
                    <td><p>Name/SKU</p></td>
                    <td><p>Weight(In Gms)</p></td>
                    <td><p>Stock</p></td>
                    <td><p>Return Stock</p></td>
                    <td><p>Total Value</p></td>
                    <td><p>Action</p></td>
                    </tr>
                </thead>
                <tbody>
                    {
                        stocks.map((item,index)=>
                        {
                            return <tr>
                                <td className='capitalize'>
                                    <div>
                                        <img src={item.productImage} className='w-16 h-16 rounded-full'/>
                                    </div>
                                </td>
                                <td className='capitalize'>
                                <div>
                                        <p className='text-sm font-regular my-0'>{item.productName}</p>
                                        <p className='text-xs my-0 text-gray-400'>{item.productSku}</p>
                                    </div>
                                </td>
    
                                <td className='capitalize'>
                                <div>
                                        <p className='text-sm font-regular my-0'>{item.productWeight}</p>
                                    </div>
                                </td>
                                <td className='capitalize'>
                                <div>
                                        <p className='text-sm font-regular my-0'>{parseInt(item.stockCount)}</p>
                                    </div>
                                </td>
                                <td className='capitalize'>
                                <div>
                                        <p className='text-sm font-regular my-0'>{parseInt(item.returnQty)}</p>
                                    </div>
                                </td>
                                <td className='capitalize'>
                                <div>
                                        <p className='text-sm font-regular my-0'>Rs.{(parseInt(item.stockCount)*item.productPrice)}</p>
                                    </div>
                                </td>
                                <td className='capitalize'>
                                <div>
                                    <button className='btn-xs mb-1' onClick={()=>setShowUpdate(item)}>Update</button>
                                    <button className='btn-xs' onClick={()=>setShowReturnUpdate(item)}>Clear Return</button>
                                    </div>
                                </td>

                            </tr>
                        })
                    }
                </tbody>
            </table> */}
    </div>
    </div>
    {/* {showUpdate ? <ManageStock data={showUpdate} onSubmit={onSubmit} onClose={()=>setShowUpdate(null)}/> : null}
    {showReturnUpdate ? <ManageReturnQty data={showReturnUpdate} onSubmit={onReturnSubmit} onClose={()=>setShowReturnUpdate(null)}/> : null} */}
    </>
}


const MarginInfo = ({id})=>
{

    const [margins,setMargins] = useState([])
    const [showUpdate,setShowUpdate] = useState(false)

    async function getMargins(){

        try 
        {
            let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/margin/${id}?populate=true`)
            let productres = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/product/${id}?populate=true`)
            if(res.data.data) setMargins(res.data.data)
        } catch (error) {
            toast('Error getting products')
        }
    }
    useEffect(()=>
    {
        getMargins();
    },[])
    
    
    const onSubmit = async(e,data)=>
    {
        try 
        {
            e.preventDefault()
            let productres = await authAxios.post(`${process.env.REACT_APP_BASE_URL}/margin`,{marginDistributorId:id,marginProductId:data.productId,marginAmount:data.marginAmount})
            await getMargins();
            setShowUpdate(null)
            InfoToast('Stock Updated')
        } catch (error) {
            ErrorToast('Error getting products')
        }
    }


    return <><div className='flex justify-start h-full w-full self-stretch'>
        
<div className='flex flex-1 bg-white w-full overflow-scroll'>
<table className='table-auto w-full flex-1'>
                <thead className='bg-[#f9f9f9] w-full'>
                    <tr className='w-full'>
                    <td><p>Image</p></td>
                    <td><p>Name/SKU</p></td>
                    <td><p>Weight(In Gms)</p></td>
                    <td><p>Resale Amount</p></td>
                    <td><p>Margin Amount</p></td>
                    <td><p>Total Amount</p></td>
                    <td><p>Action</p></td>
                    </tr>
                </thead>
                <tbody>
                    {
                        margins.map((item,index)=>
                        {
                            return <tr>
                                <td className='capitalize'>
                                    <div>
                                        <img src={item.productImage} className='w-16 h-16 rounded-full'/>
                                    </div>
                                </td>
                                <td className='capitalize'>
                                <div>
                                        <p className='text-sm font-regular my-0'>{item.productName}</p>
                                        <p className='text-xs my-0 text-gray-400'>{item.productSku}</p>
                                    </div>
                                </td>
    
                                <td className='capitalize'>
                                <div>
                                        <p className='text-sm font-regular my-0'>{item.productWeight}</p>
                                    </div>
                                </td>
                                <td className='capitalize'>
                                <div>
                                        <p className='text-sm font-regular my-0'>{parseFloat(item.productPrice)}</p>
                                    </div>
                                </td>
                                <td className='capitalize'>
                                <div>
                                        <p className='text-sm font-regular my-0'>{parseFloat(item.marginAmount)}</p>
                                    </div>
                                </td>
                                <td className='capitalize'>
                                <div>
                                        <p className='text-sm font-regular my-0'>Rs.{(parseFloat(item.productPrice)+parseFloat(item.marginAmount))}</p>
                                    </div>
                                </td>
                                <td className='capitalize'>
                                <div>
                                    <button className='btn-xs mb-1' onClick={()=>setShowUpdate(item)}>Update</button>
                                    </div>
                                </td>

                            </tr>
                        })
                    }
                </tbody>
            </table>
    </div>
    </div>
    {/* {showUpdate ? <ManageMargin data={showUpdate} onSubmit={onSubmit} onClose={()=>setShowUpdate(null)}/> : null} */}
    </>
}



const ManageStock = ({onClose,onSubmit,data})=>
{
    console.log('item',data)
    const [manageData,setManageData] = useState({stockCount:data.stockCount,productId:data.id})
    
    return <Popup title={'Update Stock'} submitTitle={'Update'} onClose={onClose} formName={'updateStock'}>
        <form onSubmit={(e)=>onSubmit(e,manageData)} id={'updateStock'} className='grid grid-cols-2 gap-x-3 gap-y-6 w-full'>
            <div>
                <label>Product Name</label>
                <p className='text-sm text-medium'>{data?.productName}</p>
            </div>
            <div>
                <label>Product SKU</label>
                <p className='text-sm text-medium'>{data?.productSku}</p>
            </div>
            <div className='col-span-2'>
                <label>Product Stock</label>
                <Input type='text' placeholder={'Enter Stock'} value={manageData.stockCount ? parseInt(manageData.stockCount) : ''} setValue={(value)=>setManageData(data=>({...data,stockCount:value}))}/>
            </div>
        </form>
    </Popup>
}
