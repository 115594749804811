import React, { useEffect } from 'react';
import { Timeline } from 'vis-timeline/standalone';
import Header from '../../components/Header';

const ProjectTimeline = () => {
  useEffect(() => {
    // Initialize the timeline
    const container = document.getElementById('timeline-container');
    const options = {}; // Customize options if needed
    const items = [
      { id: 1, content: 'Item 1', start: '2022-01-01' },
      { id: 2, content: 'Item 2', start: '2022-01-05' },
      // Add more dummy data as needed
    ];
    const timeline = new Timeline(container, items, options);

    // Clean up function to remove the timeline when component unmounts
    return () => {
      timeline.destroy();
    };
  }, []);

  return (
    <>
    <Header title={'Project Timeline'} RightContent={<></>}/>
    <div className='flex h-[100vh] w-full'>
        <div id="timeline-container" style={{ width: '100%', height: '100vh' }}></div>
    </div>
    </>
  );
};

export default ProjectTimeline;
