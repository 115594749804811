import React, { useEffect, useRef, useState } from 'react'
import { PiArrowDown, PiCaretDown, PiCaretDownBold } from 'react-icons/pi'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { logout } from '../redux/slice/authSlice'
  
export default function Header({title,parentLink='',parentTitle='',RightContent}) {
  const authInfo = useSelector(state=>state.auth)
  const [showMenu,setShowMenu] = useState(false)
  const ref = useRef(null);
  const dispatch = useDispatch()

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setShowMenu(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const toggleOpen = () => {
    setShowMenu(prevState => !prevState);
  };
  return (
    <div className=' px-2 py-4 pb-6 bg-white flex justify-between items-center border-b-0 border-b-[#EDF2F5]'>
        <div>
        <p className='text-sm font-normal capitalize tracking-[-.15px] text-gray-400'><Link to={'/'}>Home</Link> {parentLink ? '>' : null} <Link className='hover:text-[#2ac08b]' to={`/${parentLink}`}>{parentTitle}</Link> &gt; <span className='inline-block text-black'>{title}</span></p>
        </div>
        <RightContent/>
        {/* <div onClick={toggleOpen} ref={ref} className='relative z-0  flex items-center bg-white hover:bg-[#f3f3f3] rounded-md py-2 px-3 transition-all cursor-pointer'>
            <div className='bg-[#182159] h-8 w-8 rounded-full mr-2 flex items-center justify-center'>
                <p className='text-sm text-white font-semibold'>{authInfo?.userName[0]}</p>
            </div>
            <div className=''>
              <div className='flex justify-between items-center'>
                <div className='mr-2'>
                  <p className='text-sm font-medium tracking-tight capitalize mb-0'>{authInfo?.userName}</p>
                  <p className='text-[11px] font-medium capitalize text-gray-400 tracking-tight mt-0'>{authInfo?.role} Department</p>
                </div>
              <div className='my-auto'>
                  <PiCaretDownBold color='#959595'/>
              </div>
              </div>
              <div className={`${showMenu ? 'block' : 'hidden'} absolute bg-white border border-gray-200 rounded-md top-0 left-0 w-full pt-16 -z-10`}>
                <div className='py-3 border-b border-b-gray-100 border-t border-t-gray-100 hover:bg-[#f9f9f9] px-4'>
                  <p className='text-sm font-medium tracking-tight capitalize mb-0'>Profile</p>
                </div>
                <div className='py-3 px-4 hover:bg-[#f9f9f9]' onClick={()=>dispatch(logout())}>
                  <p className='text-sm font-medium tracking-tight capitalize mb-0'>Logout</p>
                </div>
              </div>
            </div>
        </div> */}
    </div>
  )
}
