import { useCallback, useEffect, useState } from "react"
import Input from "../../../components/Input"
import Select from "../../../components/Select"
import { authAxios } from "../../../helpers/axios"
import { PROJECT_STATUS_SAMPLE_REQUESTED, RETAILER_IMAGE_BASE_URL, STERILITY_TYPE_NON_STERILE, STERILITY_TYPE_STERILE } from "../../../helpers/constants"
import SlidePopup from "../../../components/SlidePopup"
import PDFViewer from "../../../components/PDFViewer"
import Loader from "../../../components/Loader"


export default function ManageProjectRequest({onClose,edit=false,onSubmit,updateData})
{
    const [manageData,setManageData] = useState({testItemName:'',sponsorId:'',uniqueNumber:'',tests:[{id:'',studyNumber:'',sdId:''}]})
    const [sponsors,setSponsors] = useState([])
    const [samples,setSamples] = useState([])
    const formName = 'editRetailer';
    const [image,setImage] = useState(null)
    const [croppedImage,setCroppedImage] = useState()
    const [sds,setSds] = useState([])
    const [tests,setTests] = useState([])
    const [showTest,setShowTest] = useState(false)
    const [loading,setLoading] = useState(true)
    const [submitting,setSubmitting] = useState(false)

    const [file,setFile] = useState(null);

    const [containerRef, setContainerRef] = useState(null);
    const [containerWidth, setContainerWidth] = useState();
    const maxWidth = '100%';
    const options = {
        cMapUrl: '/cmaps/',
        standardFontDataUrl: '/standard_fonts/',
      };

    // useResizeOb(containerRef, resizeObserverOptions, onResize);

    const onResize = useCallback((entries) => {
        const [entry] = entries;

        if (entry) {
        setContainerWidth(entry.contentRect.width);
        }
    }, []);

    
        useEffect(()=>
        {
            async function getSponsors(){
                let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/sponsor`)
                setSponsors(res.data)
            }
            async function getSamples(){
                let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/sample?status=unlinked&limit=100&offset=0`)
                setSamples(res.data.samples)
            }
            async function getTests(){
                let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/test`)
                setTests(res.data)
            }
            async function getSds(){
                let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/user?type=sd`)
                setSds(res.data)
            }
            // async function getTidsInfo(){
            //     let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/tids/${edit}`)
            //     // setSelectedTests()
            //     setManageData({...res.data})
            // }
            // getTidsInfo()
            getSponsors()
            getSamples()
            getTests()
            getSds()
        },[])
       
        useEffect(()=>
        {
            async function getProjectInfo(){
                console.log('updateData',updateData)
                let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/project/${updateData.project.id}`)
                // setShowTest(true)
                setFile(`${process.env.REACT_APP_STATIC_URL}/${res.data.project.tids.tidsDoc}`)
                setLoading(false)
                setManageData(res.data)
            }
            getProjectInfo()
        },[])
        
        
        const handleFileChange = (event) => {
            setManageData(prev=>({...prev,file:event.target.files[0]}));
        };


        const onFormSubmit = async(e)=>
        {
            setSubmitting(true)
            await onSubmit(e,{...manageData})
            setSubmitting(false)
        }


    return  <><SlidePopup size="xl" submitting={submitting} title={edit ? 'Complete Project Request' : 'Complete Project Request'} submitTitle={edit ? 'Update' : 'Create'} onClose={onClose} formName={formName}>
        { !loading ? <form onSubmit={onFormSubmit} id={formName} className='grid items-start grid-cols-4 gap-x-3 w-full'>
            <div className="flex h-full col-span-2 w-full overflow-y-scroll bg-[#f3f3f3] rounded-md p-2">
                <PDFViewer file={file}/>
            </div>
            <div className="grid grid-cols-2 items-start col-span-2 gap-x-3 gap-y-0 px-4 w-full">

            <div className="mb-4">
                <label>Sponsor Name*</label>
                <p className="text-xs">{manageData.project.sponsor.name}</p>
            </div>
            <div className="mb-4">
                <label>Test Item Name</label>
                <p className="text-xs">{manageData.project.tids.testItemName}</p>
            </div>
            <div className="w-full">
                <label>Sample ID</label>
                <Select placeholder={'Select Sample'} required={true} customLabel={'sampleId'} options={samples} customValue={'id'} value={manageData.sampleId} setValue={(value)=>setManageData(data=>({...data,sampleId:value}))}/>
            </div>
            <div className="flex">
                <div className="w-full">
                    <label>Sterility Type</label>
                    <Select placeholder={'Select Sterility Type'} required={true} customLabel={'name'} options={[{name:'Non-Sterile',id:STERILITY_TYPE_NON_STERILE},{name:'Sterile',id:STERILITY_TYPE_STERILE}]} customValue={'id'} value={manageData.sterilityType} setValue={(value)=>setManageData(data=>({...data,sterilityType:value}))}/>
                </div>
                {
                    manageData.sterilityType === STERILITY_TYPE_STERILE ? <div className="ml-2 w-[350px]">
                    <label>Sterile Type</label>
                    <Input type='text' placeholder={'Sterile Type'} required={true} value={manageData.sterileName} setValue={(value)=>setManageData(data=>({...data,sterileName:value}))}/>
                </div> : null
                }
            </div>
            <div>
                <label>Acceptable to Cut</label>
                <Select placeholder={'Select Acceptable to Cut'} required={true} options={[{name:'Yes',value:true},{name:'No',value:false}]} value={manageData.acceptableToCut} setValue={(value)=>setManageData(data=>({...data,acceptableToCut:value}))}/>
            </div>
            {/* <div>
                <label>Lot Number</label>
                <Input type='text' placeholder={'Lot Number'} required={true} value={manageData.lotNumber} setValue={(value)=>setManageData(data=>({...data,lotNumber:value}))}/>
            </div>
            <div>
                <label>Serial Number</label>
                <Input type='text' placeholder={'Serial Number'} required={true} value={manageData.serialNumber} setValue={(value)=>setManageData(data=>({...data,serialNumber:value}))}/>
            </div> */}
            <div>
                <label>Temperature</label>
                <Input type='number' placeholder={'Enter temperature'} required={true} value={manageData.temperature} setValue={(value)=>setManageData(data=>({...data,temperature:value}))}/>
            </div>
            <div>
                <label>pH</label>
                <Input type='number' min={1} max={10} placeholder={'Enter pH'} required={false} value={manageData.ph} setValue={(value)=>setManageData(data=>({...data,ph:value}))}/>
            </div>
            <div>
                <label>Sample Storage Condition</label>
                <Input type='text' placeholder={'Enter Sample Storage Condition'} required={true} value={manageData.sampleStorageCondition} setValue={(value)=>setManageData(data=>({...data,sampleStorageCondition:value}))}/>
            </div>
            <div>
                <label>GLR Storage Condition</label>
                <Input type='text' placeholder={'Enter GLR Storage Condition'} required={true} value={manageData.glrStorageCondition} setValue={(value)=>setManageData(data=>({...data,glrStorageCondition:value}))}/>
            </div>
            <div>
                <label>Extraction Ratio</label>
                <Input type='text' placeholder={'Enter Extraction Ratio'} required={true} value={manageData.extractionRatio} setValue={(value)=>setManageData(data=>({...data,extractionRatio:value}))}/>
            </div>
            <div>
                <label>Mfd. Date</label>
                <Input type='date' placeholder={'Select Mfd. Date'} required={true} value={manageData.mfdDate} setValue={(value)=>setManageData(data=>({...data,mfdDate:value}))}/>
            </div>
            <div>
                <label>Expiry Date</label>
                <Input type='date' placeholder={'Select Expiry Date'} required={true} value={manageData.expiryDate} setValue={(value)=>setManageData(data=>({...data,expiryDate:value}))}/>
            </div>
            <div className="col-span-2 border-t border-t-gray-200 my-4 pt-4">
                <p className="text-xs font-medium uppercase">Thickness Measurement</p>
            </div>
            <div>
                <label>Equipment Name</label>
                <Input type='text' placeholder={'Enter Equipment Name'} required={true} value={manageData.equipmentName} setValue={(value)=>setManageData(data=>({...data,equipmentName:value}))}/>
            </div>
            <div>
                <label>Equipment ID</label>
                <Input type='text' placeholder={'Enter Equipment ID'} required={true} value={manageData.equipmentId} setValue={(value)=>setManageData(data=>({...data,equipmentId:value}))}/>
            </div>
            <div>
                <label>Thickness</label>
                <Input type='text' placeholder={'Enter Thickness'} required={true} value={manageData.thickness} setValue={(value)=>setManageData(data=>({...data,thickness:value}))}/>
            </div>
            <div>
                <label>Measured Part of the Test Item</label>
                <Input type='text' placeholder={'Enter Measure Part Name'} required={true} value={manageData.measuredPart} setValue={(value)=>setManageData(data=>({...data,measuredPart:value}))}/>
            </div>
            <div>
                <label>Trial 1</label>
                <Input type='text' placeholder={'Enter Trial 1'} required={true} value={manageData.trial1} setValue={(value)=>setManageData(data=>({...data,trial1:value}))}/>
            </div>
            <div>
                <label>Trial 2</label>
                <Input type='text' placeholder={'Enter Trial 2'} required={true} value={manageData.trial2} setValue={(value)=>setManageData(data=>({...data,trial2:value}))}/>
            </div>
            <div>
                <label>Trial 3</label>
                <Input type='text' placeholder={'Enter Trial 3'} required={true} value={manageData.trial3} setValue={(value)=>setManageData(data=>({...data,trial3:value}))}/>
            </div>

            <div>
                <label>Test Item Image</label>
                <input className="text-input" type="file" placeholder={'Enter Test Item Image'} required={true} onChange={handleFileChange}/>
            </div>


            </div>
        </form> : <Loader/> }
    </SlidePopup> 
    </> 
}
