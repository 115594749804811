import React, { useEffect, useState } from 'react'
import StatusOrders from './components/StatusOrders'
import { RouteOrders } from './components/RouteOrders'
import { CityOrders } from './components/CityOrders'
import { PriceData } from './components/PriceData'
import { ProductOrders } from './components/ProductOrders'
import { DateRangePicker } from 'react-date-range';
import { addDays } from 'date-fns';
import axios from 'axios'
import Input from '../../components/Input'
import { getDateFormat, getValidDateFormat } from '../../helpers/utils'
import Loader from '../../components/Loader'
import { TeamOrders } from './components/TeamOrders'
import { RepOrders } from './components/RepOrders'
import { RepPoints } from './components/RepPoints'
import { RoutePoints } from './components/RoutePoints'
import { CityPoints } from './components/CityPoints'
import { TeamPoints } from './components/TeamPoints'
import { useSelector } from 'react-redux'
import { authAxios } from '../../helpers/axios'
import { ErrorToast } from '../../helpers/toasters'
import { HiOutlineClipboard } from 'react-icons/hi'

export default function Dashboard() {
  
  const [type,setType] = useState('1D')
  const [counts,setCounts] = useState('')
  const [analytics,setAnalytics] = useState(null)
  const [startDate,setStartDate] = useState('')
  const [endDate,setEndDate] = useState('')
  const [loading,setLoading] = useState(true)
  const authInfo = useSelector(state=>state.auth)


  const tabItems = [{label:'This Month',value:'this_month'},{label:'Last Month',value:'last_month'}]
  const [activeTab,setActiveTab] = useState('this_month')
  const [tasks,setTasks] = useState([])

  const getTasks = async()=>
  {
      try 
      {
          let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/user/my-tasks?status=${1}`) 
          if(res.data) setTasks(res.data)
      } catch (error) {
          ErrorToast(error.response.data.message)
      }
  }

  useEffect(()=>
{
    getTasks()
},[])

  return (
    !loading ? <div className='px-6 py-6 overflow-scroll'>
       {/* <p className='text-base'>Dashboard</p> */}
       <div className='pb-4'>
          <p className='text-lg font-semibold tracking-tight'>Welcome {authInfo?.userName}</p>
       </div>
       <div className='bg-[#f8f8fa] rounded-lg p-2'>
        <div className='border rounded-lg border-slate-100 flex justify-between bg-white'>
            <div className='py-6 px-6 border-r border-r-slate-200 w-full'>
                <p className='text-xs font-medium text-[#151515] mb-2'>Pending TIDS</p>
                <p className='text-3xl font-semibold'>05</p>
            </div>
            <div className='py-6 px-6 border-r border-r-slate-200 w-full'>
                <p className='text-xs font-medium text-[#151515] mb-2'>Pending Samples</p>
                <p className='text-3xl font-semibold'>{40}</p>
            </div>
            <div className='py-6 px-6 border-r border-r-slate-200 w-full'>
                <p className='text-xs font-medium text-[#151515] mb-2'>Samples Received</p>
                <p className='text-3xl font-semibold'>{16}</p>
            </div>
            <div className='py-6 px-6 w-full border-r border-r-slate-200'>
                <p className='text-xs font-medium text-[#151515] mb-2'>Samples Received</p>
                <p className='text-3xl font-semibold'>{16}</p>
            </div>
            <div className='py-6 px-6 w-full'>
                <p className='text-xs font-medium text-[#151515] mb-2'>Samples Received</p>
                <p className='text-3xl font-semibold'>{16}</p>
            </div>
        </div>
       </div>

      <div className='mt-8 grid grid-cols-4 gap-4 justify-between'>
        {
          tasks.map((item,index)=>
        {
          return <TaskItem item={item} key={index}/>
        })
        }
      </div>
    </div> : <div className='h-full w-full flex items-center justify-center'>
      <div className='flex-col items-center justify-center'>
        <HiOutlineClipboard fontSize={120} color='#e3e3e3'/>
        <p className='text-center text-xs font-medium text-[#c3c3c3]'>No Data to view</p>
      </div>
    </div>
  )
}


const TaskItem = ({item})=>
{
  return <div className='bg-[#f8f8fa] rounded-lg p-4 shadow-sm'>
      <p className='text-sm font-medium'>{item.name}</p>
      <p className='text-xs font-medium text-[#959595]'>{item.type}</p>
  </div>
}


