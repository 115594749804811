import React, { useEffect, useState } from 'react'
import SearchInput from '../../../components/SearchInput'
import { authAxios } from '../../../helpers/axios'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { ErrorToast, InfoToast } from '../../../helpers/toasters'
import { LIMIT } from '../../../helpers/constants'
import RightIcon from '../../../images/right.svg'
import SortIcon from '../../../images/sort.svg'
import FilterIcon from '../../../images/filter.svg'
import FilterActiveIcon from '../../../images/filteractive.svg'
import FilterRoute from '../components/RouteFilter'
import { getDateFormat, getTimeFormat } from '../../../helpers/utils'
import Select from '../../../components/Select'
import Input from '../../../components/Input'
import Popup from '../../../components/Popup'
import SmallPopup from '../../../components/SmallPopup'

export default function Test({setShowManage,showManage}) {

    const [warehouses,setTests] = useState([])
    const [showDeleteWarehouse,setShowWarehouseDelete] = useState(false)
    const [deleting,setDeleting] = useState(false)

    async function getTests(){

        try 
        {
            let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/test`)
            if(res.data)
            {
                setTests(res.data)
            }
        } catch (error) {
            console.log(error)
            toast('Error getting warehouses')
        }
    } 

    const onSubmit = async(e,data)=>
    {
        try 
        {
            e.preventDefault()
            let res;
            console.log('sbm',data)
            if(showManage.edit)
            {
                res = await authAxios.put(`${process.env.REACT_APP_BASE_URL}/test/${data.id}`,{...data})  
            }
            else
            {
                res = await authAxios.post(`${process.env.REACT_APP_BASE_URL}/test`,{...data}) 
                
            }
            if(res.data)
            {
                if(showManage.edit)
                {
                    setTests(warehouses=>{
                        let prev = [...warehouses]
                        let itemIndex = prev.findIndex(item=>item.id === showManage.edit)
                        console.log('itemIndex',itemIndex,'res',res.data)
                        prev[itemIndex] = {...prev[itemIndex],name:res.data.name}
                        return prev;
                    })
                }
                else setTests(warehouses=>([...warehouses,{...res.data}]))
                InfoToast(showManage.edit ? 'Warehouse Updated' : 'Warehouse Created',{position: toast.POSITION.BOTTOM_CENTER,hideProgressBar:true})
                setShowManage({type:null,status:false,edit:null})
            }
            else toast('error creating/updating Warehouse')
        } catch (error) {
            console.log(error)
            ErrorToast(error.response.data.error.name)
        }
    }


    const onTestDelete = async(e)=>
    {
        try 
        {
            setDeleting(true)
            await authAxios.delete(`${process.env.REACT_APP_BASE_URL}/test/${showDeleteWarehouse}`) 
            getTests()
            setShowWarehouseDelete(false)
            setDeleting(false)
            InfoToast('City Deleted')
        } catch (error) {
            setDeleting(false)
            ErrorToast('City Deletion Failed')
            
        }
    }



    useEffect(()=>
    {
        getTests();
    },[])


  return (
    <div className='flex flex-col w-full'>
        <div className='bg-white w-full flex-1 flex overflow-scroll'>
            <table className=' table-auto w-full flex-1 '>
                <thead className='bg-[#f9f9f9] w-full'>
                    <tr className='w-full'>
                    <td><p className='cursor-pointer flex items-center hover:text-black'>Test Name</p></td>
                    <td><p className='cursor-pointer flex items-center hover:text-black'>Test Duration</p></td>
                    <td><p className='cursor-pointer flex items-center hover:text-black'>Action</p></td>
                    </tr>
                </thead>
                <tbody>
                    {
                        warehouses.map((item,index)=>
                        {
                            return <tr>
                                <td className='capitalize'>
                                    <div>
                                        <p className='text-sm font-regular my-0'>{item.name}</p>
                                    </div>
                                </td>
                                <td className='capitalize'>
                                    <div>
                                        <p className='text-sm font-regular my-0'>{item.minDays} Days</p>
                                    </div>
                                </td>
                                <td className='capitalize'>
                                <div className='flex'>
                                    <button className='btn-xs' onClick={()=>setShowManage({type:'test',status:true,edit:item.id})}>Edit</button>
                                    <button className='btn-xs btn-red ml-2' onClick={()=>setShowWarehouseDelete(item.id)}>Delete</button>
                                    </div>
                                </td>
                            </tr>
                        })
                    }
                </tbody>
            </table>
        </div>
        { showManage.status && showManage.type ==='test' ? <ManageTest setShow={setShowManage} onSubmit={onSubmit} edit={showManage.edit} /> : null}
        {showDeleteWarehouse ? <DeleteTest submitting={deleting} onSubmit={onTestDelete} onClose={setShowWarehouseDelete}/> : null}
    </div>
  )
}

const DeleteTest = ({onSubmit,onClose,submitting})=>
{
    return <SmallPopup submitting={submitting} title={'Delete Test'} onClose={onClose} onSubmit={onSubmit} submitTitle={'Yes, Delete'}>
        <div>
            <p className="text-sm">Are you sure you want to delete the test?</p>
        </div>
    </SmallPopup>
}



const ManageTest = ({setShow,onSubmit,edit=false})=>
{
    const [city,setCity] = useState({id:null,name:''})
    const [loading,setLoading] = useState(edit ? true : false)
    useEffect(()=>
    {
        async function getCityInfo(){
            if(edit)
            {
                let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/test/${edit}`)
                // console.log(res.data.data)
                setCity({id:res.data.id,minDays:res.data.minDays,name:res.data.name})
                setLoading(false)
            }
        }
        getCityInfo()
    },[])
    return loading ? 'loading' :<Popup onClose={()=>setShow({type:null,status:false})}  title={edit ?  'Edit Test' : 'Add Test'} submitTitle={edit ? 'Update' : 'Add'} formName={'createTest'}>
        <form className='w-full' onSubmit={(e)=>onSubmit(e,edit ? city : {name:city.name})} id="createTest">
            <div>
                <label>Test Name</label>
                <Input placeholder={'Enter Test Name'} value={city.name} setValue={(value)=>setCity(city=>({...city,name:value}))} required={true}/>
            </div>
            <div>
                <label>Minimum Test Days</label>
                <Input placeholder={'Enter Number of days'} type='number' value={city.minDays} setValue={(value)=>setCity(city=>({...city,minDays:value}))} required={true}/>
            </div>
        </form>
    </Popup>
}
