import React, { useEffect, useState } from 'react'
import { authAxios } from '../../../helpers/axios'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { ErrorToast, InfoToast } from '../../../helpers/toasters'
import { LIMIT } from '../../../helpers/constants'
import ReactDataGrid from 'react-data-grid'

export default function ConsultantSample({id}) {

    const [searchText,setSearchText] = useState('')
    const [samples,setSamples] = useState([])
    const [showCreate,setShowCreate] = useState({status:false,edit:null})
    const navigate = useNavigate()
    const [sort,setSort] = useState('name');
    const [offset,setOffset] = useState(0);
    const [count,setCount] = useState(0)

    const columns = [
        { key: 'sampleId', name: 'Sample ID', frozen: true },
        { key: 'sponsorName', name: 'Sponsor Name' ,frozen:true,renderCell:({row})=><p>{row.sponsor.name}</p>},
        { key: 'testItemName', name: 'Test Item' ,renderCell:({row})=><p>{row.tids.testItemName}</p>},
        { key: 'quantity', name: 'Total Qty.' },
        { key: 'receivedDate', name: 'Received On' }
      ];
    

    const [selectedFilters,setSelectedFilters] = useState({city:'',route:''})

    const onSortPress = (type)=>
    {
        if (sort === type) {
            if(sort === `-${type}`) setSort(type)
            else setSort(`-${type}`);
          } else {
            setSort(type);
          }
    }

    async function getSamples(){

        try 
        {
            let query = `&limit=${LIMIT}&offset=${offset}&orderBy=${sort}`;
                if(searchText) query+= `&search=${searchText}`;
                query+= `&sponsorId=${id}`;
            let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/sample?populate=true${query}`)
            if(res.data)
            {
                setSamples(res.data)
                setCount(res.data.count)
            }
        } catch (error) {
            toast('Error getting samples')
        }
    }
    useEffect(()=>
    {
        getSamples();
    },[sort,searchText,offset])


    const onSubmit = async(e,data,croppedImage)=>
    {
        try 
        {
            e.preventDefault()
            let res;
            if(showCreate.edit)
            {
                res = await authAxios.put(`${process.env.REACT_APP_BASE_URL}/sample/${showCreate.edit}`,data) 
                InfoToast('Sample Updated')
                
            }
            else
            {
                res = await authAxios.post(`${process.env.REACT_APP_BASE_URL}/sample`,data)
                InfoToast('Sample Created')
            }
            if(res.data)
            {
                await getSamples()
                setShowCreate({status:false,edit:null})
            }
            else toast('error updating retailer')
        } catch (error) {
            // console.log(error.response.data.error[0])
            ErrorToast(error.response.data.error[Object.keys(error.response.data.error)[0]])
        }
    }


  return (
    <>
        <div className='block mt-4 flex-1 bg-white w-full overflow-scroll'>
        <ReactDataGrid
        rowHeight={40}
        rows={samples}
        className='w-full'
      columns={columns}
    //   renderers={rowGetter}
    //   rowsCount={tids.length}
    //   minWidth={800} // Minimum width for the table
    //   minHeight={400} // Minimum height for the table
      enableCellSelect={false}
    />
        </div>
    </>
  )
}

