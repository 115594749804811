import React, { useEffect, useState } from 'react'
import { authAxios } from '../../../helpers/axios'
import { toast } from 'react-toastify'
import { ErrorToast, InfoToast } from '../../../helpers/toasters'
import Input from '../../../components/Input'
import Popup from '../../../components/Popup'
import SmallPopup from '../../../components/SmallPopup'

export default function RackTypes({setShowManage,showManage}) {

    const [racktypes,setRackTypes] = useState([])
    const [showDeleteWarehouse,setShowWarehouseDelete] = useState(false)
    const [deleting,setDeleting] = useState(false)

    async function getRacktypes(){

        try 
        {
            let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/rack-type`)
            if(res.data)
            {
                setRackTypes(res.data)
            }
        } catch (error) {
            console.log(error)
            toast('Error getting orders')
        }
    } 

    const onSubmit = async(e,data)=>
    {
        try 
        {
            e.preventDefault()
            let res;
            console.log('sbm',data)
            if(showManage.edit)
            {
                res = await authAxios.put(`${process.env.REACT_APP_BASE_URL}/rack-type/${data.id}`,{...data})  
            }
            else
            {
                res = await authAxios.post(`${process.env.REACT_APP_BASE_URL}/rack-type`,{...data}) 
                
            }
            if(res.data)
            {
                if(showManage.edit)
                {
                    setRackTypes(racktypes=>{
                        let prev = [...racktypes]
                        let itemIndex = prev.findIndex(item=>item.id === showManage.edit)
                        console.log('itemIndex',itemIndex,'res',res.data)
                        prev[itemIndex] = {...prev[itemIndex],name:res.data.name}
                        return prev;
                    })
                }
                else setRackTypes(racktypes=>([...racktypes,{...res.data}]))
                InfoToast(showManage.edit ? 'Rack Type Updated' : 'Rack Type Created',{position: toast.POSITION.BOTTOM_CENTER,hideProgressBar:true})
                setShowManage({type:null,status:false,edit:null})
            }
            else toast('error creating/updating Rack Type')
        } catch (error) {
            console.log(error)
            ErrorToast(error.response.data.error.name)
        }
    }


    const onWarehouseDelete = async(e)=>
    {
        try 
        {
            setDeleting(true)
            await authAxios.delete(`${process.env.REACT_APP_BASE_URL}/rack-type/${showDeleteWarehouse}`) 
            getRacktypes()
            setShowWarehouseDelete(false)
            setDeleting(false)
            InfoToast('Warehouse Deleted')
        } catch (error) {
            setDeleting(false)
            ErrorToast('Warehouse Deletion Failed')
            
        }
    }



    useEffect(()=>
    {
        getRacktypes();
    },[])


  return (
    <div className='flex flex-col w-full'>
        <div className='bg-white w-full flex-1 flex overflow-scroll'>
            <table className=' table-auto w-full flex-1 '>
                <thead className='bg-[#f9f9f9] w-full'>
                    <tr className='w-full'>
                    <td><p className='cursor-pointer flex items-center hover:text-black'>Rack Type Name</p></td>
                    <td><p className='cursor-pointer flex items-center hover:text-black'>Rack Rows</p></td>
                    <td><p className='cursor-pointer flex items-center hover:text-black'>Rack Columns</p></td>
                    <td><p className='cursor-pointer flex items-center hover:text-black'>Action</p></td>
                    </tr>
                </thead>
                <tbody>
                    {
                        racktypes.map((item,index)=>
                        {
                            return <tr>
                                <td className='capitalize'>
                                    <div>
                                        <p className='text-sm font-regular my-0'>{item.name}</p>
                                    </div>
                                </td>
                                <td className='capitalize'>
                                    <div>
                                        <p className='text-sm font-regular my-0'>{item.rows}</p>
                                    </div>
                                </td>
                                <td className='capitalize'>
                                    <div>
                                        <p className='text-sm font-regular my-0'>{item.columns}</p>
                                    </div>
                                </td>
                                <td className='capitalize'>
                                <div className='flex'>
                                    <button className='btn-xs' onClick={()=>setShowManage({type:'rack-types',status:true,edit:item.id})}>Edit</button>
                                    <button className='btn-xs btn-red ml-2' onClick={()=>setShowWarehouseDelete(item.id)}>Delete</button>
                                    </div>
                                </td>
                            </tr>
                        })
                    }
                </tbody>
            </table>
        </div>
        { showManage.status && showManage.type ==='rack-types' ? <ManageCity setShow={setShowManage} onSubmit={onSubmit} edit={showManage.edit} /> : null}
        {showDeleteWarehouse ? <DeleteCity submitting={deleting} onSubmit={onWarehouseDelete} onClose={setShowWarehouseDelete}/> : null}
    </div>
  )
}

const DeleteCity = ({onSubmit,onClose,submitting})=>
{
    return <SmallPopup submitting={submitting} title={'Delete Warehouse'} onClose={onClose} onSubmit={onSubmit} submitTitle={'Yes, Delete'}>
        <div>
            <p className="text-sm">Are you sure you want to delete the warehouse?</p>
        </div>
    </SmallPopup>
}



const ManageCity = ({setShow,onSubmit,edit=false})=>
{
    const [rackType,setRackType] = useState({id:null,name:'',rows:'',columns:''})
    const [loading,setLoading] = useState(edit ? true : false)
    useEffect(()=>
    {
        async function getRackTypeInfo(){
            if(edit)
            {
                let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/rack-type/${edit}`)
                setRackType({id:res.data.id,name:res.data.name,columns:res.data.columns,rows:res.data.rows})
                setLoading(false)
            }
        }
        getRackTypeInfo()
    },[])
    return loading ? 'loading' :<Popup onClose={()=>setShow({type:null,status:false})}  title={edit ?  'Edit Rack Type' : 'Add Rack Type'} submitTitle={edit ? 'Update' : 'Add'} formName={'createRackType'}>
        <form className='w-full' onSubmit={(e)=>onSubmit(e,edit ? rackType : {name:rackType.name,rows:rackType.rows,columns:rackType.columns})} id="createRackType">
            <div>
                <label>Rack Name</label>
                <Input placeholder={'Enter Rack Type Name'} value={rackType.name} setValue={(value)=>setRackType(city=>({...city,name:value}))} required={true}/>
            </div>
            <div>
                <label>Rack Rows</label>
                <Input placeholder={'Enter Rows'} value={rackType.rows} setValue={(value)=>setRackType(city=>({...city,rows:value}))} required={true}/>
            </div>
            <div>
                <label>Rack Columns</label>
                <Input placeholder={'Enter Columns'} value={rackType.columns} setValue={(value)=>setRackType(city=>({...city,columns:value}))} required={true}/>
            </div>
        </form>
    </Popup>
}
