import React, { useEffect, useState } from 'react'
import Header from '../../components/Header'
import { authAxios } from '../../helpers/axios'
import { toast } from 'react-toastify'
import { useParams } from 'react-router-dom'
import TabGroup from '../../components/TabGroup'
import ManageUser from './components/ManageUser'
import SalesOrder from './components/SalesOrder'
import SalesRoute from './components/UserLogs'
import UserLogs from './components/UserLogs'
import { ErrorToast } from '../../helpers/toasters'

export default function UserInfo() {

    const {id} = useParams();
    // const id = 'd48a0ac8-0aac-ce9f-2f43-b0823a8458d1'
    const [userInfo,setUserInfo] = useState([])
    const [orders,setOrders] = useState([])
    const [showEdit,setShowEdit] = useState(false)
    const [activeTab,setActiveTab] = useState('activity-logs')
    const [submitting,setSubmitting] = useState(false)

    const RightContent = ()=>
    {
        return <div>
          <button type='button' className='btn-md' onClick={()=>setShowEdit(true)}>Edit User</button>
        </div>
    }

    async function getUserInfo(){

        try 
        {
            let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/user/${id}?populate=true`)
            if(res.data) setUserInfo(res.data)
        } catch (error) {
            toast('Error getting users')
        }
    }
    useEffect(()=>
    {
        getUserInfo();
    },[])

    const onSubmit = async(e,data)=>
    {
        try 
        {
            e.preventDefault()
                setSubmitting(true)
                let res = await authAxios.put(`${process.env.REACT_APP_BASE_URL}/user/${id}?populate=true`,data) 
                if(res.data)
                {
                    await getUserInfo()
                }
                else toast('error updating user')
                setShowEdit(false)
                setSubmitting(false)
        } catch (error) {
            setSubmitting(false)
            ErrorToast(error.response.data)
        }
    }

  return (
    <>
        <Header title={userInfo.name} RightContent={RightContent} parentTitle='User' parentLink='users'/>
        <div className='flex justify-between items-stretch my-2 bg-white'>
            <div>
               <TabGroup setActiveTab={setActiveTab} activeTab={activeTab} options={[{label:'Activity Logs',value:'activity-logs'},{label:'Tasks',value:'tasks'},{label:'Profile',value:'profile'}]}/>
            </div>
        </div>
        <div className='bg-white w-full flex-1 flex overflow-scroll'>
            {activeTab === 'activity-logs' ? <UserLogs id={id}/> : <ProfileInfo id={id}/>}
            
        </div>
        { showEdit ? <ManageUser submitting={submitting} onClose={setShowEdit} onSubmit={onSubmit} edit={id} updateData={userInfo}/> : null}
    </>
  )
}




const ProfileInfo = ({id})=>
{
    const [info,setInfo] = useState({})
    useEffect(()=>
    {
        async function getUserInfo(){
            let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/user/${id}?populate=true`)
            setInfo(res.data)
        }
        getUserInfo()
    },[])
    return <div className='flex justify-start items-start h-[100%] w-full self-stretch py-12 px-6'>
        <div className='grid grid-cols-4 gap-6'>
            <div>
                <label>Name</label>
                <p className='text-sm'>{info?.name}</p>
            </div>
            <div>
                <label>Employee Id</label>
                <p className='text-sm'>{info?.empId}</p>
            </div>
            <div>
                <label>Email</label>
                <p className='text-sm'>{info?.email}</p>
            </div>
            <div>
                <label>Contact Number</label>
                <p className='text-sm'>{info?.contactNumber}</p>
            </div>
            <div>
                <label>Role</label>
                <p className='text-sm'>{info?.role}</p>
            </div>
            {info?.tempPass ? <div>
                <label>Temporary Password</label>
                <p className='text-sm'>{info?.tempPass}</p>
            </div> : null}
        </div>
    </div>
}
