import React, { useEffect, useState } from 'react'
import Header from '../../components/Header'
import SearchInput from '../../components/SearchInput'
import { authAxios } from '../../helpers/axios'
import { toast } from 'react-toastify'
import Popup from '../../components/Popup'
import Input from '../../components/Input'
import FilterSelect from '../../components/FilterSelect'
import Select from '../../components/Select'
import Map from '../../components/Map'
import { useParams } from 'react-router-dom'
import TabGroup from '../../components/TabGroup'
import ManageConsultant from './components/ManageConsultant'
import { ErrorToast, InfoToast } from '../../helpers/toasters'
import ConsultantStudies from './components/ConsultantStudies'
import ConsultantSample from './components/ConsultantSamples'
import ConsultantTids from './components/ConsultantTids'
import ConsultantSponsors from './components/ConsultantSponsors'

export default function ConsultantInfo() {

    const {id} = useParams();
    const [consultantInfo,setConsultantInfo] = useState([])
    const [showEdit,setShowEdit] = useState(false)
    const [orders,setOrders] = useState([])
    const [activeTab,setActiveTab] = useState('study')
    const [stocks,setStocks] = useState([])

    const RightContent = ()=>
    {
        return <div>
          <button type='button' className='btn-md' onClick={()=>setShowEdit(true)}>Edit Consultant</button>
        </div>
    }

    async function getDistributors(){

        try 
        {
            let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/consultant/${id}?populate=true`)
            if(res.data) setConsultantInfo(res.data)
            // let Ordersres = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/orders?distributorId=${id}&populate=true`)
            // if(Ordersres.data.data) setOrders(Ordersres.data.data)
        } catch (error) {
            toast('Error getting Consultant Info')
        }
    }
    useEffect(()=>
    {
        getDistributors();
    },[])

    const onSubmit = async(e,data)=>
    {
        try 
        {
            e.preventDefault()
            let res = await authAxios.put(`${process.env.REACT_APP_BASE_URL}/consultant/${id}`,data) 
            if(res.data.data)
            {
                await getDistributors()
                setShowEdit(false)
            }
            else toast('error updating distributor')
        } catch (error) {
            toast(error.response.data)
        }
    }

  return (
    <>
        <Header  parentLink='distributors' parentTitle='Consultants' title={consultantInfo.name} RightContent={RightContent}/>
        <div className='flex justify-between items-stretch '>
            <div>
               <TabGroup activeTab={activeTab} setActiveTab={setActiveTab} options={[{label:'Study',value:'study'},{label:'Samples',value:'samples'},{label:'TIDS',value:'tids'},{label:'Sponsors',value:'sponsors'},{label:'Profile',value:'profile'}]}/>
            </div>
        </div>
        <div className='flex flex-1 overflow-scroll bg-white w-full'>
            {activeTab === 'study' ? <ConsultantStudies id={id}/> : activeTab === 'samples' ? <ConsultantSample id={id}/> : activeTab === 'tids' ? <ConsultantTids id={id}/> : activeTab === 'sponsors' ? <ConsultantSponsors id={id}/> : <ProfileInfo data={consultantInfo}/>}
            
        </div>
        { showEdit ? <ManageConsultant onClose={setShowEdit} onSubmit={onSubmit} update={true} updateData={consultantInfo}/> : null}
    </>
  )
}


const ProfileInfo = ({data})=>
{
    // const [info,setInfo] = useState({})
    const [cities,setCities] = useState('')
    const [routes,setRoutes] = useState('')

    useEffect(()=>
    {
        if(data.distributorRoutes)
        {
            let cityNames = [];
            let routeNames = [];
            
            data.distributorRoutes.forEach(item => {
                // Extract city name
                const cityName = item.cityInfo.cityName;
                cityNames.push(cityName);
                
                // Extract route names
                item.routes.forEach(route => {
                    const routeName = route.routeInfo.routeName;
                    routeNames.push(routeName);
                });
            });
            
            setCities(cityNames.join(', '));
            setRoutes(routeNames.join(', '));
            
        }
    },[data])

    return <div className='flex justify-start items-start h-[100%] py-12 px-6'>
        {/* <div className='mb-4 w-[120px] h-[120px] mr-20'>
            <img src={info?.retailerImage} className='w-[120px] h-[120px] rounded-full'/>
        </div> */}
        <div className='grid grid-cols-4 gap-6'>
            <div>
                <label>Company Name</label>
                <p className='text-sm'>{data?.distributorCompanyName}</p>
            </div>
            <div>
                <label>Owner Name</label>
                <p className='text-sm'>{data?.distributorName}</p>
            </div>
            <div>
                <label>Contact Number</label>
                <p className='text-sm'>{data?.distributorContactNumber}</p>
            </div>
            <div>
                <label>Email Address</label>
                <p className='text-sm'>{data?.distributorEmail}</p>
            </div>
            <div>
                <label>Cities</label>
                <p className='text-sm'>{cities}</p>
            </div>
            <div>
                <label>Routes</label>
                <p className='text-sm'>{routes}</p>
            </div>
            <div>
                <label>Area</label>
                <p className='text-sm'>{data?.distributorArea}</p>
            </div>
            <div>
                <label>Address</label>
                <p className='text-sm'>{data?.distributorAddress}</p>
            </div>
            {data.distributorLat ? <div>
                <label>Map Location</label>
                <a href={`https://maps.google.com/?q=${data.distriutorLat},${data.distriutorLong}`} className='text-sm text-blue-600 underline' target='_blank'>Map Link</a>
            </div> : null}
        </div>
    </div>
}
