import { useEffect, useState } from "react"
import Input from "../../../components/Input"
import Map from "../../../components/Map"
import Popup from "../../../components/Popup"
import Select from "../../../components/Select"
import { authAxios } from "../../../helpers/axios"
import ImageInput from "../../../components/ImageInput"
import CropPopup from "../../../components/CropPopup"
import { RETAILER_IMAGE_BASE_URL } from "../../../helpers/constants"
import { dataURItoBlob, getDateFormat } from "../../../helpers/utils"
import Header from "../../../components/Header"
import { ErrorToast, InfoToast } from "../../../helpers/toasters"
import { useNavigate } from "react-router-dom"

export default function CreateProject({onClose,edit=false,updateData})
{
    const [manageData,setManageData] = useState({sponsorId:'',tidsId:'',sampleId:'',tests:[]})
    const [sponsors,setSponsors] = useState([])
    const [hsn,setHsn] = useState([])
    const [showCrop,setShowCrop] = useState(false)
    const formName = 'editRetailer';
    const navigate = useNavigate()
    const [image,setImage] = useState(null)
    const [croppedImage,setCroppedImage] = useState()
    const [tests,setTests] = useState([])
    const [sds,setSds] = useState([])
    const [tids,setTids] = useState([])
    const [selectedTests,setSelectedTests] = useState([])

    
        useEffect(()=>
        {
            async function getSponsors(){
                let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/sponsor`)
                setSponsors(res.data)
            }
            async function getTests(){
                let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/test`)
                setTests(res.data)
            }
            async function getSds(){
                let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/user?type=sd`)
                setSds(res.data)
            }
            getSponsors()
            getTests()
            getSds()
        },[])
        useEffect(()=>
        {
            async function getTids(){
                let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/tids?sponsorId=${manageData.sponsorId}`)
                setTids(res.data)
            }
            getTids()
        },[manageData.sponsorId])



        const handleTestSelect = (index, test) => {
            const updatedTests = [...selectedTests];
            updatedTests[index] = test;
            setSelectedTests(updatedTests);
          };

          const getSelectedTestIds = () => {
            console.log('selectedTest',selectedTests)
            return selectedTests.map(test => test).join(',');
          };

          const onInitiate = async(e)=>
          {
              try 
              {
                e.preventDefault()
                let res = await authAxios.post(`${process.env.REACT_APP_BASE_URL}/project/initiate`,{tidsId:manageData.tidsId})
                setManageData(prev=>({...prev,...res.data}))
                InfoToast('TIDS Created')
              } catch (error) {
                  // console.log(error.response.data.error[0])
                  ErrorToast(error.response.data.error)
              }
          }
          const onSubmit = async(e)=>
          {
              try 
              {
                e.preventDefault()
                let res = await authAxios.post(`${process.env.REACT_APP_BASE_URL}/project`,manageData)
                setManageData(prev=>({...prev,...res.data}))
                InfoToast('Project Created')
                navigate('/project')
              } catch (error) {
                  // console.log(error.response.data.error[0])
                  ErrorToast(error.response.data.error[Object.keys(error.response.data.error)[0]])
              }
          }




    return <>
    <Header title={'Create Project'} RightContent={()=><div></div>}/>
    <div className='flex flex-1 py-6 bg-white w-full overflow-scroll'>
        <div>
        <form onSubmit={(e)=>onSubmit(e)} id={formName} className='grid gap-x-3 w-full'>
            <div className="grid grid-cols-4 gap-x-3 gap-y-0 w-full items-end">
            <div className="col-span-1 w-full">
                <label>Select Sponsor*</label>
                <Select placeholder={'Select Sponsor'} required={true} customLabel={'name'} options={sponsors} customValue={'id'} value={manageData.sponsorId} setValue={(value)=>setManageData(data=>({...data,sponsorId:value}))}/>
            </div>
            <div className="col-span-1 w-full">
                <label>Mode</label>
                <Select placeholder={'Select Mode'} required={true} customLabel={'testItemName'} options={[{value:'draft',name:'Draft'},{value:'live',name:'Live'}]} customValue={'id'} value={manageData.tidsId} setValue={(value)=>setManageData(data=>({...data,tidsId:value}))}/>
            </div>
            <div className="col-span-1 w-full">
                <label>Select TIDS*</label>
                <Select placeholder={'Select TIDS'} required={true} customLabel={'testItemName'} options={tids} customValue={'id'} value={manageData.tidsId} setValue={(value)=>setManageData(data=>({...data,tidsId:value}))}/>
            </div>
            {/* <div>
                <label>Select Sample</label>
                <Select placeholder={'Select Acceptable to Cut'} required={true} options={[{name:'True',value:true},{name:'False',value:false}]} value={manageData.acceptableToCut} setValue={(value)=>setManageData(data=>({...data,acceptableToCut:value}))}/>
            </div> */}
            <div>
                <button type="button" className="btn-xs mb-5" onClick={onInitiate}>Initiate</button>
            </div>
            </div>
            <div className="max-w-[920px]">
                <label>Studies</label>
                {manageData.tests.map((selectedTest, index) => (
                    <StudyItem index={index} item={selectedTest} tests={tests} sds={sds} data={manageData} setManageData={setManageData}/>
                ))}
            </div>
            {manageData.tests.length > 0 ?<div>
                <button type="submit" className="btn-xs mb-5">Create Studies</button>
            </div> : null}
        </form>
        </div>
    </div>
    </>
}


const StudyItem = ({item,sds,setManageData,tests,index,data})=>
{

    const updateValue = (type,newValue)=>
    {
        setManageData(prevState => {
            // Create a copy of the previous state array
            const newArray = [...prevState.tests];
        
            // Find the object at the specified index
            const updatedObject = { ...newArray[index] };
        
            // Update the nested field of the object
            if(type==='date') updatedObject.expectedDeliveryDate = newValue;
            else updatedObject.sdAssigned = newValue;
        
            // Update the object in the array
            newArray[index] = updatedObject;
        
            // Return the updated state
            return { ...prevState,tests: newArray };
          });
    }
    return <div className="grid grid-cols-12 border border-gray-200 shadow-sm rounded-md my-4 bg-[#fcfcfc] w-full">
            <div className="col-span-2">
                <div className="relative z-0">
                    <div className="absolute top-0 left-0 h-full flex items-center pl-3 -z-10">
                        <p className="text-[13px] absolute whitespace-nowrap">{data?.uniqueNumber?.uniqueNumber} /</p> 
                    </div>
                    <input type='number' className=" px-3 py-2 pl-16 text-[13px] border-r border-r-gray-200 bg-transparent w-full" placeholder={''} required={true} value={item.studyNumber} onChange={(e)=>setManageData(data=>({...data,studyNumber:e.target.value}))}/>

                </div>
            </div>
            <div className="col-span-5 w-full">
            <select placeholder={'Select Test'} disabled={true} className="px-3 py-2 text-[13px] border-0 border-r border-r-gray-200 rounded-none bg-transparent mb-0 w-full" required={true} options={tests} value={item.id} onChange={(e)=>setManageData(data=>({...data,testId:e.target.value}))}>
                {
                    tests.map((testItem,index)=>
                    {
                        return <option value={testItem.id} key={index}>{testItem.name}</option>
                    })
                }
            </select>
            </div>
            {/* <div className="w-full col-span-2">
            <input type='date' className="px-3 py-2 border-r text-[13px] border-r-gray-200 bg-transparent  w-full" placeholder={''} required={true} defaultValue={getDateFormat(item.expectedDeliveryDate)} onChange={(e)=>updateValue('date',e.target.value)}/>
            </div> */}
            <div className="w-full col-span-3">
            <select placeholder={'Select Sd'} className="px-3 text-[13px] py-2 border-0 rounded-none bg-transparent mb-0 w-full" required={true} options={sds} defaultValue={item.id} onChange={(e)=>updateValue('sd',e.target.value)}>
                <option value={''}>Select Sd</option>
                {
                    sds.map((sdItem,index)=>
                    {
                        return <option value={sdItem.id} key={index}>{sdItem.name}</option>
                    })
                }
            </select>
            </div>
    </div>
}