import React, { useEffect, useState } from 'react'
import Header from '../../components/Header'
import SearchInput from '../../components/SearchInput'
import { authAxios } from '../../helpers/axios'
import { toast } from 'react-toastify'
import FilterSelect from '../../components/FilterSelect'
import { useNavigate } from 'react-router-dom'
import { base64ToBlob, dataURItoBlob, getDateFormat } from '../../helpers/utils'
import { ErrorToast, InfoToast } from '../../helpers/toasters'
import SortIcon from '../../images/sort.svg'
import RightIcon from '../../images/right.svg'
import FilterIcon from '../../images/filter.svg'
import RupeeIcon from '../../images/rupee.svg'
import { LIMIT, PROJECT_STATUS_SAMPLE_REQUESTED } from '../../helpers/constants'
import { IoDocumentTextOutline } from 'react-icons/io5'
import { TbSquareRoundedPlusFilled } from 'react-icons/tb'
import ManageProject from './components/ManageProject'
import ReactDataGrid from 'react-data-grid';
import ManageProjectRequest from './components/ManageProjectRequest'

export default function ProjectRequest() {

    const [searchText,setSearchText] = useState('')
    const [projects,setProjects] = useState([])
    const navigation = useNavigate()
    const [showCreate,setShowCreate] = useState({status:false,edit:null})

    const [cityFilters,setCityFilters] = useState([])
    const [routeFilters,setRouteFilters] = useState([])
    const [sort,setSort] = useState('name');
    const [offset,setOffset] = useState(0);
    const [count,setCount] = useState(0)

    const columns = [
        // { key: 'id', name: 'Sample ID', frozen: true, width: 150},
        { key: 'testItemName', name: 'Test Item' ,width: 250,renderCell:({row})=><p >{row.tids.testItemName}</p>},
        { key: 'sponsor', name: 'Sponsor' ,width: 250,renderCell:({row})=><p>{row.sponsor.name}</p>},
        { key: 'consultant', name: 'Consultant' ,width: 250,renderCell:({row})=><p>{row.consultant ? row.consultant.name : ''}</p>},
        // { key: 'tests', name: 'Not of Tests' ,width: 100,renderCell:({row})=><p>{row.tids.tests.length}</p>},
        { key: 'createdAt', name: 'Created On',width:200,renderCell:({row})=><p>{getDateFormat(row.createdAt)}</p>},
        { key: 'sponsorName', name: 'Sponsor Name' ,width: 150,frozen:true,renderCell:({row})=><p>{row.sponsor.name}</p>},
      ];
    

    const [selectedFilters,setSelectedFilters] = useState({city:'',route:''})
    const RightContent = ()=>
    {
        return <>
        <div className='flex justify-end'>
            <div className="mr-2">
                <SearchInput value={searchText} setValue={(value)=>setSearchText(value)} placeholder={'Search Project'} label={'Search Project'} />
            </div>
            <div className="flex items-center">
                {/* <button className='rounded-full' onClick={()=>setShowCreate({status:true,edit:false})}><TbSquareRoundedPlusFilled fontSize={40} lightingColor={'#fff'} color='#182159' className='ml-2'/></button> */}
            </div>
            </div>
            </>
    }

    const onSortPress = (type)=>
    {
        if (sort === type) {
            if(sort === `-${type}`) setSort(type)
            else setSort(`-${type}`);
          } else {
            setSort(type);
          }
    }

    async function getProjects(){

        try 
        {
            let query = `&limit=${LIMIT}&offset=${offset}&orderBy=${sort}`;
                query+= `&status=${PROJECT_STATUS_SAMPLE_REQUESTED}`;
                if(searchText) query+= `&search=${searchText}`;
            let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/project?populate=true${query}`)
            if(res.data)
            {
                setProjects(res.data.projects)
                setCount(res.data.totalCount)
            }
        } catch (error) {
            toast('Error getting projects')
        }
    }
    useEffect(()=>
    {
        getProjects();
    },[sort,searchText,offset])


    const onSubmit = async(e,data)=>
    {
        try 
        {
            e.preventDefault()

            let res;
            if(showCreate.edit)
            {
                res = await authAxios.put(`${process.env.REACT_APP_BASE_URL}/project/${showCreate.edit}`,data) 
                InfoToast('Project Updated')
                
            }
            else
            {
                res = await authAxios.post(`${process.env.REACT_APP_BASE_URL}/project/complete-request`,data)
                InfoToast('Project Request Completed')
            }
            await getProjects()
            setShowCreate({status:false,edit:null})
        } catch (error) {
            console.log('errro',error?.response?.data)
            // ErrorToast(error.response?.data?.name)
        }
    }


  return (
    <>
        <Header title={'Project Request Management'} RightContent={RightContent}/>
        <div className='flex flex-1 bg-white w-full overflow-scroll'>
        <ReactDataGrid
        rowHeight={40}
        onCellClick={(args)=>navigation(`/project-request/${args.row.id}`)}
        rows={projects}
        className='w-full'
      columns={columns}
      enableCellSelect={false}
    />
        </div>
        { showCreate.status ? <ManageProjectRequest onClose={setShowCreate} onSubmit={onSubmit} edit={showCreate.edit}/> : null}
    </>
  )
}

