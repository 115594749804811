import React, { useState } from 'react'
import { Document, Page, pdfjs } from "react-pdf";
import 'react-pdf/dist/Page/TextLayer.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';

// pdfjs.GlobalWorkerOptions.workerSrc = new URL(
//     'pdfjs-dist/build/pdf.worker.min.js',
//     import.meta.url,
//   ).toString();

pdfjs.GlobalWorkerOptions.workerSrc = `/pdf.4.2.67.worker.min.js`;
// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/4.2.67/pdf.worker.min.js`;

// pdfjs.GlobalWorkerOptions.workerSrc = new URL(
//     'pdfjs-dist/legacy/build/pdf.worker.min.js',
//      import.meta.url,
//    ).toString();

const PDFViewer = ({file})=> {

      
    // const [numPages, setNumPages] = useState();
    // const [pageNumber, setPageNumber] = useState(1);

    // const options = {
    //     cMapUrl: '/cmaps/',
    //     standardFontDataUrl: '/standard_fonts/',
    //   };

    // function onDocumentLoadSuccess({ numPages }) {
    //   setNumPages(numPages);
    // }


  return (
    // <div>
    //     <Document onLoadSuccess={onDocumentLoadSuccess} file={file} className="h-full w-full rounded-sm " options={options}>
    //         {Array.from(new Array(numPages), (el, index) => (
    //             <Page
    //                 key={`page_${index + 1}`}
    //                 pageNumber={index + 1}
    //                 // width={containerWidth ? Math.min(containerWidth, maxWidth) : maxWidth}
    //             />
    //             ))}
    //     </Document>
    // </div>
    <object
	data={file}
  className='h-full w-full'
	type="application/pdf"
>
	<iframe
		src={file}
    className='h-full w-full'
	>
		<p>
			Your browser does not support PDFs.
			<a href={file}>Download the PDF</a>
			.
		</p>
	</iframe>
</object>
  )
}

export default React.memo(PDFViewer);

