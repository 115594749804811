import React, { useEffect, useState } from 'react'
import Header from '../../components/Header'
import SearchInput from '../../components/SearchInput'
import { authAxios } from '../../helpers/axios'
import { toast } from 'react-toastify'
import FilterSelect from '../../components/FilterSelect'
import { useNavigate } from 'react-router-dom'
import ManageSample from './components/ManageSample'
import { base64ToBlob, dataURItoBlob } from '../../helpers/utils'
import { ErrorToast, InfoToast } from '../../helpers/toasters'
import SortIcon from '../../images/sort.svg'
import RightIcon from '../../images/right.svg'
import FilterIcon from '../../images/filter.svg'
import RupeeIcon from '../../images/rupee.svg'
import { LIMIT } from '../../helpers/constants'
import { CiBoxes } from 'react-icons/ci'
import ReactDataGrid from 'react-data-grid'
import { PiPlusBold, PiPlusCircle } from 'react-icons/pi'
import ManageRack from './components/ManageRack'

export default function SampleTracker() {

    const [searchText,setSearchText] = useState('')
    const [racks,setRacks] = useState([])
    const [showCreate,setShowCreate] = useState({status:false,edit:null,type:null})
    const [activeWarehouse,setActiveWarehouse] = useState('')
    const [activeRack,setActiveRack] = useState('')
    const [warehouses,setWarehouses] = useState([])
    const navigate = useNavigate()
    const [sort,setSort] = useState('name');
    const [offset,setOffset] = useState(0);
    const [count,setCount] = useState(0)
    const [samples,setSamples] = useState([])

    const columns = [
        { key: 'sampleId', name: 'Sample ID', frozen: true },
        { key: 'sponsorName', name: 'Sponsor Name' ,frozen:true,renderCell:({row})=><p>{row.sponsor.name}</p>},
        { key: 'testItemName', name: 'Test Item' ,renderCell:({row})=><p>{row.tids.testItemName}</p>},
        { key: 'quantity', name: 'Total Qty.' },
        { key: 'receivedDate', name: 'Received On' }
      ];
    

    const [selectedFilters,setSelectedFilters] = useState({city:'',route:''})
    const RightContent = ()=>
    {
        return <>
        <div className='flex justify-end'>
            <div className="mr-2">
                <SearchInput value={searchText} setValue={(value)=>setSearchText(value)} placeholder={'Search Tids'} label={'Search Tids'} />
            </div>
            <div className="flex items-center">
                <button className='rounded-full' onClick={()=>setShowCreate({status:true,edit:false})}><PiPlusBold fontSize={40} lightingColor={'#fff'} color='#182159' className='ml-2'/></button>
            </div>
            </div>
            </>
    }

    const onSortPress = (type)=>
    {
        if (sort === type) {
            if(sort === `-${type}`) setSort(type)
            else setSort(`-${type}`);
          } else {
            setSort(type);
          }
    }

    async function getWarehouses(){

        try 
        {
            let query = `&limit=${LIMIT}&offset=${offset}&orderBy=${sort}`;
                if(searchText) query+= `&search=${searchText}`;
            let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/warehouse?populate=true${query}`)
            if(res.data)
            {
                setWarehouses(res.data)
                setCount(res.data.count)
            }
        } catch (error) {
            toast('Error getting samples')
        }
    }

    async function getSamples(){

        try 
        {
            let query = `&limit=${LIMIT}&offset=${offset}&orderBy=${sort}`;
            query += `&rackId=${activeRack}`;
            let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/sample?populate=true${query}`)
            if(res.data)
            {
                setSamples(res.data.samples)
                setCount(res.data.totalCount)
            }
        } catch (error) {
            toast('Error getting samples')
        }
    }

    useEffect(()=>
    {
        getWarehouses();
        getSamples()
        getRacks();
    },[])
   
    async function getRacks(){

        try 
        {
                let query ='';
                if(activeWarehouse) query += `&warehouseId=${activeWarehouse}`;
            let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/rack?populate=true${query}`)
            if(res.data)
            {
                setRacks(res.data)
            }
        } catch (error) {
            toast('Error getting rack items')
        }
    }
    useEffect(()=>
    {
        getRacks();
    },[activeWarehouse])

    useEffect(()=>
    {
        getSamples();
    },[activeRack,activeWarehouse])


    const onSubmit = async(e,data,yearCode)=>
    {
        try 
        {
            e.preventDefault()
            let res;
            if(showCreate.edit)
            {
                res = await authAxios.put(`${process.env.REACT_APP_BASE_URL}/rack/${showCreate.edit}`,data) 
                InfoToast('Rack Updated')
                
            }
            else
            {
                res = await authAxios.post(`${process.env.REACT_APP_BASE_URL}/rack`,{...data})
                InfoToast('Rack Created')
            }
            if(res.data)
            {
                await getWarehouses()
                setShowCreate({status:false,edit:null})
            }
            else toast('error updating retailer')
        } catch (error) {
            // console.log(error.response.data.error[0])
            ErrorToast(error.response.data.error[Object.keys(error.response.data.error)[0]])
        }
    }


  return (
    <>
        {/* <Header title={'Sample Management'} RightContent={RightContent}/> */}
        <div className='grid grid-cols-12 h-screen w-full items-stretch'>
            <div className='col-span-3 border-r border-r-gray-200 py-6 overflow-scroll'>
                <p className='text-xs font-semibold text-[#959595] tracking-normal uppercase'>Warehouses</p>
                <div className='mt-6 pr-5'>
                    <div onClick={()=>setActiveWarehouse('')} className={`py-4 mb-4 border  cursor-pointer rounded-md transition-all overflow-hidden p-2 ${activeWarehouse === '' ? 'bg-[#1821591c] border-[#182159]' : 'bg-[#f3f3f3] border-transparent'}`}>
                        <div className='text-center'>
                            <p className='text-xs font-semibold uppercase text-[#959595]'>All</p>
                        </div>
                    </div>

                    {
                        warehouses.map((item,index)=>{
                            return <WarehouseItem activeWarehouse={activeWarehouse} setActiveWarehouse={(id)=>setActiveWarehouse(id)} item={item} key={index}/>
                        })
                    }
                </div>
            </div>
            <div className='col-span-4 border-r border-r-gray-200 py-6 px-6 overflow-scroll'>
                <div className='flex justify-between items-center'>
                    <p className='text-xs font-semibold text-[#959595] tracking-normal uppercase'>Racks</p>
                    <PiPlusCircle fontSize={24} className='text-black cursor-pointer hover:rotate-90 transition-all' onClick={()=>setShowCreate({status:true,edit:false,type:'rack'})}/>
                </div>

                <div className='mt-6'>
                    {
                        racks.map((item,index)=>{
                            return <RackItem activeRack={activeRack} setActiveRack={setActiveRack} item={item} key={index}/>
                        })
                    }
                </div>

            </div>
            <div className='col-span-5 border-r border-r-gray-200 py-6 px-6 overflow-scroll'>
                <div className='flex justify-between items-center'>
                    <p className='text-xs font-semibold text-[#959595] tracking-normal uppercase'>Samples</p>
                </div>

                <div className='mt-6 pr-5'>
                    {
                        samples.map((item,index)=>{
                            return <SampleItem item={item} key={index}/>
                        })
                    }
                </div>

            </div>
        </div>
        { showCreate.status === true && showCreate.type === 'rack' ? <ManageRack activeWarehouse={activeWarehouse} onClose={setShowCreate} onSubmit={onSubmit} edit={showCreate.edit}/> : null}
    </>
  )
}


const WarehouseItem = ({item,activeWarehouse,setActiveWarehouse})=>
{
    return <div onClick={()=>setActiveWarehouse(item.id)} className={`mb-4 border  cursor-pointer rounded-md transition-all overflow-hidden p-2 ${activeWarehouse === item.id ? 'bg-[#1821591c] border-[#182159]' : 'bg-[#f3f3f3] border-transparent'}`}>
        <div className='text-center mb-2'>
            <p className='text-xs font-semibold uppercase text-[#959595]'>{item.name}</p>
            <p className='text-[10px] uppercase text-[#959595]'>{item.refrigerated ? 'Refrigerated' : 'Not Refrigerated'}</p>
        </div>
        <div className='grid grid-cols-8'>
            {
                item.racks?.map((item,index)=>
                {
                    return <div key={index} className='w-full h-[28px] bg-[#ff8b51] rounded-[5px] flex items-center justify-center'><p className='text-xs text-[#fff]'>{item.name}</p></div>
                })
            }
        </div>
    </div>
}

const RackItem = ({item,activeRack,setActiveRack})=>
{
    const [rowCount, setRowCount] = useState(item.rackTypeInfo.rows);
    const [columnCount, setColumnCount] = useState(item.rackTypeInfo.columns);
    const [position,setPosition] = useState('')
    useEffect(()=>
    {
        setRowCount(item.rackTypeInfo.rows)
        setColumnCount(item.rackTypeInfo.columns)
    },[])

    const joinPositions = (items)=>
    {
        let itemNames = [];
        items.forEach(item => {
            const itemName = `${item.rack.name}${item.row} C${item.column}`;
            itemNames.push(itemName);
        });
        return itemNames.join(', ');
    }

    return <div onClick={()=>setActiveRack(item.id)} className={`bg-[#fafafa] rounded-md border overflow-hidden p-6 pt-4 ${activeRack === item.id ? 'bg-[#1821591c] border-[#182159]' : 'bg-[#f3f3f3] border-transparent'}`}>
        <div className='text-center mb-2'>
            <p className='text-xs font-semibold uppercase text-[#959595]'>{item?.name}</p>
        </div>
        <div className={`grid gap-1 grid-cols-${columnCount} grid-rows-${rowCount} relative`}>
            {
                item?.rackitems?.map((item,index)=>
                {
                    return <div key={index} className='px-2  text-center flex justify-center items-center w-full text-[9px] h-[20px] py-4 bg-[#542d9e] text-white rounded-md'>{item.sampleId ? item?.sample.sampleId : '-'}</div>
                })
            }
        </div>
    </div>
}


const SampleItem = ({item})=>
{
    // const [rowCount, setRowCount] = useState(item.rackType.rows);
    // const [columnCount, setColumnCount] = useState(item.rackType.columns);
    const [position,setPosition] = useState('')
    // useEffect(()=>
    // {
    //     setRowCount(item.rackType.rows)
    //     setColumnCount(item.rackType.columns)
    // },[])

    const joinPositions = (items)=>
    {
        let itemNames = [];
        items.forEach(item => {
            const itemName = `${item.rack.name}${item.row} P${item.column}`;
            itemNames.push(itemName);
        });
        return itemNames.join(', ');
    }

    return <div className='bg-[#f3f3f3] border border-[#e3e3e3] rounded-md overflow-hidden p-4 mb-4'>
        <div className=' flex justify-between items-center'>
            <div className="block justify-between items-center">
            <p className='text-xs font-semibold uppercase text-[#959595]'>{item?.tidsId ? item?.tids?.testItemName : item.referenceName}</p>
            <p className='text-xs font-medium text-[#151515]'>Qty:{item.quantity}</p>
            </div>
            <div>
            <p className='text-xs font-medium text-[#151515] text-right'>#{item.sampleId}</p>
            <p className='text-xs font-medium text-[#151515] text-right'>{item?.rackitems?.length > 0 ? joinPositions(item.rackitems) : 'Not Alloted'}</p>
            </div>
        </div>
    </div>
}
