import { useEffect, useState } from "react"
import Input from "../../../components/Input"
import Map from "../../../components/Map"
import Popup from "../../../components/Popup"
import Select from "../../../components/Select"
import { authAxios } from "../../../helpers/axios"
import ImageInput from "../../../components/ImageInput"
import CropPopup from "../../../components/CropPopup"
import { RETAILER_IMAGE_BASE_URL } from "../../../helpers/constants"
import { dataURItoBlob } from "../../../helpers/utils"

export default function ManageProject({onClose,edit=false,onSubmit,updateData})
{
    const [manageData,setManageData] = useState({approvedOn:''})
    const [sponsors,setSponsors] = useState([])
    const [hsn,setHsn] = useState([])
    const [showCrop,setShowCrop] = useState(false)
    const formName = 'editRetailer';
    const [image,setImage] = useState(null)
    const [croppedImage,setCroppedImage] = useState()
    const [tests,setTests] = useState([])
    const [selectedTests,setSelectedTests] = useState([''])

    
        useEffect(()=>
        {
            async function getSponsors(){
                let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/sponsor`)
                setSponsors(res.data)
            }
            async function getTests(){
                let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/test`)
                setTests(res.data)
            }
            // async function getHsn(){
            //     let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/hsn`)
            //     setHsn(res.data.data)
            // }
            async function getTidsInfo(){
                let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/tids/${edit}`)
                // setSelectedTests()
                setManageData({...res.data})
            }
            getTidsInfo()
            getSponsors()
            getTests()
        },[])



        const handleTestSelect = (index, test) => {
            const updatedTests = [...selectedTests];
            updatedTests[index] = test;
            setSelectedTests(updatedTests);
          };

          const getSelectedTestIds = () => {
            console.log('selectedTest',selectedTests)
            return selectedTests.map(test => test).join(',');
          };

        const renderTestSelect = (index) => {
            const selectedTest = selectedTests[index];
            const options = tests.filter(test => !selectedTests.includes(test));

            return <Select placeholder={'Select Test'} required={true} customLabel={'name'} options={options} customValue={'id'} value={selectedTest || ''} setValue={(value)=>handleTestSelect(index,value)}/>
        };




    return <><Popup size="xl" title={edit ? 'Update Tids' : 'Create Tids'} submitTitle={edit ? 'Approve' : 'Approve'} onClose={onClose} formName={formName}>
        <form onSubmit={(e)=>onSubmit(e,{...manageData,tests:getSelectedTestIds()})} id={formName} className='grid grid-cols-2 gap-x-3 w-full'>
            <div>
                <p></p>
            </div>
            

        </form>
    </Popup>
    {showCrop ? <CropPopup image={image} setActive={setShowCrop} setImage={setCroppedImage}/>: null}
    </>
}
