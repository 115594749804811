import axios from 'axios';


const authAxios = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    'User-Agent': 'Custom User Agent' // Set a custom User-Agent header
  }
});

// Function to set the token to the Axios authAxios headers
export const setAuthToken = (token) => {
  if (token) {
    // Apply token to every request header
    authAxios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  } else {
    // Delete the token from the headers if no token is provided
    delete authAxios.defaults.headers.common['Authorization'];
  }
};

// export default authAxios;

// // const authAxios = axios.create({
// // baseURL:process.env.REACT_APP_BASE_URL
// // })
// import axios from 'axios';

// // Function to set token to axios authAxios
// const setAuthToken = async () => {
//   const user = auth.currentUser;
//   if (user) {
//     try {
//       const token = await user.getIdToken();
//       authAxios.defaults.headers.Authorization = `${token}`;
//     } catch (error) {
//       console.error('Error getting Firebase token:', error);
//     }
//   } else {
//     // If no user is authenticated, remove the Authorization header
//     delete authAxios.defaults.headers.Authorization;
//   }
// };

// // Set token on every request
// // authAxios.interceptors.request.use(async (config) => {
// //   await setAuthToken();
// //   return config;
// // });

// // Call setAuthToken once on initialization
// // setAuthToken();

// // Using onAuthStateChanged to listen for changes in authentication state
// // onAuthStateChanged(auth, async (user) => {
// //   await setAuthToken(user);
// // });

// export default authAxios;



// const publicAxios = axios.create({
// baseURL:process.env.REACT_APP_BASE_URL
// })

export {authAxios}