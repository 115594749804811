import { useEffect, useState } from "react"
import Input from "../../../components/Input"
import Map from "../../../components/Map"
import Popup from "../../../components/Popup"
import Select from "../../../components/Select"
import { authAxios } from "../../../helpers/axios"
import ImageInput from "../../../components/ImageInput"
import CropPopup from "../../../components/CropPopup"
import { RETAILER_IMAGE_BASE_URL, STERILITY_TYPE_NON_STERILE, STERILITY_TYPE_STERILE } from "../../../helpers/constants"
import { dataURItoBlob } from "../../../helpers/utils"

export default function ManageSample({onClose,edit=false,onSubmit,updateData})
{
    const [manageData,setManageData] = useState({productSku:'',productName:'',productReward:null,initialStock:12,productIsActive:1,productCategory:'',productImage:'',retailerApproved:true,productTax:'',productHsn:'',productUnit:'9e30f6fe-b861-698d-79f8-fcd6ce6c70a3'})
    const [sponsors,setSponsors] = useState([])
    const [hsn,setHsn] = useState([])
    const [showCrop,setShowCrop] = useState(false)
    const formName = 'editRetailer';
    const [image,setImage] = useState(null)
    const [croppedImage,setCroppedImage] = useState()
    const [tests,setTests] = useState([])
    const [selectedTests,setSelectedTests] = useState([''])

    
        useEffect(()=>
        {
            async function getSponsors(){
                let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/sponsor`)
                setSponsors(res.data)
            }
            async function getTests(){
                let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/test`)
                setTests(res.data)
            }
            // async function getHsn(){
            //     let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/hsn`)
            //     setHsn(res.data.data)
            // }
            async function getTidsInfo(){
                let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/tids/${edit}`)
                // setSelectedTests()
                setManageData({...res.data})
            }
            getTidsInfo()
            getSponsors()
            getTests()
        },[])



        const handleTestSelect = (index, test) => {
            const updatedTests = [...selectedTests];
            updatedTests[index] = test;
            setSelectedTests(updatedTests);
          };

          const getSelectedTestIds = () => {
            console.log('selectedTest',selectedTests)
            return selectedTests.map(test => test).join(',');
          };

        const renderTestSelect = (index) => {
            const selectedTest = selectedTests[index];
            const options = tests.filter(test => !selectedTests.includes(test));

            return <Select placeholder={'Select Test'} required={true} customLabel={'name'} options={options} customValue={'id'} value={selectedTest || ''} setValue={(value)=>handleTestSelect(index,value)}/>
        };




    return <><Popup size="xl" title={edit ? 'Update Tids' : 'Create Tids'} submitTitle={edit ? 'Update' : 'Create'} onClose={onClose} formName={formName}>
        <form onSubmit={(e)=>onSubmit(e,{...manageData,tests:getSelectedTestIds()})} id={formName} className='grid grid-cols-5 gap-x-3 w-full'>
            <div className="grid grid-cols-2 col-span-3 gap-x-3 gap-y-0 w-full">
            {/* <div className="col-span-2">
                <ImageInput image={manageData.productImage && manageData.productImage!=='' ? `${manageData.productImage}` : image} setImage={onImageChange} />
            </div> */}
            <div>
                <label>Sponsor Name*</label>
                <Select placeholder={'Select Sponsor'} required={true} customLabel={'name'} options={sponsors} customValue={'id'} value={manageData.sponsorId} setValue={(value)=>setManageData(data=>({...data,sponsorId:value}))}/>
            </div>
            <div>
                <label>Test Item Name</label>
                <Input type='text' placeholder={'Test Item Name'} required={true} value={manageData.testItemName} setValue={(value)=>setManageData(data=>({...data,testItemName:value}))}/>
            </div>
            <div className="flex">
                <div className="w-full">
                    <label>Sterility Type</label>
                    <Select placeholder={'Select Sterility Type'} required={true} customLabel={'name'} options={[{name:'Non-Sterile',id:STERILITY_TYPE_NON_STERILE},{name:'Sterile',id:STERILITY_TYPE_STERILE}]} customValue={'id'} value={manageData.sterilityType} setValue={(value)=>setManageData(data=>({...data,sterilityType:value}))}/>
                </div>
                {
                    manageData.sterilityType === STERILITY_TYPE_STERILE ? <div className="ml-2 w-[350px]">
                    <label>Sterile Type</label>
                    <Input type='text' placeholder={'Sterile Type'} required={true} value={manageData.sterileName} setValue={(value)=>setManageData(data=>({...data,sterileName:value}))}/>
                </div> : null
                }
            </div>
            <div>
                <label>Acceptable to Cut</label>
                <Select placeholder={'Select Acceptable to Cut'} required={true} options={[{name:'Yes',value:true},{name:'No',value:false}]} value={manageData.acceptableToCut} setValue={(value)=>setManageData(data=>({...data,acceptableToCut:value}))}/>
            </div>
            <div>
                <label>Lot Number</label>
                <Input type='text' placeholder={'Lot Number'} required={true} value={manageData.lotNumber} setValue={(value)=>setManageData(data=>({...data,lotNumber:value}))}/>
            </div>
            <div>
                <label>Serial Number</label>
                <Input type='text' placeholder={'Serial Number'} required={true} value={manageData.serialNumber} setValue={(value)=>setManageData(data=>({...data,serialNumber:value}))}/>
            </div>
            <div className="col-span-1 mb-2">
                <label>Appearance</label>
                <textarea className="resize-none" placeholder={'Enter Appearance'} required={true} value={manageData.appearance} onChange={(e)=>setManageData(data=>({...data,appearance:e.target.value}))}/>
            </div>
            <div className="col-span-1 mb-2">
                <label>Description</label>
                <textarea className="resize-none" placeholder={'Enter Description'} required={true} value={manageData.description} onChange={(e)=>setManageData(data=>({...data,description:e.target.value}))}/>
            </div>
            <div>
                <label>Temperature</label>
                <Input type='number' placeholder={'Enter temperature'} required={true} value={manageData.temperature} setValue={(value)=>setManageData(data=>({...data,temperature:value}))}/>
            </div>
            <div>
                <label>pH</label>
                <Input type='number' min={1} max={10} placeholder={'Enter pH'} required={true} value={manageData.ph} setValue={(value)=>setManageData(data=>({...data,ph:value}))}/>
            </div>
            <div>
                <label>Sample Storage Condition</label>
                <Input type='text' placeholder={'Enter Sample Storage Condition'} required={true} value={manageData.sampleStorageCondition} setValue={(value)=>setManageData(data=>({...data,sampleStorageCondition:value}))}/>
            </div>
            <div>
                <label>GLR Storage Condition</label>
                <Input type='text' placeholder={'Enter GLR Storage Condition'} required={true} value={manageData.glrStorageCondition} setValue={(value)=>setManageData(data=>({...data,glrStorageCondition:value}))}/>
            </div>
            <div>
                <label>Extraction Ratio</label>
                <Input type='text' placeholder={'Enter Extraction Ratio'} required={true} value={manageData.extractionRatio} setValue={(value)=>setManageData(data=>({...data,extractionRatio:value}))}/>
            </div>
            <div>
                <label>Mfd. Date</label>
                <Input type='date' placeholder={'Select Mfd. Date'} required={true} value={manageData.mfdDate} setValue={(value)=>setManageData(data=>({...data,mfdDate:value}))}/>
            </div>
            <div>
                <label>Expiry Date</label>
                <Input type='date' placeholder={'Select Expiry Date'} required={true} value={manageData.expiryDate} setValue={(value)=>setManageData(data=>({...data,expiryDate:value}))}/>
            </div>
            </div>
            <div className="pl-4 col-span-2">
                <label>Select Study Tests</label>
                {selectedTests.map((selectedTest, index) => (
                    <div key={index}>
                    {renderTestSelect(index)}
                    </div>
                ))}
                {selectedTests.length >= tests.length ? null : <button type="button" className="btn-xs" onClick={() => setSelectedTests([...selectedTests, ''])}>Add Test</button>}
            </div>
        </form>
    </Popup>
    {showCrop ? <CropPopup image={image} setActive={setShowCrop} setImage={setCroppedImage}/>: null}
    </>
}
