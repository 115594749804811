import React, { useEffect, useState } from 'react'
import Header from '../../components/Header'
import SearchInput from '../../components/SearchInput'
import { authAxios } from '../../helpers/axios'
import { toast } from 'react-toastify'
import FilterSelect from '../../components/FilterSelect'
import { useNavigate } from 'react-router-dom'
import { base64ToBlob, dataURItoBlob, getDateFormat } from '../../helpers/utils'
import { ErrorToast, InfoToast } from '../../helpers/toasters'
import SortIcon from '../../images/sort.svg'
import RightIcon from '../../images/right.svg'
import FilterIcon from '../../images/filter.svg'
import RupeeIcon from '../../images/rupee.svg'
import { LIMIT, PROJECT_STATUS_SAMPLE_REQUESTED, PROJECT_STATUS_SD_APPROVAL, PROJECT_STATUS_TECH_INFO, STUDY_STATUS_APPROVED, STUDY_STATUS_SAMPLE_REQUESTED, STUDY_STATUS_SD_APPROVAL_REQUESTED, STUDY_STATUS_TECH_INFO_REQUESTED } from '../../helpers/constants'
import { IoDocumentTextOutline } from 'react-icons/io5'
import { TbSquareRoundedPlusFilled } from 'react-icons/tb'
// import ManageProject from './components/ManageProject'
import ReactDataGrid from 'react-data-grid';
import { useDispatch, useSelector } from 'react-redux'
import Pagination from '../../components/Pagination'
import { FiFilter } from 'react-icons/fi'
import { updateLastNotification, updateNotificationCheck } from '../../redux/slice/authSlice'

export default function Notification() {

    const [searchText,setSearchText] = useState('')
    const [notifications,setNotifications] = useState([])
    const navigation = useNavigate()
    const [showCreate,setShowCreate] = useState({status:false,edit:null})
    const [showFilters,setShowFilters] = useState(false)

    const [cityFilters,setCityFilters] = useState([])
    const [routeFilters,setRouteFilters] = useState([])
    const [sort,setSort] = useState('-createdAt');
    const authInfo = useSelector(state=>state.auth)
    const [offset,setOffset] = useState(0);
    const [count,setCount] = useState(0)
    const dispatch = useDispatch()

    const columns = [
        // { key: 'id', name: 'Sample ID', frozen: true, width: 150},
        { key: 'studyNumber', name: 'Study Number' ,frozen:true,width: 150,renderCell:({row})=><p >{row.consultantId && row.consultant.useOwnNumber ? row.consultant.uniqueNumber : row.sponsor.uniqueNumber}/{row.studyNumber}</p>},
        { key: 'studyName', name: 'Study Name' ,frozen:true,width: 250,renderCell:({row})=><p >{row.test.name}</p>},
        { key: 'testItemName', name: 'Test Item' ,width: 200,renderCell:({row})=><p >{row.project.tids.testItemName}</p>},
        { key: 'status', name: 'Status',width:200,renderCell:({row})=><p className={`text-xs ${row.status === STUDY_STATUS_APPROVED ? 'bg-green-300' : row.status === STUDY_STATUS_SD_APPROVAL_REQUESTED ? 'bg-orange-200' : 'bg-gray-300'} rounded-full px-3 py-1 inline-block text-center mx-auto`}>{row.status === STUDY_STATUS_APPROVED ? 'Approved' : row.status === STUDY_STATUS_TECH_INFO_REQUESTED ? 'Awaiting RT Check' : row.status === STUDY_STATUS_SAMPLE_REQUESTED ? 'Awaiting Sample Info' : row.status === STUDY_STATUS_TECH_INFO_REQUESTED ? 'Awaiting RT Info' : 'Awaiting SD Approval'}</p>},
        { key: 'qaApproved', name: 'QA Review',width:200,renderCell:({row})=><p className={`text-xs ${row.qaVerified ? 'bg-green-300' : 'bg-gray-300'} rounded-full px-3 py-1 inline-block text-center mx-auto`}>{row.qaVerified ? 'QA Reviewed' : 'Awaiting QA Review'}</p>},
        { key: 'qaAssigned', name: 'QA Assigned',width:200,renderCell:({row})=><p>{row.qaAssigned ? row.qa.name : '-'}</p>},
        { key: 'sponsor', name: 'Sponsor' ,width: 200,renderCell:({row})=><p>{row.sponsor.name}</p>},
        { key: 'sdAssigned', name: 'Study Director',width:150,renderCell:({row})=><p>{row.sdAssigned ? row.sd.name : '-'}</p>},
        { key: 'consultant', name: 'Consultant' ,width: 250,renderCell:({row})=><p>{row.consultant ? row.consultant.name : ''}</p>},
        { key: 'createdAt', name: 'Created On',width:200,renderCell:({row})=><p>{getDateFormat(row.createdAt)}</p>},
      ];
    

    const [selectedFilters,setSelectedFilters] = useState({city:'',route:''})
    const RightContent = ()=>
    {
        return <>
        <div className='flex justify-end'>
            </div>
            </>
    }

    const onSortPress = (type)=>
    {
        if (sort === type) {
            if(sort === `-${type}`) setSort(type)
            else setSort(`-${type}`);
          } else {
            setSort(type);
          }
    }

    async function getProjects(){

        try 
        {
            let query = `&limit=${LIMIT}&offset=${offset}&orderBy=${sort}`;
                if(searchText) query+= `&search=${searchText}`;
                if(authInfo.role === 'sd') query+=`&sdId=${authInfo.id}&mode=live`
                if(authInfo.role === 'qa') query+=`&qaId=${authInfo.id}&verified=false&mode=live`
                if(authInfo.role === 'tico' || authInfo.role === 'tfm') query+=`&mode=all`
            let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/notification/my-notifications?populate=true${query}`)
            if(res.data)
            {
                setNotifications(res.data)
                // dispatch(updateLastNotification({lastNotification:res.data[0].id}))
                if(res.data.length > 0) dispatch(updateNotificationCheck({notificationCheck:false,newId:res.data[0].id}));
                setCount(res.data.totalCount)
            }
        } catch (error) {
            toast('Error getting projects')
        }
    }
    useEffect(()=>
    {
        getProjects();
    },[sort,searchText,offset])


    const onSubmit = async(e,data)=>
    {
        try 
        {
            e.preventDefault()

            let res;
            if(showCreate.edit)
            {
                res = await authAxios.put(`${process.env.REACT_APP_BASE_URL}/tids/${showCreate.edit}`,data) 
                InfoToast('TIDS Updated')
                
            }
            else
            {
                res = await authAxios.post(`${process.env.REACT_APP_BASE_URL}/tids`,data)
                InfoToast('TIDS Created')
            }
            if(res.data)
            {
                await getProjects()
                setShowCreate({status:false,edit:null})
            }
            else toast('error updating tids')
        } catch (error) {
            // console.log(error.response.data.error[0])
            ErrorToast(error.response.data.error[Object.keys(error.response.data.error)[0]])
        }
    }


  return (
    <>
        <Header title={'Notifications'} RightContent={RightContent}/>
        <div className='flex flex-1 bg-white w-full overflow-scroll h-full'>
        {/* <ReactDataGrid
        rowHeight={40}
        rows={projects}
        className='w-full h-full'
      columns={columns}
      enableCellSelect={false}
    /> */}
    <div className='flex-col w-full items-start'>
        {
            notifications.map((item,index)=>
            {
                return <NotificationItem item={item} key={index}/>
            })
        }
    </div>
        </div>
        {/* { showCreate.status ? <ManageProject onClose={setShowCreate} onSubmit={onSubmit} edit={showCreate.edit}/> : null} */}
    </>
  )
}



const NotificationItem = ({item})=>
{
    const navigate = useNavigate()
    const onClick = ()=>{
        if(item.resourceType === 'samplerequest') navigate(`/sample-requests`)
        else if(item.resourceType === 'project') navigate(`/project/${item.resourceId}`)
        else if(item.resourceType === 'study') navigate(`/study/${item.resourceId}`)
    }
    return <div className='grid w-full items-center grid-cols-12 mb-4 border bg-[#f9f9f9] py-3 px-6 border-gray-100 rounded-md' onClick={onClick}>
        <div className='col-span-6'>
            <p className='text-xs'>{item.description}</p>
        </div>
        <div className='col-span-3'>
            <p className='text-xs text-[#000]'>{getDateFormat(item.createdAt)}</p>
        </div>
        <div className='col-span-3'>
            <p className='text-xs text-[#000]'>{item.read ? 'Read' : 'Not Read'}</p>
        </div>
    </div>
}