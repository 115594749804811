import React, { useEffect, useState } from 'react'
import Header from '../../components/Header'
import { authAxios } from '../../helpers/axios'
import Popup from '../../components/Popup'
import Input from '../../components/Input'
import EditImage from '../../images/edit.svg'
import { toast } from 'react-toastify'
import Select from '../../components/Select'
import { ErrorToast, InfoToast } from '../../helpers/toasters'
import TabGroup from '../../components/TabGroup'
import Routes from './components/Routes'
import Categories from './components/Categories'
import Cities from './components/Cities'
import Hsns from './components/Hsns'
import Warehouse from './components/Warehouse'
import Test from './components/Test'
import RackTypes from './components/RackTypes'

export default function Settings() 
{
    const [showManage,setShowManage] = useState({type:null,status:false,edit:null})
    const [activeTab,setActiveTab] = useState('test')

    
    const RightContent = ()=>
    {
        return <div>
          <button type='button' className='btn-md' onClick={()=>setShowManage({type:activeTab,status:true})}>Add {activeTab === 'test' ? 'Test' : activeTab === 'warehouse' ? 'Warehouse' : 'Rack Type'}</button>
        </div>
    }

  return (
    <>
    <Header title={'Settings'} RightContent={()=><div></div>} parentTitle='Settings' parentLink='sales-representatives'/>
    <div className='flex justify-between items-center border-b border-[#e3e3e3] bg-white'>
        <div className='my-4'>
           <TabGroup setActiveTab={setActiveTab} activeTab={activeTab} options={[{label:'Tests',value:'test'},{label:'Warehouses',value:'warehouses'},{label:'Rack Types',value:'rack-types'}]}/>
        </div>
        <div>
          <RightContent/>
        </div>
    </div>
    <div className='bg-white w-full flex-1 flex overflow-scroll'>
        {activeTab === 'test' ? <Test setShowManage={setShowManage} showManage={showManage}/> : activeTab === 'warehouses' ? <Warehouse setShowManage={setShowManage} showManage={showManage}/> : activeTab === 'rack-types'  ? <RackTypes setShowManage={setShowManage} showManage={showManage}/> : <Categories setShowManage={setShowManage} showManage={showManage}/>}
    </div>
</>
  )
}








