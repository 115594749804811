import { useCallback, useEffect, useState } from "react"
import Input from "../../../../components/Input"
import Select from "../../../../components/Select"
import { authAxios } from "../../../../helpers/axios"
import { PROJECT_STATUS_SAMPLE_REQUESTED, RETAILER_IMAGE_BASE_URL, STERILITY_TYPE_NON_STERILE, STERILITY_TYPE_STERILE } from "../../../../helpers/constants"
import SlidePopup from "../../../../components/SlidePopup"
import PDFViewer from "../../../../components/PDFViewer"
import Loader from "../../../../components/Loader"
import { getDateFormat } from "../../../../helpers/utils"


export default function ManageProjectRequest({onClose,edit=false,onSubmit,updateData})
{
    const [manageData,setManageData] = useState({testItemImage:'',sponsorId:'',appearanceConfirmation:'',testingPart:'',dimensions:'',studies:[]})
    const [sponsors,setSponsors] = useState([])
    const [samples,setSamples] = useState([])
    const formName = 'editRetailer';
    const [image,setImage] = useState(null)
    const [projectInfo,setProjectInfo] = useState({})
    const [croppedImage,setCroppedImage] = useState()
    const [sds,setSds] = useState([])
    const [tests,setTests] = useState([])
    const [showTest,setShowTest] = useState(false)
    const [loading,setLoading] = useState(true)
    const [studies,setStudies] = useState([])

    const [file,setFile] = useState(null);

    
        useEffect(()=>
        {
            async function getTests(){
                let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/test`)
                setTests(res.data)
            }
            // getTidsInfo()
            // getSponsors()
            // getSamples()
            // getSds()
            getTests()
        },[])
       
        useEffect(()=>
        {
            async function getProjectInfo(){
                console.log('updateData',updateData)
                let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/project/${updateData.project.id}`)
                setFile(`${process.env.REACT_APP_STATIC_URL}/${res.data.project.tids.tidsDoc}`)
                setLoading(false)
                setProjectInfo(res.data)
                let newStudies = res.data.studies.map(item => ({
                    id: item.id,
                    testName: item.test.name,
                    sampleRequired: "", // Add empty string for sampleRequired field
                    extractionCondition: "" // Add empty string for extractionRatio field
                }));
                setManageData(prev=>({...prev,studies:newStudies}))
            }
            getProjectInfo()
        },[])
        
        const handleFileChange = (event) => {
            setManageData(prev=>({...prev,file:event.target.files[0]}));
        };




    return  <><SlidePopup size="xl" title={edit ? 'Complete Tech Info Request' : 'Complete Tech Info Request'} submitTitle={edit ? 'Update' : 'Create'} onClose={onClose} formName={formName}>
        { !loading ? <form onSubmit={(e)=>onSubmit(e,{...manageData})} id={formName} className='grid items-start grid-cols-4 gap-x-3 w-full'>
            <div className="flex h-full col-span-2 w-full overflow-y-scroll bg-[#f3f3f3] rounded-md p-2">
                <PDFViewer file={file}/>
            </div>
            <div className="grid grid-cols-2 items-start col-span-2 gap-x-3 gap-y-0 px-4 w-full">

            <div className="mb-4">
                <label>Sponsor Name*</label>
                <p className="text-xs">{projectInfo.project.sponsor.name}</p>
            </div>
            <div className="mb-4">
                <label>Test Item Name</label>
                <p className="text-xs">{projectInfo.project.tids.testItemName}</p>
            </div>
            <div className="col-span-2 mb-2">
                <label>Cofirmation of Appearance</label>
                <textarea className="resize-none" placeholder={'Appearance Information'} required={true} value={manageData.appearanceConfirmation} onChange={(e)=>setManageData(data=>({...data,appearanceConfirmation:e.target.value}))}/>
            </div>
            <div className="col-span-2 mb-2">
                <label>Description</label>
                <textarea className="resize-none" placeholder={'Enter Description'} required={true} value={manageData.description} onChange={(e)=>setManageData(data=>({...data,description:e.target.value}))}/>
            </div>
            {/* <div>
                <label>Extraction Ratio</label>
                <Input type='text' placeholder={'Enter Extraction Ratio'} required={true} value={manageData.extractionRatio} setValue={(value)=>setManageData(data=>({...data,extractionRatio:value}))}/>
            </div> */}
            <div>
                <label>Testing Part</label>
                <Input type='text' placeholder={'Enter Testing Part'} required={true} value={manageData.testingPart} setValue={(value)=>setManageData(data=>({...data,testingPart:value}))}/>
            </div>
            <div>
                <label>Dimensions</label>
                <Input type='text' placeholder={'Enter Dimensions'} required={true} value={manageData.dimensions} setValue={(value)=>setManageData(data=>({...data,dimensions:value}))}/>
            </div>
            <div className="max-w-[920px] col-span-2">
                <label>Studies</label>
                {manageData.studies.map((selectedTest, index) => (
                    <StudyItem index={index} item={selectedTest} tests={tests} data={manageData} setManageData={setManageData}/>
                ))}
            </div>

            </div>
        </form> : <Loader/> }
    </SlidePopup> 
    </> 
}



const StudyItem = ({item,sds,setManageData,tests,index,data})=>
{

    const updateValue = (type,newValue)=>
    {
        setManageData(prevState => {
            // Create a copy of the previous state array
            const newArray = [...prevState.studies];
        
            // Find the object at the specified index
            const updatedObject = { ...newArray[index] };
        
            // Update the nested field of the object
            if(type==='extractionCondition') updatedObject.extractionCondition = newValue;
            else updatedObject.sampleRequired = newValue;
        
            // Update the object in the array
            newArray[index] = updatedObject;
        
            // Return the updated state
            return { ...prevState,studies: newArray };
          });
    }
    return <div className="grid grid-cols-7 border border-gray-200 shadow-sm rounded-md my-4 bg-[#fcfcfc] w-full">
            <div className="col-span-3">
                <input type='text' disabled={true} className=" px-3 py-2 text-[13px] border-r border-r-gray-200 w-full bg-transparent" placeholder={'Test Name'} required={true} value={item.testName}/>
            </div>
            <div className="w-full col-span-2">
                <input type='text' className="px-3 py-2 border-r text-[13px] border-r-gray-200 bg-transparent  w-full" placeholder={'Extraction Condition'} required={true} defaultValue={item.extractionCondition} onChange={(e)=>updateValue('extractionCondition',e.target.value)}/>
            </div>
            <div className="w-full col-span-2">
                <input type='text' className="px-3 py-2 border-r text-[13px] border-r-gray-200 bg-transparent  w-full" placeholder={'Samples Required'} required={true} defaultValue={item.sampleRequired} onChange={(e)=>updateValue('sampleRequired',e.target.value)}/>
            </div>
    </div>
}