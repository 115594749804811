import React, { useEffect, useState } from 'react'
import Header from '../../components/Header'
import SearchInput from '../../components/SearchInput'
import { authAxios } from '../../helpers/axios'
import { toast } from 'react-toastify'
import FilterSelect from '../../components/FilterSelect'
import { useNavigate } from 'react-router-dom'
import ManageUser from './components/ManageUser'
import RepFilter from './components/RepFilter'
import FilterIcon from '../../images/filter.svg'
import FilterActiveIcon from '../../images/filteractive.svg'
import SortIcon from '../../images/sort.svg'
import RightIcon from '../../images/right.svg'
import { LIMIT } from '../../helpers/constants'
import { Tooltip } from 'react-tooltip'
import { PiUserPlus } from 'react-icons/pi'
import { ErrorToast, InfoToast } from '../../helpers/toasters'
import { getDateFormat } from '../../helpers/utils'
import ReactDataGrid from 'react-data-grid'

export default function Users() {

    const [searchText,setSearchText] = useState('')
    const [users,setUsers] = useState([])
    const [showManage,setShowManage] = useState({status:false,edit:null})

    const [cityFilters,setCityFilters] = useState([])
    const [routeFilters,setRouteFilters] = useState([])
    const navigate = useNavigate()

    const [offset,setOffset] = useState(0);
    const [count,setCount] = useState(5)
    const [sort,setSort] = useState('name')
    const [filters,setFilters] = useState({routeId:null,cityId:null,status:null,teamId:null})
    const [showFilters,setShowFilters] = useState(false)
    const [submitting,setSubmitting] = useState(false)

    const columns = [
        { key: 'empId', name: 'Employee ID', frozen: true, width: 150,renderCell:({row})=><p>#{row.empId}</p> },
        { key: 'name', name: 'Name' ,width: 250},
        { key: 'email', name: 'Email' ,width: 250,renderCell:({row})=><p>{row.email}</p>},
        { key: 'contactNumber', name: 'Contact Number' ,width: 200},
        { key: 'role', name: 'Role' ,width: 200,renderCell:({row})=><p><span className='uppercase'>{row.role}</span></p>},
        { key: 'lastActivity', name: 'Last Activity' ,width: 200,renderCell:({row})=><p>{getDateFormat(row.lastActivity)}</p>}
      ];

    const [selectedFilters,setSelectedFilters] = useState({city:'',route:''})
    const RightContent = ()=>
    {
        return <div className='flex'>
            <SearchInput value={searchText} setValue={(value)=>setSearchText(value)} placeholder={'Search User'} label={'Search User'} />
          <button type='button' className='ml-2 btn-md whitespace-nowrap'  onClick={()=>setShowManage({status:true,edit:null})}>Add User</button>
        </div>
    }

    const onFilterSubmit = (filters)=>
    {
        try 
        {
            setFilters({...filters})
            setCount(5)
            setOffset(0)
            setShowFilters(false)
        } catch (error) {
            toast('Order Filtered')
        }
    }

    const clearFilter = ()=>
    {
        try 
        {
            setFilters({routeId:null,cityId:null,status:null,distributorId:null,retailerId:null,teamId:null,salesRepId:null,startDate:null,endDate:null})
            setShowFilters(false)
        } catch (error) {
            toast('Order Filtered')
        }
    }

    const onSortPress = (type)=>
    {
        if (sort === type) {
            if(sort === `-${type}`) setSort(type)
            else setSort(`-${type}`);
          } else {
            setSort(type);
          }
    }

    async function getUsers(){

        try 
        {
            let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/user?populate=true`)
            setUsers(res.data)
        } catch (error) {
            toast('Error getting retailer')
        }
    }
    useEffect(()=>
    {
        async function getUsers(){

            try 
            {
                let query = `&limit=${LIMIT}&offset=${offset}&orderBy=${sort}`;
                // if(searchText) query+= `&search=${searchText}`;
                // if(filters.cityId) query+=`&cityId=${filters.cityId.id}`
                // if(filters.routeId) query+=`&routeId=${filters.routeId.id}`
                // if(filters.status) query+=`&status=${filters.status}`
                let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/user?populate=true${query}`)
                console.log('salse',res.data)
                if(res.data) 
                {
                    setUsers(res.data)
                    setCount(res.count)
                }
            } catch (error) {
                toast('Error getting sales representatives')
            }
        }
        getUsers();
    },[filters,searchText,sort,offset])


    const onSubmit = async(e,data)=>
    {
        try 
        {
            e.preventDefault()
            console.log(data)
            // let base64Image = await getBase64Image(data.retailerShopImage)
            setSubmitting(true)
            let res = await authAxios.post(`${process.env.REACT_APP_BASE_URL}/user`,{...data}) 
            await getUsers()
            InfoToast('User Added')
            setSubmitting(false)
            setShowManage({edit:null,status:false})
        } catch (error) {
            setSubmitting(false)
            console.log(error)
            ErrorToast(error.response.data.name)
        }
    }


  return (
    <>
        <Header title={'User Management'} RightContent={RightContent}/>
        {/* <div className='flex justify-between items-center py-2 border-[#e3e3e3] bg-white'>
            <div className='flex'>
                <SearchInput value={searchText} setValue={(value)=>setSearchText(value)} placeholder={'Search User'} label={'Search User'} />
                <div className='flex w-full border rounded-md py-2.5 items-center h-full justify-center text-center'>
                    <div className='px-2 hover:bg-[#f6f6f6] h-full cursor-pointer flex items-center flex-1 justify-center' onClick={()=>{if (offset > 0) {
                            setOffset(offset - LIMIT);
                        }}}>
                        <img src={RightIcon} className='w-[20px] h-[20px] rotate-180'/>
                    </div>
                    <p className='text-xs whitespace-nowrap w-[80px] justify-center'>{offset + 1}-{Math.min(offset + LIMIT, count)} of {count}</p>
                    <div className='px-2 hover:bg-[#f6f6f6] h-full cursor-pointer flex items-center flex-1 justify-center' onClick={()=>{if (offset + LIMIT < count) {
                            setOffset(offset + LIMIT);
                        }}}>
                        <img src={RightIcon} className='w-[20px] h-[20px]'/>
                    </div>
                </div>
            <div className='flex justify-end h-[100%] self-stretch items-center px-4 border rounded-sm border-[#e3e3e3] hover:bg-[#f6f6f6] cursor-pointer' onClick={()=>setShowFilters(true)}>
            {(filters.cityId || filters.routeId || filters.status) ? <img src={FilterActiveIcon} className={`w-[24px] h-[24px]`} alt='Filter' /> : <img src={FilterIcon} className={`w-[24px] h-[24px]`} alt='Filter' /> }
            </div>
            </div>
            <div>
                <button className='btn-xs' onClick={()=>setShowManage({status:true,edit:false})}>Create user <PiUserPlus fontSize={16} className='ml-2'/></button>
            </div>
        </div> */}
        <div className='bg-white w-full flex-1 flex overflow-scroll h-full'>
        <ReactDataGrid
        rowHeight={40}
        onCellClick={(args)=>navigate(`/users/${args.row.id}`)}
        rows={users}
        className='w-full h-full'
      columns={columns}
      enableCellSelect={false}
    />
        </div>
        {showManage.status  ? <ManageUser active={showManage.status} submitting={submitting} onClose={setShowManage} onSubmit={onSubmit} edit={showManage.edit}/> : null}
        { showFilters ? <RepFilter onSubmit={onFilterSubmit} filters={filters} onClose={setShowFilters} clearFilter={clearFilter}/> : null}
    </>
  )
}



const CityRouteInfo = ({ data ,id}) => {

    let routes = data.flatMap(item => item.routeIds.map(routeId => {
        const routeInfo = routeId.routeName; // Adjust this based on your route information
        return routeInfo;
    }));


    return (
      <div>
        <p className='text-sm font-regular my-0 flex items-start' >{data.length > 0 ? data[0].cityInfo.cityName : 'Not Available'}{data.length > 1 ? <span data-tooltip-id={`${id}-city`} className='ml-1 text-[9px] font-semibold h-[16px] w-[16px] flex justify-center items-center text-center bg-black text-white rounded-lg'>{data.length > 1 ? ` +${data.length - 1}` : ''}
        </span> : null}
        </p>
        <p className='text-[13px] text-gray-400 my-0' >{data.length > 0 ? data[0].routeIds[0].routeName : 'Not Available'}{routes.length > 1 ?<span data-tooltip-id={`${id}-route`} className='inline px-1 py-1 self-end ml-1 text-[9px] font-semibold w-[100%] h-[100%] !max-h-[16px] !max-w-[16px]  justify-center items-center text-center bg-black text-white rounded-full'>{routes.length > 1 ? `+${routes.length - 1}` : ''}</span> : null}</p>
  
          <Tooltip id={`${id}-city`} effect="solid" place="top" globalEventOff="click" clickable>
            {data.map((item, index) => (
              <p className='text-xs' key={index}>{item.cityInfo.cityName}</p>
            ))}
          </Tooltip>

          <Tooltip id={`${id}-route`} effect="solid" place="top" globalEventOff="click" clickable>
               { routes.map((item, index) => (
                    <p className='text-xs' key={index}>{item}</p>
                ))}
          </Tooltip>
      </div>
    );
  };
