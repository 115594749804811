import React, { useEffect, useState } from 'react'
import Header from '../../components/Header'
import { authAxios } from '../../helpers/axios'
import { toast } from 'react-toastify'
import Popup from '../../components/Popup'
import Input from '../../components/Input'
import { useNavigate, useParams } from 'react-router-dom'
import { ErrorToast, InfoToast } from '../../helpers/toasters'
import Loader from '../../components/Loader'
import ReactDataGrid from 'react-data-grid';
import { getDateFormat } from '../../helpers/utils'
import ManageProjectRequest from './components/ManageProjectRequest'

export default function ProjectRequestInfo() {

    const {id} = useParams();
    const [projectInfo,setProjectInfo] = useState([])
    const [showEdit,setShowEdit] = useState(false)
    const [orders,setOrders] = useState([])
    const [activeTab,setActiveTab] = useState('study')
    const [loading,setLoading] = useState(true)
    const [stocks,setStocks] = useState([])
    const [submitting,setSubmitting] = useState(false)
    const [showUpdate,setShowUpdate] = useState(false)
    const navigate = useNavigate()

    const RightContent = ()=>
    {
        return <div>
          <button type='button' disabled={submitting} className='btn-md' onClick={()=>setShowUpdate(true)}>{!submitting ? 'Update Request' : 'Updating Request...'}</button>
        </div>
    }

    async function getProjectInfo(){

        try 
        {
            let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/project/${id}?populate=true`)
            if(res.data) setProjectInfo(res.data)
            setLoading(false)
        // let Ordersres = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/orders?distributorId=${id}&populate=true`)
        // if(Ordersres.data.data) setOrders(Ordersres.data.data)
    } catch (error) {
        setLoading(false)
        toast('Error getting Consultant Info')
        }
    }
    useEffect(()=>
    {
        getProjectInfo();
    },[])

    const onSubmit = async(e,data)=>
    {
        try 
        {
            e.preventDefault()

            let res;
            // if(showUpdate.edit)
            // {
            //     res = await authAxios.put(`${process.env.REACT_APP_BASE_URL}/project/complete-request/${id}`,data) 
            //     InfoToast('Project Updated')
                
            // }
            // else
            // {
                const formData = new FormData();
                // formData.append('file', data.file);
                for (const [key, value] of Object.entries(data)) {
                    formData.append(key, value);
                }
                res = await authAxios.post(`${process.env.REACT_APP_BASE_URL}/project/complete-request/${id}`,formData,{
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }})
                // let imgRes = await authAxios.post(`${process.env.REACT_APP_BASE_URL}/upload`,formData,{
                //     headers: {
                //         'Content-Type': 'multipart/form-data'
                //     }})
                // res = await authAxios.post(`${process.env.REACT_APP_BASE_URL}/project/complete-request/${id}`,data)
                InfoToast('Project Request Completed')
                navigate('/project-request')
            // }
                await getProjectInfo()
                setShowUpdate({status:false,edit:null})
        } catch (error) {
            // console.log(error.response.data.error[0])
            ErrorToast(error.response.data.name)
        }
    }


    const columns = [
        // { key: 'id', name: 'Sample ID', frozen: true, width: 150},
        { key: 'studyNumber', name: 'Study Number' ,width: 150,renderCell:({row})=><p>{projectInfo.project.consultantId ? projectInfo.project.consultant.uniqueNumber : projectInfo.project.sponsor.uniqueNumber}/{row.studyNumber}</p>},
        { key: 'testName', name: 'Study Name' ,width: 350,renderCell:({row})=><p>{row.test.name }</p>},
        { key: 'sd', name: 'Study Director' ,width: 200,renderCell:({row})=><p>{row.sdAssigned ? row.sd.name : 'Not Assigned'}</p>},
        { key: 'sdApproval', name: 'SD Approval' ,width: 150,renderCell:({row})=><p>{row.sdApproved ? 'Approved' : 'Not Yet Approved'}</p>},
        { key: 'sampleRequired', name: 'Sample Required' ,width: 100,renderCell:({row})=><p>{row.sampleRequired ? 1 : '1'}</p>},
        { key: 'sdApprovedOn', name: 'Approved On',renderCell:({row})=><p>{row.sdApprovedOn ? getDateFormat(row.sdApprovedOn) : '-'}</p>}
        // { key: 'expectedDeliveryDate', name: 'Expected Delivery Date',renderCell:({row})=><p>{row.sdApprovedOn ? getDateFormat(row.sdApprovedOn) : '-'}</p>}
      ];
    

  return (
    !loading ? <>
        <Header  parentLink='project-request' parentTitle='Project Request' title={projectInfo.name} RightContent={RightContent}/>
        <div className='block flex-1 overflow-scroll bg-white w-full'>
            <div className='w-full border-b border-b-gray-300 pb-10'>
                <p className='text-xs uppercase text-gray-400 font-medium mb-4'>Project Information</p>
            
            <div className='grid grid-cols-12 w-full'>
                <div className='col-span-3'>
                    <label>Sponsor</label>
                    <p className='text-sm'>{projectInfo.project?.sponsor?.name} ({projectInfo.project.sponsor.uniqueNumber})</p>
                </div>
                <div className='col-span-3'>
                    <label>Consultant</label>
                    <p className='text-sm'>{projectInfo.project?.consultantId ? `${projectInfo.project.consultant.name} (${projectInfo.project.consultant.uniqueNumber})` : '-'}</p>
                </div>
                <div className='col-span-3'>
                    <label>Sponsor</label>
                    <p className='text-sm'>{projectInfo.project.sponsor?.name}</p>
                </div>
            </div>
            </div>

            <div className='w-full border-b border-b-gray-300 py-10 pt-6'>
                <p className='text-xs uppercase text-gray-400 font-medium mb-4'>Studies Information</p>
                <div className='w-full'>
                <ReactDataGrid
                    rowHeight={40}
                    rows={projectInfo.studies}
                    className='w-full'
                columns={columns}
                enableCellSelect={false}
                />

                </div>
            </div>

            <div>

            </div>
            
        </div>
        { showUpdate ? <ManageProjectRequest onClose={setShowUpdate} onSubmit={onSubmit} update={true} updateData={projectInfo}/> : null}
    </> : <Loader/>
  )
}


const StockInfo = ({id})=>
{
    const [searchText,setSearchText] = useState('')
    const [cityFilters,setCityFilters] = useState([])
    const [routeFilters,setRouteFilters] = useState([])
    const [selectedFilters,setSelectedFilters] = useState({city:'',route:''})
    const [stocks,setStocks] = useState([])
    const [products,setProducts] = useState([])
    const [showUpdate,setShowUpdate] = useState(false)
    const [showReturnUpdate,setShowReturnUpdate] = useState(false)

    async function getStocks(){

        try 
        {
            let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/stock/${id}?populate=true`)
            let productres = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/product/${id}?populate=true`)
            if(res.data.data) setStocks(res.data.data)
        } catch (error) {
            toast('Error getting products')
        }
    }
    useEffect(()=>
    {
        getStocks();
    },[])
    
    
    const onSubmit = async(e,data)=>
    {
        try 
        {
            e.preventDefault()
            let productres = await authAxios.post(`${process.env.REACT_APP_BASE_URL}/stock`,{stockDistributorId:id,stockProductId:data.productId,stockQty:data.stockCount})
            await getStocks();
            setShowUpdate(null)
            InfoToast('Stock Updated')
        } catch (error) {
            ErrorToast('Error getting products')
        }
    }

    const onReturnSubmit = async(e,data)=>
    {
        try 
        {
            e.preventDefault()
            let productres = await authAxios.post(`${process.env.REACT_APP_BASE_URL}/stock/clear-return`,{stockDistributorId:id,stockProductId:data.productId})
            await getStocks();
            setShowReturnUpdate(null)
            InfoToast('Return stock cleared')
        } catch (error) {
            ErrorToast('Error clearing return products')
        }
    }

    return <><div className='flex justify-start h-full w-full self-stretch'>
        
<div className='flex flex-1 bg-white w-full overflow-scroll'>
<table className='table-auto w-full flex-1'>
                <thead className='bg-[#f9f9f9] w-full'>
                    <tr className='w-full'>
                    <td><p>Image</p></td>
                    <td><p>Name/SKU</p></td>
                    {/* <td><p>Category</p></td> */}
                    <td><p>Weight(In Gms)</p></td>
                    <td><p>Stock</p></td>
                    <td><p>Return Stock</p></td>
                    <td><p>Total Value</p></td>
                    <td><p>Action</p></td>
                    </tr>
                </thead>
                <tbody>
                    {
                        stocks.map((item,index)=>
                        {
                            return <tr>
                                <td className='capitalize'>
                                    <div>
                                        <img src={item.productImage} className='w-16 h-16 rounded-full'/>
                                    </div>
                                </td>
                                <td className='capitalize'>
                                <div>
                                        <p className='text-sm font-regular my-0'>{item.productName}</p>
                                        <p className='text-xs my-0 text-gray-400'>{item.productSku}</p>
                                    </div>
                                </td>
    
                                <td className='capitalize'>
                                <div>
                                        <p className='text-sm font-regular my-0'>{item.productWeight}</p>
                                    </div>
                                </td>
                                <td className='capitalize'>
                                <div>
                                        <p className='text-sm font-regular my-0'>{parseInt(item.stockCount)}</p>
                                    </div>
                                </td>
                                <td className='capitalize'>
                                <div>
                                        <p className='text-sm font-regular my-0'>{parseInt(item.returnQty)}</p>
                                    </div>
                                </td>
                                <td className='capitalize'>
                                <div>
                                        <p className='text-sm font-regular my-0'>Rs.{(parseInt(item.stockCount)*item.productPrice)}</p>
                                    </div>
                                </td>
                                <td className='capitalize'>
                                <div>
                                    <button className='btn-xs mb-1' onClick={()=>setShowUpdate(item)}>Update</button>
                                    <button className='btn-xs' onClick={()=>setShowReturnUpdate(item)}>Clear Return</button>
                                    </div>
                                </td>

                            </tr>
                        })
                    }
                </tbody>
            </table>
    </div>
    </div>
    {showUpdate ? <ManageStock data={showUpdate} onSubmit={onSubmit} onClose={()=>setShowUpdate(null)}/> : null}
    {showReturnUpdate ? <ManageReturnQty data={showReturnUpdate} onSubmit={onReturnSubmit} onClose={()=>setShowReturnUpdate(null)}/> : null}
    </>
}


const MarginInfo = ({id})=>
{

    const [margins,setMargins] = useState([])
    const [showUpdate,setShowUpdate] = useState(false)

    async function getMargins(){

        try 
        {
            let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/margin/${id}?populate=true`)
            let productres = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/product/${id}?populate=true`)
            if(res.data.data) setMargins(res.data.data)
        } catch (error) {
            toast('Error getting products')
        }
    }
    useEffect(()=>
    {
        getMargins();
    },[])
    
    
    const onSubmit = async(e,data)=>
    {
        try 
        {
            e.preventDefault()
            let productres = await authAxios.post(`${process.env.REACT_APP_BASE_URL}/margin`,{marginDistributorId:id,marginProductId:data.productId,marginAmount:data.marginAmount})
            await getMargins();
            setShowUpdate(null)
            InfoToast('Stock Updated')
        } catch (error) {
            ErrorToast('Error getting products')
        }
    }


    return <><div className='flex justify-start h-full w-full self-stretch'>
        
<div className='flex flex-1 bg-white w-full overflow-scroll'>
<table className='table-auto w-full flex-1'>
                <thead className='bg-[#f9f9f9] w-full'>
                    <tr className='w-full'>
                    <td><p>Image</p></td>
                    <td><p>Name/SKU</p></td>
                    <td><p>Weight(In Gms)</p></td>
                    <td><p>Resale Amount</p></td>
                    <td><p>Margin Amount</p></td>
                    <td><p>Total Amount</p></td>
                    <td><p>Action</p></td>
                    </tr>
                </thead>
                <tbody>
                    {
                        margins.map((item,index)=>
                        {
                            return <tr>
                                <td className='capitalize'>
                                    <div>
                                        <img src={item.productImage} className='w-16 h-16 rounded-full'/>
                                    </div>
                                </td>
                                <td className='capitalize'>
                                <div>
                                        <p className='text-sm font-regular my-0'>{item.productName}</p>
                                        <p className='text-xs my-0 text-gray-400'>{item.productSku}</p>
                                    </div>
                                </td>
    
                                <td className='capitalize'>
                                <div>
                                        <p className='text-sm font-regular my-0'>{item.productWeight}</p>
                                    </div>
                                </td>
                                <td className='capitalize'>
                                <div>
                                        <p className='text-sm font-regular my-0'>{parseFloat(item.productPrice)}</p>
                                    </div>
                                </td>
                                <td className='capitalize'>
                                <div>
                                        <p className='text-sm font-regular my-0'>{parseFloat(item.marginAmount)}</p>
                                    </div>
                                </td>
                                <td className='capitalize'>
                                <div>
                                        <p className='text-sm font-regular my-0'>Rs.{(parseFloat(item.productPrice)+parseFloat(item.marginAmount))}</p>
                                    </div>
                                </td>
                                <td className='capitalize'>
                                <div>
                                    <button className='btn-xs mb-1' onClick={()=>setShowUpdate(item)}>Update</button>
                                    </div>
                                </td>

                            </tr>
                        })
                    }
                </tbody>
            </table>
    </div>
    </div>
    {showUpdate ? <ManageMargin data={showUpdate} onSubmit={onSubmit} onClose={()=>setShowUpdate(null)}/> : null}
    </>
}

const ManageMargin = ({onClose,onSubmit,data})=>
{
    console.log('item',data)
    const [manageData,setManageData] = useState({marginAmount:data.marginAmount,productId:data.id})
    
    return <Popup title={'Update Margin'} submitTitle={'Update'} onClose={onClose} formName={'updateMargin'}>
        <form onSubmit={(e)=>onSubmit(e,manageData)} id={'updateMargin'} className='grid grid-cols-2 gap-x-3 gap-y-6 w-full'>
            <div>
                <label>Product Name</label>
                <p className='text-sm text-medium'>{data?.productName}</p>
            </div>
            <div>
                <label>Product SKU</label>
                <p className='text-sm text-medium'>{data?.productSku}</p>
            </div>
            <div className='col-span-2'>
                <label>Product Margin Amount</label>
                <Input type='text' number={false} placeholder={'Enter Margin Amount'} value={manageData.marginAmount ? parseFloat(manageData.marginAmount) : ''} setValue={(value)=>setManageData(data=>({...data,marginAmount:value}))}/>
            </div>
        </form>
    </Popup>
}

const ManageStock = ({onClose,onSubmit,data})=>
{
    console.log('item',data)
    const [manageData,setManageData] = useState({stockCount:data.stockCount,productId:data.id})
    
    return <Popup title={'Update Stock'} submitTitle={'Update'} onClose={onClose} formName={'updateStock'}>
        <form onSubmit={(e)=>onSubmit(e,manageData)} id={'updateStock'} className='grid grid-cols-2 gap-x-3 gap-y-6 w-full'>
            <div>
                <label>Product Name</label>
                <p className='text-sm text-medium'>{data?.productName}</p>
            </div>
            <div>
                <label>Product SKU</label>
                <p className='text-sm text-medium'>{data?.productSku}</p>
            </div>
            <div className='col-span-2'>
                <label>Product Stock</label>
                <Input type='text' placeholder={'Enter Stock'} value={manageData.stockCount ? parseInt(manageData.stockCount) : ''} setValue={(value)=>setManageData(data=>({...data,stockCount:value}))}/>
            </div>
        </form>
    </Popup>
}

const ManageReturnQty = ({onClose,onSubmit,data})=>
{
    console.log('item',data)
    const [manageData,setManageData] = useState({productId:data.id})
    
    return <Popup title={'Clear Return Stock'} submitTitle={'Clear Return Stock'} onClose={onClose} formName={'clearReturnStock'}>
        <form onSubmit={(e)=>onSubmit(e,manageData)} id={'clearReturnStock'} className='grid grid-cols-3 gap-x-3 gap-y-6 w-full'>
            <div>
                <label>Product Name</label>
                <p className='text-sm text-medium'>{data?.productName}</p>
            </div>
            <div>
                <label>Product SKU</label>
                <p className='text-sm text-medium'>{data?.productSku}</p>
            </div>
            <div>
                <label>Return Stock</label>
                <p className='text-sm text-medium'>Qty.{data?.returnQty}</p>
            </div>
        </form>
    </Popup>
}
const ProfileInfo = ({data})=>
{
    // const [info,setInfo] = useState({})
    const [cities,setCities] = useState('')
    const [routes,setRoutes] = useState('')

    useEffect(()=>
    {
        if(data.distributorRoutes)
        {
            let cityNames = [];
            let routeNames = [];
            
            data.distributorRoutes.forEach(item => {
                // Extract city name
                const cityName = item.cityInfo.cityName;
                cityNames.push(cityName);
                
                // Extract route names
                item.routes.forEach(route => {
                    const routeName = route.routeInfo.routeName;
                    routeNames.push(routeName);
                });
            });
            
            setCities(cityNames.join(', '));
            setRoutes(routeNames.join(', '));
            
        }
    },[data])

    return <div className='flex justify-start items-start h-[100%] py-12 px-6'>
        {/* <div className='mb-4 w-[120px] h-[120px] mr-20'>
            <img src={info?.retailerImage} className='w-[120px] h-[120px] rounded-full'/>
        </div> */}
        <div className='grid grid-cols-4 gap-6'>
            <div>
                <label>Company Name</label>
                <p className='text-sm'>{data?.distributorCompanyName}</p>
            </div>
            <div>
                <label>Owner Name</label>
                <p className='text-sm'>{data?.distributorName}</p>
            </div>
            <div>
                <label>Contact Number</label>
                <p className='text-sm'>{data?.distributorContactNumber}</p>
            </div>
            <div>
                <label>Email Address</label>
                <p className='text-sm'>{data?.distributorEmail}</p>
            </div>
            <div>
                <label>Cities</label>
                <p className='text-sm'>{cities}</p>
            </div>
            <div>
                <label>Routes</label>
                <p className='text-sm'>{routes}</p>
            </div>
            <div>
                <label>Area</label>
                <p className='text-sm'>{data?.distributorArea}</p>
            </div>
            <div>
                <label>Address</label>
                <p className='text-sm'>{data?.distributorAddress}</p>
            </div>
            {data.distributorLat ? <div>
                <label>Map Location</label>
                <a href={`https://maps.google.com/?q=${data.distriutorLat},${data.distriutorLong}`} className='text-sm text-blue-600 underline' target='_blank'>Map Link</a>
            </div> : null}
        </div>
    </div>
}
