import { useEffect, useState } from "react"
import Input from "../../../components/Input"
import Map from "../../../components/Map"
import Popup from "../../../components/Popup"
import Select from "../../../components/Select"
import { authAxios } from "../../../helpers/axios"
import ImageInput from "../../../components/ImageInput"
import CropPopup from "../../../components/CropPopup"
import { RETAILER_IMAGE_BASE_URL } from "../../../helpers/constants"
import { dataURItoBlob } from "../../../helpers/utils"

export default function ManageRack({onClose,edit=false,onSubmit,updateData,activeWarehouse})
{
    const [manageData,setManageData] = useState({name:'',warehouseId:activeWarehouse,rackType:''})
    const [rackTypes,setRackTypes] = useState([])
    const [warehouses,setWarehouses] = useState([])
    const [showCrop,setShowCrop] = useState(false)
    const formName = 'editRetailer';

    
        useEffect(()=>
        {
            async function getWarehouses(){
                let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/warehouse`)
                setWarehouses(res.data)
            }
            async function getRackTypes(){
                let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/rack-type`)
                setRackTypes(res.data)
            }
            getRackTypes()
            getWarehouses()
        },[])
        
        // useEffect(()=>
        // {
        //     async function getTids(){
        //         if(manageData.sponsorId && manageData.sponsorId != '')
        //         {
        //             let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/tids?sponsorId=${manageData.sponsorId}`)
        //             setTids(res.data)
        //         }
        //     }
        //     getTids()
        // },[manageData.sponsorId])






    return <><Popup size="md" title={edit ? 'Update Rack Type' : 'Create Rack Type'} submitTitle={edit ? 'Update' : 'Create'} onClose={onClose} formName={formName}>
        <form onSubmit={(e)=>onSubmit(e,{...manageData})} id={formName} className='w-full'>
            <div className="grid grid-cols-2 gap-x-3 gap-y-0 w-full">
            <div>
                <label>Rack Name*</label>
                <Input type='text' placeholder={'Enter Rack Name'} required={true} value={manageData.name} setValue={(value)=>setManageData(data=>({...data,name:value}))}/>
            </div>
            <div>
                <label>Warehouse</label>
                <Select placeholder={'Select Warehouse'} required={true} options={warehouses} customLabel={'name'} customValue={'id'} value={manageData.warehouseId} setValue={(value)=>setManageData(data=>({...data,warehouseId:value}))}/>
            </div>
            <div>
                <label>Rack Type</label>
                <Select placeholder={'Select Rack Type'} required={true} options={rackTypes} customLabel={'name'} customValue={'id'} value={manageData.rackType} setValue={(value)=>setManageData(data=>({...data,rackType:value}))}/>
            </div>
            </div>
        </form>
    </Popup>
    </>
}
