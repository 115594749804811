
export default function TabGroup({options,activeTab='orders',setActiveTab}) {
    return (
        <div class={`relative self-stretch h-[full]`}>
            <div className='flex justify-start content-stretch bg-[#f4f4f4] rounded-md p-1'>
                {
                    options.map((item,index)=>
                    {
                        return <div key={index} onClick={()=>setActiveTab(item.value)} className={`cursor-pointer  transition-all duration-100 px-3 py-1 rounded-md border border-transparent relative ${activeTab === item.value ? 'bg-white shadow-sm shadow-gray-200 border-gray-300' : ''}`}>
                            <p className={`text-[13px] tracking-[-.25px] font-medium  ${activeTab === item.value ? 'text-[#182159]' : 'text-[#a3a3a3]'}`}>{item.label}</p>
                        </div>
                    })
                }
            </div>
        </div>
  )
}