import { LIMIT } from "../helpers/constants";
import RightIcon from '../images/right.svg'


import React from 'react'

export default function Pagination({offset,setOffset,count}) {
  return  <div className='flex justify-between items-center bg-[#f3f3f6] mr-4'>
            <div className='flex'>
                <div className='flex w-full border rounded-md py-2.5 items-center h-full justify-center text-center'>
                    <div className='px-2 hover:bg-[#f6f6f6] h-full cursor-pointer flex items-center flex-1 justify-center' onClick={()=>{if (offset > 0) {
                            setOffset(offset - LIMIT);
                        }}}>
                        <img src={RightIcon} className='w-[20px] h-[20px] rotate-180'/>
                    </div>
                    <p className='text-xs whitespace-nowrap w-[80px] justify-center'>{offset + 1}-{Math.min(offset + LIMIT, count)} of {count}</p>
                    <div className='px-2 hover:bg-[#f6f6f6] h-full cursor-pointer flex items-center flex-1 justify-center' onClick={()=>{if (offset + LIMIT < count) {
                            setOffset(offset + LIMIT);
                        }}}>
                        <img src={RightIcon} className='w-[20px] h-[20px]'/>
                    </div>
                </div>
            </div>
        </div>
}