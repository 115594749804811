import React, { useEffect, useState } from 'react'
import { authAxios } from '../../../helpers/axios'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { ErrorToast, InfoToast } from '../../../helpers/toasters'
import { LIMIT } from '../../../helpers/constants'
import ReactDataGrid from 'react-data-grid';

export default function ConsultantTids({id}) {

    const [searchText,setSearchText] = useState('')
    const [tids,setTids] = useState([])
    const [showCreate,setShowCreate] = useState({status:false,edit:null})

    const [cityFilters,setCityFilters] = useState([])
    const [routeFilters,setRouteFilters] = useState([])
    const navigate = useNavigate()
    const [sort,setSort] = useState('name');
    const [offset,setOffset] = useState(0);
    const [count,setCount] = useState(0)
    const [loading,setLoading] = useState(true)

    const columns = [
        // { key: 'id', name: 'Sample ID', frozen: true, width: 150 },
        { key: 'testItemName', name: 'Test Item Name' ,width: 150},
        { key: 'sterilityType', name: 'Sterility Type' ,width: 150},
        { key: 'acceptableToCut', name: 'Acceptable To Cut' ,width: 150,renderCell:({row})=><p>{row.acceptableToCut ? 'Yes' : 'No'}</p>},
        { key: 'appearance', name: 'Appearance' ,width: 250},
        { key: 'description', name: 'Description'},
        { key: 'sponsorName', name: 'Sponsor Name' ,width: 150,frozen:true,renderCell:({row})=><p>{row.sponsor.name}</p>},
        { key: 'temperature', name: 'Sponsor Name' ,width: 150},
        { key: 'ph', name: 'Sponsor Name' ,width: 100},
        { key: 'sampleStorageCondition', name: 'Sample Storage Condition' ,width: 150},
        { key: 'glrStorageCondition', name: 'GLR Storage Condition' ,width: 150},
        { key: 'extractionRatio', name: 'Extraction Ratio' ,width: 150}
      ];
    

    const [selectedFilters,setSelectedFilters] = useState({city:'',route:''})

    const onSortPress = (type)=>
    {
        if (sort === type) {
            if(sort === `-${type}`) setSort(type)
            else setSort(`-${type}`);
          } else {
            setSort(type);
          }
    }

    async function getTids(){

        try 
        {
            let query = `&limit=${LIMIT}&offset=${offset}&orderBy=${sort}`;
                if(searchText) query+= `&search=${searchText}`;
                query+= `&sponsorId=${id}`;
            let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/tids?populate=true${query}`)
            if(res.data)
            {
                setTids(res.data)
                setCount(res.data.count)
            }
            setLoading(false)
        } catch (error) {
            setLoading(false)
            ErrorToast('Error getting tids')
        }
    }
    useEffect(()=>
    {
        getTids();
    },[])


    const onSubmit = async(e,data)=>
    {
        try 
        {
            e.preventDefault()

            let res;
            if(showCreate.edit)
            {
                res = await authAxios.put(`${process.env.REACT_APP_BASE_URL}/tids/${showCreate.edit}`,data) 
                InfoToast('TIDS Updated')
                
            }
            else
            {
                res = await authAxios.post(`${process.env.REACT_APP_BASE_URL}/tids`,data)
                InfoToast('TIDS Created')
            }
            if(res.data)
            {
                await getTids()
                setShowCreate({status:false,edit:null})
            }
            else toast('error updating tids')
        } catch (error) {
            // console.log(error.response.data.error[0])
            ErrorToast(error.response.data.error[Object.keys(error.response.data.error)[0]])
        }
    }


    const rowGetter = (i) => {
        if (i < tids.length) {
          const rowData = { ...tids[i] };
          // Accessing nested property sponsor.name
          rowData['sponsorName'] = tids[i].sponsor.name;
          return rowData;
        }
        return undefined;
      };
      


  return (
    <>
        {!loading ? <div className='block mt-4 flex-1 bg-white w-full justify-stretch'>
        <ReactDataGrid
        rowHeight={40}
        rows={tids}
        className='w-full'
      columns={columns}
      enableCellSelect={false}
    />
            
        </div> : null} 
    </>
  )
}

