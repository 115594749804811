import React, { useEffect, useState } from 'react'
import Header from '../../../components/Header'
import { authAxios } from '../../../helpers/axios'
import { toast } from 'react-toastify'
import FilterSelect from '../../../components/FilterSelect'
import { useNavigate } from 'react-router-dom'
import SearchInput from '../../../components/SearchInput'
import DatePicker from 'react-datepicker';
import moment from 'moment'
import { getDateFormat, getDayFromDate } from '../../../helpers/utils'
import { ErrorToast } from '../../../helpers/toasters'
// import TeamLeaderboard from './components/TeamLeaderboard'

export default function UserLogs({id}) {

    const [logs,setLogs] = useState([])
    const [scheduleDate,setScheduleDate] = useState(new Date())

    // async function getLogs(){

    //     try 
    //     {
    //         let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/logs/${id}`)
    //         if(res.data) setLogs(res.data)
    //     } catch (error) {
    //         ErrorToast('Error Getting Logs')
    //     }
    // }
    // useEffect(()=>
    // {
    //     getLogs();
    // },[])


  return (
    <div className='w-full'>
        <div className='flex justify-between items-stretch border-b border-[#e3e3e3] bg-white w-full'>
            <div className='flex'>
            {/* <SearchInput value={searchText} setValue={(value)=>setSearchText(value)} placeholder={'Search Sales Rep'} label={'Search Team'} /> */}
            </div>
        </div>
        <div className='flex flex-1 bg-white w-full'>
            <table className=' w-full'>
                <thead className='bg-[#f9f9f9] w-full'>
                    <tr className='w-full'>
                    <td><p>Timestamp</p></td>
                    <td><p>Log Description</p></td>
                    <td><p>Log Type</p></td>
                    </tr>
                </thead>
                <tbody>
                    {
                        logs.map((item,index)=>
                        {
                            return <tr>
                                <td className='capitalize'>
                                    <div>
                                        <p className='text-sm font-regular my-0'>{item.scheduleDate}</p>
                                    </div>
                                </td>
                                <td className='capitalize'>
                                <div>
                                        <p className='text-sm font-regular my-0'>{item.logDescription}</p>
                                    </div>
                                </td>
                                <td className='capitalize'>
                                <div>
                                        <p className='text-sm font-regular my-0'>{item.orderCount}</p>
                                    </div>
                                </td>
                            </tr>
                        })
                    }
                </tbody>
            </table>
        </div>
    </div>
  )
}

