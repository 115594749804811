import React, { useEffect, useState } from 'react'
import Header from '../../components/Header'
import { authAxios } from '../../helpers/axios'
import { toast } from 'react-toastify'
import { useParams } from 'react-router-dom'
import TabGroup from '../../components/TabGroup'
import ManageSponsor from './components/ManageSponsor'
import { ErrorToast, InfoToast } from '../../helpers/toasters'
import SponsorStudies from './components/SponsorStudies'
import SponsorSample from './components/SponsorSamples'
import SponsorTids from './components/SponsorTids'
import { findCountryByCode } from '../../helpers/utils'

export default function SponsorInfo() {

    const {id} = useParams();
    const [sponsorInfo,setSponsorInfo] = useState([])
    const [showEdit,setShowEdit] = useState(false)
    const [orders,setOrders] = useState([])
    const [activeTab,setActiveTab] = useState('study')
    const [stocks,setStocks] = useState([])

    const RightContent = ()=>
    {
        return <div>
          <button type='button' className='btn-md' onClick={()=>setShowEdit(true)}>Edit Sponsor</button>
        </div>
    }

    async function getSponsorInfo(){

        try 
        {
            let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/sponsor/${id}?populate=true`)
            if(res.data) setSponsorInfo(res.data)
            // let Ordersres = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/orders?distributorId=${id}&populate=true`)
            // if(Ordersres.data.data) setOrders(Ordersres.data.data)
        } catch (error) {
            toast('Error getting Consultant Info')
        }
    }
    useEffect(()=>
    {
        getSponsorInfo();
    },[])

    const onSubmit = async(e,data)=>
    {
        try 
        {
            e.preventDefault()
            let res = await authAxios.put(`${process.env.REACT_APP_BASE_URL}/sponsor/${id}`,data) 
            if(res.data.data)
            {
                await getSponsorInfo()
                setShowEdit(false)
            }
            else toast('error updating distributor')
        } catch (error) {
            toast(error.response.data)
        }
    }

  return (
    <>
        <Header  parentLink='sponsors' parentTitle='Sponsors' title={sponsorInfo.name} RightContent={RightContent}/>
        <div className='flex justify-between items-stretch '>
            <div>
               <TabGroup activeTab={activeTab} setActiveTab={setActiveTab} options={[{label:'Study',value:'study'},{label:'Samples',value:'samples'},{label:'TIDS',value:'tids'},{label:'Profile',value:'profile'}]}/>
            </div>
        </div>
        <div className='flex flex-1 overflow-scroll bg-white w-full'>
            {activeTab === 'study' ? <SponsorStudies id={id} orders={orders}/> : activeTab === 'samples' ? <SponsorSample id={id}/> : activeTab === 'tids' ? <SponsorTids id={id}/> : <ProfileInfo data={sponsorInfo}/>}
            
        </div>
        { showEdit ? <ManageSponsor onClose={setShowEdit} onSubmit={onSubmit} update={true} updateData={sponsorInfo}/> : null}
    </>
  )
}

const ProfileInfo = ({data})=>
{
    // const [info,setInfo] = useState({})
    const [cities,setCities] = useState('')
    const [routes,setRoutes] = useState('')

    useEffect(()=>
    {
        if(data.distributorRoutes)
        {
            let cityNames = [];
            let routeNames = [];
            
            data.distributorRoutes.forEach(item => {
                // Extract city name
                const cityName = item.cityInfo.cityName;
                cityNames.push(cityName);
                
                // Extract route names
                item.routes.forEach(route => {
                    const routeName = route.routeInfo.routeName;
                    routeNames.push(routeName);
                });
            });
            
            setCities(cityNames.join(', '));
            setRoutes(routeNames.join(', '));
            
        }
    },[data])

    return <div className='flex justify-start items-start h-[100%] w-full py-12'>
        {/* <div className='mb-4 w-[120px] h-[120px] mr-20'>
            <img src={info?.retailerImage} className='w-[120px] h-[120px] rounded-full'/>
        </div> */}
        <div className='grid grid-cols-4 gap-6 gap-y-10 w-full'>
            <div>
                <label>Sponsor ID</label>
                <p className='text-sm'>{data?.uniqueNumber}</p>
            </div>
            <div>
                <label>Sponsor Name</label>
                <p className='text-sm'>{data?.name}</p>
            </div>
            <div>
                <label>Consultant Name</label>
                <p className='text-sm'>{data?.consultant?.name}</p>
            </div>
            <div>
                <label>Contact Name</label>
                <p className='text-sm'>{data?.contactPerson}</p>
            </div>
            <div>
                <label>Contact Number</label>
                <p className='text-sm'>{data?.contactNumber}</p>
            </div>
            <div>
                <label>ContactEmail</label>
                <p className='text-sm'>{data?.contactEmail}</p>
            </div>
            <div>
                <label>Country</label>
                <p className='text-sm'>{findCountryByCode(data?.countryCode).name}</p>
            </div>
            <div>
                <label>City</label>
                <p className='text-sm'>{data?.city}</p>
            </div>
            <div>
                <label>Address</label>
                <p className='text-sm'>{data?.address}</p>
            </div>
        </div>
    </div>
}
