import { useEffect, useState } from "react"
import Input from "../../../components/Input"
import Map from "../../../components/Map"
import Popup from "../../../components/Popup"
import Select from "../../../components/Select"
import { authAxios } from "../../../helpers/axios"
import { Countries } from "../../../helpers/constants"

export default function ManageConsultant({onClose,update=false,onSubmit,updateData})
{
    const [manageData,setManageData] = useState(!update ? {name:'',uniqueNumber:'',useOwnNumber:false,city:'',countryCode:'IN',contactPerson:'',contactNumber:'',contactEmail:'',city:'',address:'',status:1} : {...updateData,uniqueNumber:'',useOwnNumber:parseInt(updateData.useOwnNumber),city:updateData.city,countryCode:updateData.countryCode,contactPerson:updateData.contactPerson,contactNumber:updateData.contactNumber,contactEmail:updateData.contactEmail,city:updateData.city,address:updateData.city,address:updateData.address,status:updateData.status})
    const formName = 'editConsultant';

    const [submitting,setSubmitting] = useState(false)

    const onCreate = async(e)=>
    {
        setSubmitting(true)
        await onSubmit(e,manageData)
        setSubmitting(false)

    }
      

    // useEffect(()=>
    // {
    //     async function getCities(){
    //         let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/city`)
    //         setCities(res.data.data)
    //     }
    //     getCities()
    // },[])




    return <Popup title={update ? 'Update Consultant' : 'Create Consultant'} submitting={submitting} submitTitle={update ? 'Update' : 'Create'} onClose={onClose} formName={formName}>
        <form onSubmit={onCreate} id={formName} className=' w-full'>
            <div className='grid grid-cols-4 gap-x-2 gap-y-0 w-full'>
            <div className="col-span-2">
                <label>Company Name</label>
                <Input type='text' required={true} placeholder={'Enter Company Name'} value={manageData.name} setValue={(value)=>setManageData(data=>({...data,name:value}))}/>
            </div>
            <div className="col-span-2">
                <label>Unique Id</label>
                <Input type='text' required={true} placeholder={'Enter Consultant Unique Id'} value={manageData.uniqueNumber} setValue={(value)=>setManageData(data=>({...data,uniqueNumber:value}))}/>
                <label className="align-middle -mt-2 mb-2"><input type="checkbox" className="mr-2" value={manageData.useOwnNumber} onChange={(e)=>setManageData(data=>({...data,useOwnNumber:e.target.checked ? true : false}))}/>Use Own Unique Id</label>
            </div>
            <div className="col-span-2">
                <label>Contact Person</label>
                <Input type='text' required={true} placeholder={'Enter Company Name'} value={manageData.contactPerson} setValue={(value)=>setManageData(data=>({...data,contactPerson:value}))}/>
            </div>
            <div className="col-span-2">
                <label>Contact Number</label>
                <Input type='text' required={true} placeholder={'Enter Contact Number'} value={manageData.contactNumber} setValue={(value)=>setManageData(data=>({...data,contactNumber:value}))}/>
            </div>
            <div className="col-span-2">
                <label>Contact Email</label>
                <Input type='text' required={true} placeholder={'Enter Email'} value={manageData.contactEmail} setValue={(value)=>setManageData(data=>({...data,contactEmail:value}))}/>
            </div>
            <div className="col-span-2">
                <label>Country</label>
                <Select options={Countries} customLabel={'name'} customValue={'code'} setValue={(value)=>setManageData((data)=>({...data,status:value}))} required={true}></Select>
            </div>
            <div className="col-span-2">
                <label>City</label>
                <Input type='text' placeholder={'Enter City'} value={manageData.city} required={true} setValue={(value) =>setManageData(data=>({...data,city:value}))}/>
            </div>
            <div className="col-span-2">
                <label>Address</label>
                <Input type='text' placeholder={'Enter Address'} value={manageData.address} setValue={(value)=>setManageData(data=>({...data,address:value}))} required={true}/>
            </div>
            <div className="col-span-2">
                <label>Status</label>
                <Select options={[{label:'Active',value:1},{label:'Inactive',value:0}]} customLabel={'label'} customValue={'value'} setValue={(value)=>setManageData((data)=>({...data,status:value}))} required={true}></Select>
            </div>
            </div>
        </form>
    </Popup>
}