import { useEffect, useState } from "react"
import Input from "../../../components/Input"
import Popup from "../../../components/Popup"
import Select from "../../../components/Select"
import { authAxios } from "../../../helpers/axios"
import Loader from "../../../components/Loader"
import { roles } from "../../../helpers/constants"

export default function ManageUser({onClose,edit,onSubmit,submitting,updateData=null,active})
{
    const [manageData,setManageData] = useState(!updateData ? {name:'',email:'',empId:'',contactNumber:'',role:''}:{name:'',email:'',empId:'',contactNumber:'',role:''})
    const [teams,setTeams] = useState([])
    const formName = 'editSalesRep';
    const [loading,setLoading] = useState(true)
    
useEffect(()=>{
    const fetchData = async () => {
      try {
        // Fetch teams
        setLoading(true)
        // Fetch representative info if in edit mode
        if (edit) {
          const repInfoResponse = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/user/${edit}`);
          setManageData({
            name: repInfoResponse.data.name,
            email: repInfoResponse.data.email,
            contactNumber: repInfoResponse.data.contactNumber,
            role: repInfoResponse.data.role,
          });
        }
        setLoading(false)
      } catch (error) {
        setLoading(false)
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [edit]);


    return <><Popup active={active} submitting={submitting} title={edit ? 'Update user' : 'Create user'} submitTitle={edit ? 'Update' : 'Create'} onClose={onClose} formName={formName}>
        {!loading ? <form onSubmit={(e)=>onSubmit(e,manageData)} id={formName} className='w-full'>
            <div className="grid grid-cols-2 gap-x-3 gap-y-0 w-full pr-4  items-start">
            <div>
                <label>Full Name</label>
                <Input type='text' placeholder={'Enter Full Name'} value={manageData.name} setValue={(value)=>setManageData(data=>({...data,name:value}))}/>
            </div>
            <div>
                <label>Employee ID</label>
                <Input type='text' placeholder={'Enter Employee Id'} value={manageData.empId} setValue={(value)=>setManageData(data=>({...data,empId:value}))}/>
            </div>
            <div>
                <label>Email</label>
                <Input type='text' placeholder={'Enter Email Address'} value={manageData.email} setValue={(value)=>setManageData(data=>({...data,email:value}))}/>
            </div>
            <div>
                <label>Phone Number (+91)</label>
                <Input type='text' placeholder={'Enter Phone Number'} value={manageData.contactNumber} setValue={(value)=>setManageData(data=>({...data,contactNumber:value}))}/>
            </div>
            <div>
                <label>Role</label>
                <Select placeholder={'Select Role'} customLabel={'label'} options={roles} customValue={'value'} value={manageData.role} setValue={(value)=>setManageData(data=>({...data,role:value}))}/>
            </div>
            </div>
        </form> : <div className="max-h-[420px] w-full justify-center items-center"><Loader/></div>}
    </Popup>
    </>
}


const CityItem = ({item,updateRepRoutes,manageData})=>
{
    return <div className="py-2 border-b border-b-gray-200 px-4">
        <p className="font-semibold text-sm">{item.cityName}</p>
        <div className="flex flex-wrap gap-2 mt-2">
        {
            item.routes.map((i,index)=>
            {
                return <RouteItem manageData={manageData} updateRepRoutes={updateRepRoutes} item={i} key={item.cityId} cityId={item.cityId}/>
            })
        }
        </div>
    </div>
}

const RouteItem = ({item,updateRepRoutes,cityId,manageData})=>
{
  const [isRouteSelected,setIsRouteSelected] = useState(false)
  useEffect(()=>
  {
    let check = manageData.cities?.some(
        (city) => city.cityId === cityId && city?.routes?.includes(item.routeId)
      );
      setIsRouteSelected(check)

  },[manageData])

    const handleRouteClick = () => {
        // Call the updateRepRoutes function to update the state
        updateRepRoutes(cityId, item.routeId);
      };

    return <div className={`py-2 border border-gray-200 px-2 rounded-full cursor-pointer ${isRouteSelected ? 'bg-[#2ac08b]' : 'bg-[#f3f3f3]'}`} onClick={handleRouteClick}>
        <p className={`font-medium tracking-tight text-xs ${isRouteSelected ? 'text-white' : ''}`}>{item.routeName}</p>
    </div>
}