import { useEffect, useState } from "react"
import Input from "../../../components/Input"
import Map from "../../../components/Map"
import Popup from "../../../components/Popup"
import Select from "../../../components/Select"
import { authAxios } from "../../../helpers/axios"
import ImageInput from "../../../components/ImageInput"
import CropPopup from "../../../components/CropPopup"
import { RETAILER_IMAGE_BASE_URL } from "../../../helpers/constants"
import { dataURItoBlob } from "../../../helpers/utils"

export default function UpdateSampleRequest({onClose,edit=false,onSubmit,id,requestId})
{
    const [manageData,setManageData] = useState({id:requestId,sampleItemId:'',description:'',status:''})
    const [rackTypes,setRackTypes] = useState([])
    const [warehouses,setWarehouses] = useState([])
    const [showCrop,setShowCrop] = useState(false)
    const formName = 'updateSampleRequest';
    const [sampleRequestInfo,setSampleRequestInfo] = useState({})

    
        useEffect(()=>
        {
            async function getSampleRequestInfo(){
                let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/sample/${id}`)
                setSampleRequestInfo(res.data)
            }
            getSampleRequestInfo()
        },[])




    return <><Popup size="md" title={'Update Sample Request'} submitTitle={'Update'} onClose={onClose} formName={formName}>
        <form onSubmit={(e)=>onSubmit(e,{...manageData})} id={formName} className='w-full'>
            <div className="grid grid-cols-2 gap-x-3 gap-y-0 w-full">
            <div>
                <label>Select Status</label>
                <Select placeholder={'Select status'} required={true} options={[{name:'Accept',value:'accept'},{name:'Cancel',value:'cancel'}]} customLabel={'name'} customValue={'value'} value={manageData.status} setValue={(value)=>setManageData(data=>({...data,status:value}))}/>
            </div>
            <div>
                <label>Lot Number</label>
                <Select placeholder={'Select Lot Number'} required={true} options={sampleRequestInfo?.sample?.sampleitems ? sampleRequestInfo?.sample?.sampleitems : []} customLabel={'lotNumber'} customValue={'id'} value={manageData.sampleItemId} setValue={(value)=>setManageData(data=>({...data,sampleItemId:value}))}/>
            </div>
            <div>
                <label>Note</label>
                <Input type='text' placeholder={'Enter Note'} required={true} value={manageData.description} setValue={(value)=>setManageData(data=>({...data,description:value}))}/>
            </div>
            </div>
        </form>
    </Popup>
    </>
}
