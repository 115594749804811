import React, { createRef, useState } from 'react'
import CloseIcon from '../images/close.svg'

export default function Popup({title,onClose,children,submitTitle,formName,submitting,size='md',active=false}) {

  return (
    <div className={`${active === true ? 'w-full' : 'w-0 h-0 overflow-hidden'} fixed z-[999] w-[100%] h-full left-0 top-0 flex items-start overflow-scroll transition-all duration-300`}>
      <div className={`flex w-full h-full overflow-scroll justify-center transition-all duration-1500 items-center relative bg-black bg-opacity-70 ${active === true ? 'backdrop-blur-[1.5px]' : 'backdrop-blur-[0]'}`}>
    <div className={`${size === 'xl' ? 'w-[95%] rounded-l-md right-0 top-0 h-full' : 'w-[520px] rounded-md top-10 h-auto right-[320px]'} max-w-full bg-[#f3f3f3] transition-all duration-500  flex flex-col justify-between  overflow-hidden ${active === true ? 'translate-y-0' : 'translate-y-0'}`}>
        <div className='flex px-8 pt-4 py-2 justify-between items-center bg-[#f8f8fa] border-b-0 border-b-slate-200'>
            <h3 className='text-[14px] font-semibold tracking-[-.15px] capitalize'>{title}</h3>
            <div className=' px-2 py-2 rounded-md transition-all cursor-pointer' onClick={()=>onClose(false)}>
              <img src={CloseIcon} className='w-6 h-6'/>
            </div>
        </div>
        <div className='flex px-8 py-6 pb-4 w-full bg-[#fff] shadow-sm'>
            {children}
        </div>
        <div className='h-[40px] items-center flex justify-end mt-6 px-8 py-6 pt-0 border-t-0 bg-[#f3f3f3] border-gray-200'>
            <button type='button' className='btn-md-disabled' onClick={()=>onClose(false)}>Cancel</button>
            <button form={formName} disabled={submitting} type='submit' className='ml-4 btn-md'>{submitTitle}</button>
        </div>
    </div>
      </div>
</div>
  )
}
