import { useEffect, useState } from "react"
import Input from "../../../components/Input"
import Map from "../../../components/Map"
import Popup from "../../../components/Popup"
import Select from "../../../components/Select"
import { authAxios } from "../../../helpers/axios"
import { Countries } from "../../../helpers/constants"

export default function ManageSponsor({onClose,update=false,onSubmit,updateData})
{
    const [consultants,setConsultants] = useState([])
    const [manageData,setManageData] = useState(!update ? {name:'',uniqueNumber:'',direct:true,consultantId:'',city:'',countryCode:'IN',contactPerson:'',contactNumber:'',contactEmail:'',city:'',address:'',status:1,contactType:0} : {...updateData,uniqueNumber:'',useOwnNumber:parseInt(updateData.useOwnNumber),city:updateData.city,countryCode:updateData.countryCode,contactPerson:updateData.contactPerson,contactNumber:updateData.contactNumber,contactEmail:updateData.contactEmail,city:updateData.city,address:updateData.city,address:updateData.address,status:updateData.status,direct:updateData.status,consultantId:updateData.consultantId,})
    const formName = 'editConsultant';

      

    useEffect(()=>
    {
        async function getConsultants(){
            let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/consultant`)
            setConsultants(res.data)
        }
        getConsultants()
    },[])




    return <Popup title={update ? 'Update Sponsor' : 'Create Sponsor'} submitTitle={update ? 'Update' : 'Create'} onClose={onClose} formName={formName}>
        <form onSubmit={(e)=>onSubmit(e,manageData)} id={formName} className=' w-full'>
            <div className='grid grid-cols-4 gap-x-2 gap-y-0 w-full'>
            <div className="col-span-2">
                <label>Company Name</label>
                <Input type='text' required={true} placeholder={'Enter Company Name'} value={manageData.name} setValue={(value)=>setManageData(data=>({...data,name:value}))}/>
            </div>
            <div className="col-span-2">
                <label>Unique Id</label>
                <Input type='text' required={true} placeholder={'Enter Unique Id'} value={manageData.uniqueNumber} setValue={(value)=>setManageData(data=>({...data,uniqueNumber:value}))}/>
            </div>
            <div className="col-span-2">
                <label>Customer Type?</label>
                <Select options={[{name:'Direct',value:1},{name:'Consultant',value:0}]} setValue={(value)=>setManageData((data)=>({...data,direct:parseInt(value)}))} required={true}></Select>
            </div>
            <div className="col-span-2">
                <label className={`${manageData.direct === 1 ? 'opacity-20' : 'opacity-100'}`}>Consultant</label>
                <Select viewMode={manageData.direct === 1 ? true : false} options={consultants} customLabel={'name'} customValue={'id'} setValue={(value)=>setManageData((data)=>({...data,consultantId:value}))} required={true}></Select>
            </div>
            <div className="col-span-2">
                <label>Contact Person</label>
                <Input type='text' required={true} placeholder={'Enter Contact Person'} value={manageData.contactPerson} setValue={(value)=>setManageData(data=>({...data,contactPerson:value}))}/>
            </div>
            <div className="col-span-2">
                <label>Contact Number</label>
                <Input type='text' required={true} placeholder={'Enter Contact Number'} value={manageData.contactNumber} setValue={(value)=>setManageData(data=>({...data,contactNumber:value}))}/>
            </div>
            <div className="col-span-2">
                <label>Contact Email</label>
                <Input type='text' required={true} placeholder={'Enter Email'} value={manageData.contactEmail} setValue={(value)=>setManageData(data=>({...data,contactEmail:value}))}/>
            </div>
            <div className="col-span-2">
                <label>Country</label>
                <Select options={Countries} customLabel={'name'} customValue={'code'} setValue={(value)=>setManageData((data)=>({...data,countryCode:value}))} required={true}></Select>
            </div>
            <div className="col-span-2">
                <label>City</label>
                <Input type='text' placeholder={'Enter City'} value={manageData.city} required={true} setValue={(value) =>setManageData(data=>({...data,city:value}))}/>
            </div>
            <div className="col-span-2">
                <label>Address</label>
                <Input type='text' placeholder={'Enter Address'} value={manageData.address} setValue={(value)=>setManageData(data=>({...data,address:value}))} required={true}/>
            </div>
            <div className="col-span-2">
                <label>Status</label>
                <Select options={[{label:'Active',value:1},{label:'Inactive',value:0}]} customLabel={'label'} customValue={'value'} setValue={(value)=>setManageData((data)=>({...data,status:value}))} required={true}></Select>
            </div>
            </div>
        </form>
    </Popup>
}