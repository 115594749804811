import React, { useEffect, useState } from 'react'
import Header from '../../../components/Header'
import SearchInput from '../../../components/SearchInput'
import { authAxios } from '../../../helpers/axios'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { getDateFormat } from '../../../helpers/utils'
import { LIMIT } from '../../../helpers/constants'
import ReactDataGrid from 'react-data-grid';
import { useSelector } from 'react-redux'

export default function ConsultantStudies({id}) {

    const [searchText,setSearchText] = useState('')
    const [projects,setProjects] = useState([])
    const navigation = useNavigate()
    const [sort,setSort] = useState('name');
    const authInfo = useSelector(state=>state.auth)
    const [offset,setOffset] = useState(0);
    const [count,setCount] = useState(0)

    const columns = [
        { key: 'studyNumber', name: 'Study Number' ,frozen:true,width: 150,renderCell:({row})=><p >{row.consultantId ? row.consultant.uniqueNumber : row.sponsor.uniqueNumber}/{row.studyNumber}</p>},
        { key: 'studyName', name: 'Study Name' ,frozen:true,width: 250,renderCell:({row})=><p >{row.test.name}</p>},
        { key: 'testItemName', name: 'Test Item' ,frozen:true,width: 200,renderCell:({row})=><p >{row.project.tids.testItemName}</p>},
        { key: 'sponsor', name: 'Sponsor' ,width: 200,renderCell:({row})=><p>{row.sponsor.name}</p>},
        { key: 'sdAssigned', name: 'Study Director',width:150,renderCell:({row})=><p>{row.sdAssigned ? row.sd.name : '-'}</p>},
        { key: 'sdApproval', name: 'SD Approval',width:200,renderCell:({row})=><p>{row.sdApproved ? 'Approved' : 'Not Yet Approved'}</p>},
        { key: 'consultant', name: 'Consultant' ,width: 250,renderCell:({row})=><p>{row.consultant ? row.consultant.name : ''}</p>},
        { key: 'createdAt', name: 'Created On',width:200,renderCell:({row})=><p>{getDateFormat(row.createdAt)}</p>},
      ];
    
    const RightContent = ()=>
    {
        return <>
        <div className='flex justify-end'>
            <div className="mr-2">
                <SearchInput value={searchText} setValue={(value)=>setSearchText(value)} placeholder={'Search Study'} label={'Search Study'} />
            </div>
            </div>
            </>
    }

    const onSortPress = (type)=>
    {
        if (sort === type) {
            if(sort === `-${type}`) setSort(type)
            else setSort(`-${type}`);
          } else {
            setSort(type);
          }
    }

    async function getProjects(){

        try 
        {
            let query = `&limit=${LIMIT}&offset=${offset}&orderBy=${sort}`;
                if(searchText) query+= `&search=${searchText}`;
                query+=`&sponsorId=${id}`
                if(authInfo.role === 'sd') query+=`&sdId=${authInfo.id}`
            let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/study?populate=true${query}`)
            if(res.data)
            {
                setProjects(res.data)
                setCount(res.data.count)
            }
        } catch (error) {
            toast('Error getting projects')
        }
    }
    useEffect(()=>
    {
        getProjects();
    },[sort,searchText,offset])


  return (
    <>
        <div className='block mt-4 flex-1 bg-white w-full overflow-scroll'>
        <ReactDataGrid
        rowHeight={40}
        onCellClick={(args)=>navigation(`/study/${args.row.id}`)}
        rows={projects}
        className='w-full'
      columns={columns}
      enableCellSelect={false}
    />
        </div>
    </>
  )
}

