import React, { useEffect, useRef, useState } from 'react'
import { Link, NavLink, Outlet, useLocation, useNavigate } from 'react-router-dom'
import Logo from '../images/logo.png'
// import Menu from '../images/menu-icon.svg'
import DashboardIcon from '../images/dashboard.svg'
import OrdersIcon from '../images/orders.svg'
import SalesRepIcon from '../images/sales-rep.svg'
import ProductsIcon from '../images/products.svg'
import RouteIcon from '../images/route.svg'
import SettingsIcon from '../images/settings.svg'
import LogoutIcon from '../images/logout.svg'
import LeaderboardIcon from '../images/leaderboard.svg'
import DistributorIcon from '../images/distributor.svg'
import TeamIcon from '../images/team.svg'
import UpIcon from '../images/up.svg'
import RetailerIcon from '../images/retailers.svg'
import { useDispatch, useSelector } from 'react-redux'
import { logout, updateNotificationCheck, updateUser } from '../redux/slice/authSlice'
import { PiBellBold, PiBellFill, PiBuildings, PiBuildingsFill, PiCaretDownBold, PiClipboardTextLight, PiFaders, PiFadersFill, PiNotification, PiNotificationFill, PiTimer, PiTimerFill, PiUserSwitch, PiUserSwitchFill, PiUsersFour, PiUsersFourFill, PiUsersFourLight } from "react-icons/pi";
import { RiDashboard3Fill, RiDashboard3Line, RiTimeLine, RiTimerLine } from "react-icons/ri";
import { CiBoxes, CiGrid41 } from "react-icons/ci";
import { FaBoxes } from "react-icons/fa";
import { IoDocumentTextOutline,IoDocumentText } from "react-icons/io5";
import { BsBell, BsBellFill, BsClipboard2Data, BsClipboardDataFill, BsGrid3X2Gap, BsGrid3X2GapFill } from "react-icons/bs";
import Loader from './Loader'
import { authAxios, setAuthToken } from '../helpers/axios'
import { ErrorToast, InfoToast } from '../helpers/toasters'
import { HiOutlineCheckCircle,HiCheckCircle } from "react-icons/hi";
import { BiNotification } from 'react-icons/bi'


const SidebarMenuSubItem = ({active,setActive,data,collapsed})=>
{
    return  <div className={`pl-4 menu-sub-item relative my-3 mb-2 ${collapsed ? 'pl-0' : 'pl-4'}`}>
        <NavLink to={data.url} className={({ isActive, isPending }) =>
    isActive ? " inline-block menu-sub-active group active" : "inline-block"
  }>
            <div className={`w-auto mx-auto flex rounded-md items-center group-[.menu-sub-active]:bg-[#313B53] transition-all duration-[300ms] ${collapsed ? 'ml-4 px-4' : 'ml-10 px-4'}`}>
            <div className='py-3'>
            </div>
            <div className={`${!collapsed ? 'ml-3' :'ml-0'}`}>
                <p className={`text-[13px] whitespace-nowrap font-normal transition-all duration-[300ms] text-gray-300 group-[.active]:text-white ${!collapsed ? 'w-auto opacity-100' :'w-0 overflow-hidden opacity-0'}`}>{data.label}</p>
            </div>
        </div>
        </NavLink>
        </div>
}

const SidebarMenuItem = ({active,setActive,data,collapsed})=>
{
    return  <div className={`menu-item transition-all relative  ${active === data.id && data.childrens && data.childrens.length >0 ? 'menu-item-active border-b-stone-700 border-b-[1px]' : ''}`} onClick={()=>setActive(data.id)}>
        <NavLink to={data.url} className={({ isActive, isPending }) =>
        {
            if(isActive) setActive(data.id);
            return isActive ? " block group parent-active opacity-100" : "filter grayscale"
        }
}>
            <div className={`flex mx-4 px-4  rounded-md items-center ${active === data.id ? '' : ''}`}>
      {/* {active === data.id ? <div className='h-full w-[6px] bg-[#182159] absolute top-0 left-0 rounded-r-full'></div> : null} */}
                <div className='w-full flex items-center'>
                    <div className='py-3'>
                        <img src={data.icon} className='w-5 h-5 '/>
                    </div>
                    {!collapsed ? <div className='ml-4'>
                        <p className='text-[13px] tracking-[-.15px] font-regular text-black '>{data.label}</p>
                        {/* <p className='text-[13px] tracking-[-.15px] font-medium text-[#757575] hover:text-[#182159] group-[.parent-active]:text-[#182159]'>{data.label}</p> */}
                    </div> : null}
                </div>
                {!collapsed && data.childrens && data.childrens.length > 0 ? <div className=''>
                    <img src={UpIcon} className={`w-3 h-3 ${active !== data.id ? 'origin-center rotate-180' : ''}`}/>
                </div> : null}
        </div>
        </NavLink>
        {
            data.childrens && data.childrens.length > 0 ? <div className={`overflow-hidden transition-all ${active !== data.id ? 'h-0 ' : 'h-full py-4'}`}>
            {
                data.childrens.map((item,index)=>
                {
                    return <SidebarMenuSubItem data={item} key={index} active={active} collapsed={collapsed}/>
                })
            }
        </div> : null
        }
        </div>
}
export default function Sidebar() {

    const [activeMenu,setActiveMenu] = useState(1)
    const [collapsed,setCollapsed] = useState(false)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const authInfo = useSelector(state=>state.auth)
    const [loading,setLoading] = useState(true)
    const [showMenu,setShowMenu] = useState(false)
    const ref = useRef()
    const [messages, setMessages] = useState([]);
    const [input, setInput] = useState('');
    let ws;

    
    const {pathname} = useLocation();
    
    // useEffect(()=>{
        //     if(!authInfo.isLoggedIn || !authInfo.token) return navigate('/login')
        // },[authInfo])
        
        useEffect(()=>{
            async function precheck()
            {
                try 
                {
                    setAuthToken(authInfo.token)
                    setLoading(true)
                    let res = await authAxios.post('/user/precheck');
                    dispatch(updateUser({...res.data.user}))
                    setLoading(false) 
                } catch (error) {
                    setLoading(false)
                    InfoToast(error.response.data.status === 500 ? 'Email/Password Incorrect' : 'Error Logging In')
                }
            }
            
            precheck()
    },[])



  useEffect(() => {
    const fetchLastNotification = async () => {
      try {
        // Replace with your API call or notification fetching logic
        const response = await authAxios.get('/notification/last-notification');
        // const data = await response.json();
        const { id } = response.data; // Assuming the API returns the lastNotificationId in the response

        if (id && id !== authInfo.lastNotification) {
          console.log('cecking',id,'-',authInfo.lastNotification)
          dispatch(updateNotificationCheck({notificationCheck:true,newId:id}));
        }
        else dispatch(updateNotificationCheck({notificationCheck:false,newId:null}));
      } catch (error) {
        console.error('Error fetching last notification:', error);
        ErrorToast("Error Fetching Background Notification")
      }
    };

    const intervalId = setInterval(fetchLastNotification, 10000); // 10 seconds interval

    return () => clearInterval(intervalId); // Clean up interval on component unmount
  }, [dispatch, authInfo.lastNotificationId]);

    // useEffect(() => {
    //   // Connect to WebSocket server
    //   ws = new WebSocket(process.env.REACT_APP_WS_URL);
  
    //   ws.onopen = () => {
    //     console.log('Connected to server');
    //   };
  
    //   ws.onmessage = (event) => {
    //     setMessages((prevMessages) => [...prevMessages, event.data]);
    //   };
  
    //   ws.onclose = () => {
    //     console.log('Disconnected from server');
    //   };
  
    //   // Clean up WebSocket connection on component unmount
    //   return () => {
    //     ws.close();
    //   };
    // }, []);
  

    const onLogout = ()=>
    {
        dispatch(logout())
    }

    const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          setShowMenu(false);
        }
      };
    
      useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
      }, []);
    
      const toggleOpen = () => {
        setShowMenu(prevState => !prevState);
      };

    const data = [
        {
            id:1,
            label:'Dashboard',
            icon:DashboardIcon,
            childrens:null,
            url:'/'
        },
        {
            id:11,
            label:'Notification',
            icon:OrdersIcon,
            childrens:null,
            url:'/notification'
        },
        {
            id:2,
            label:'Case Study',
            icon:OrdersIcon,
            childrens:null,
            url:'/orders'
        },
        {
            id:3,
            label:'Users',
            icon:SalesRepIcon,
            url:'/users',
        },
        {
            id:10,
            label:'Teams',
            icon:TeamIcon,
            childrens:null,
            url:'/teams'
        },
        {
            id:9,
            label:'Leaderboard',
            icon:LeaderboardIcon,
            childrens:null,
            url:'/leaderboard'
        },
        {
            id:4,
            label:'Sponsors',
            icon:DistributorIcon,
            childrens:null,
            url:'/sponsors'
        },
        {
            id:5,
            label:'Consultants',
            icon:RetailerIcon,
            childrens:null,
            url:'/consultants'
        },
        {
            id:6,
            label:'Products',
            icon:ProductsIcon,
            childrens:null,
            url:'/products'
        },
        {
            id:7,
            label:'Route Schedule',
            icon:RouteIcon,
            childrens:null,
            url:'/route-schedule'
        },
        {
            id:8,
            label:'Settings',
            icon:SettingsIcon,
            childrens:null,
            url:'/settings'
        },
        {
            id:9,
            label:'TIDS',
            icon:SettingsIcon,
            childrens:null,
            url:'/tids'
        },
        {
            id:10,
            label:'Samples',
            icon:SettingsIcon,
            childrens:null,
            url:'/samples'
        }
        
    ]
    const settingsMenu = [
      {
          id:101,
          label:'Users',
          // icon:<HomeIcon height={20} width={20}/>,
          childrens:null,
          url:'/users'
        },
        {
          id:102,
          label:'Settings',
          // icon:<Settings height={20} width={20}/>,
          childrens:null,
          url:'/orders'
      },
  ]

  if(!authInfo.isLoggedIn || !authInfo.token) return navigate('/login');
  return (
    !loading ? <div className='w-auto h-screen flex transition-all overflow-hidden'>
      <div className='h-screen relative w-full max-w-[280px]'>
        <div className={`hidden md:block bg-[#fff] border-r border-r-[#efefef] shrink-0 max-w-[280px] transition-all duration-[300ms] w-full h-screen overflow-scroll px-4 py-2 pb-20`}>
          {!collapsed ? <img src={Logo} className='h-12 w-auto'/> : null}

          <div onClick={toggleOpen} ref={ref} className='relative z-10  flex items-center bg-white hover:bg-[#f3f3f3] rounded-md py-2 px-3 transition-all cursor-pointer border border-gray-100 my-6'>
            <div className='bg-[#182159] h-8 w-8 rounded-full mr-2 flex items-center justify-center'>
                <p className='text-sm text-white font-semibold'>{authInfo?.userName[0]}</p>
            </div>
            <div className=''>
              <div className='flex justify-between w-full items-center'>
                <div className='mr-2 w-full'>
                  <p className='text-sm font-medium tracking-tight capitalize mb-0'>{authInfo?.userName}</p>
                  <p className='text-[11px] font-medium capitalize text-gray-400 tracking-tight mt-0'>{authInfo?.role} Department</p>
                </div>
              <div className='my-auto '>
                  <PiCaretDownBold color='#959595'/>
              </div>
              </div>
              <div className={`${showMenu ? 'block' : 'hidden'} absolute bg-white border border-gray-200 rounded-md top-0 left-0 w-full pt-16 -z-10`}>
                <div className='py-3 px-4 hover:bg-[#f9f9f9]' onClick={()=>dispatch(logout())}>
                  <p className='text-sm font-medium tracking-tight capitalize mb-0'>Logout</p>
                </div>
              </div>
            </div>
        </div>

          <div className='py-2'>
            <div className='w-auto px-2 font-medium py-4 pb-2'>
                 {authInfo.role === ''} <Item data={{label:'Dashboard',url:'/'}} ImageComponent={()=><RiDashboard3Line fontSize={18}/>} ActiveComponent={()=><RiDashboard3Fill fontSize={18}/>}/>
                 
                 <Item data={{label:'Notifications',url:'/notifications'}} ImageComponent={()=><PiBellBold fontSize={18}/>} ActiveComponent={()=><PiBellFill fontSize={18}/>}/>

                 {authInfo.role === 'tfm' || authInfo.role === 'tico' ? <Item data={{label:'Projects',url:'/project'}} ImageComponent={()=><BsClipboard2Data fontSize={18}/>} ActiveComponent={()=><BsClipboardDataFill fontSize={18}/>}/> : null}
                  
                  {authInfo.role === 'tfm' || authInfo.role === 'sd' || authInfo.role === 'qa' ?<Item data={{label:'Studies',url:'/study'}} ImageComponent={()=><BsClipboard2Data fontSize={18}/>} ActiveComponent={()=><BsClipboardDataFill fontSize={18}/>}/> : null}
                  
                  {authInfo.role === 'qa-head' ?<Item data={{label:'QA Assign Request',url:'/qa-assign-request'}} ImageComponent={()=><BsClipboard2Data fontSize={18}/>} ActiveComponent={()=><BsClipboardDataFill fontSize={18}/>}/> : null}

                  {authInfo.role === 'tfm' ? <Item data={{label:'Sponsors',url:'/sponsors'}} ImageComponent={()=><PiBuildings fontSize={18}/>} ActiveComponent={()=><PiBuildingsFill fontSize={18}/>}/> : null}

                  {authInfo.role === 'tfm' ? <Item data={{label:'Consultants',url:'/consultants'}} ImageComponent={()=><PiUserSwitch fontSize={18}/>} ActiveComponent={()=><PiUserSwitchFill fontSize={18}/>}/> : null}
                
                  {authInfo.role === 'tico' || authInfo.role === 'tfm' ? <Item data={{label:'Project Request',url:'/project-request'}} ImageComponent={()=><BsGrid3X2Gap fontSize={18}/>} ActiveComponent={()=><BsGrid3X2GapFill fontSize={18}/>}/> : null}
                  
                  {/* RD */}
                  {authInfo.role === 'rd' || authInfo.role === 'tfm' ? <Item data={{label:'Tech Info Requests',url:'/tech-request'}} ImageComponent={()=><BsGrid3X2Gap fontSize={18}/>} ActiveComponent={()=><BsGrid3X2GapFill fontSize={18}/>}/> : null}
                
                    <div className='w-auto border-b-0 border-b-gray-200 py-4 pb-2'>

                  {authInfo.role === 'tico' || authInfo.role === 'tfm' ? <Item data={{label:'Sample Management',url:'/samples'}} ImageComponent={()=><CiBoxes fontSize={18}/>} ActiveComponent={()=><FaBoxes fontSize={18}/>}/> : null}
                  
                  {authInfo.role === 'tico' || authInfo.role === 'tfm' || authInfo.role === 'sd' ? <Item data={{label:'Sample Requests',url:'/sample-requests'}} ImageComponent={()=><HiOutlineCheckCircle fontSize={18}/>} ActiveComponent={()=><HiCheckCircle fontSize={18}/>}/> : null}
                  
                  {authInfo.role === 'tico' || authInfo.role === 'tfm' ? <Item data={{label:'Warehouse Tracker',url:'/warehouse-tracker'}} ImageComponent={()=><BsGrid3X2Gap fontSize={18}/>} ActiveComponent={()=><BsGrid3X2GapFill fontSize={18}/>}/> : null}
                  
                  
                
                </div>

                  {/* {authInfo.role === 'tfm' || authInfo.role === 'rd' ? <Item data={{label:'TIDS',url:'/tids'}} ImageComponent={()=><IoDocumentTextOutline fontSize={18}/>} ActiveComponent={()=><IoDocumentText fontSize={18}/>}/> : null} */}

                  {authInfo.role === 'tfm' ?  <Item data={{label:'Users',url:'/users'}} ImageComponent={()=><PiUsersFour fontSize={18}/>} ActiveComponent={()=><PiUsersFourFill fontSize={18}/>}/> : null}

                  {authInfo.role === 'tfm' ? <Item data={{label:'Settings',url:'/settings'}} ImageComponent={()=><PiFaders fontSize={18}/>} ActiveComponent={()=><PiFadersFill fontSize={18}/>}/> : null}
            </div>
          </div>
        </div>
        {/* <div className='absolute bottom-0 left-0 p-2 w-full flex items-center'>
            <div className='shadow-lg shadow-gray-300 border-0 bg-orange-300 border-gray-100 rounded-md px-4 py-5 w-full h-[32px] flex items-center'>
              <p className='text-xs font-medium'>Live Connection - Active</p>
            </div>
          </div> */}
        </div>
        <div className='px-6 flex flex-col md:mt-0 h-screen overflow-hidden w-full bg-white'>
            <Outlet/>
        </div>
    </div> : <Loader/>
  )
}

const Item = ({active,setActive,data,ImageComponent,ActiveComponent})=>
{
  const authInfo = useSelector(state=>state.auth)
    return  <div className={`menu-sub-item relative mb-2`}>
        <NavLink to={data.url} className={({ isActive, isPending }) =>
    isActive ? " block menu-sub-active group active" : "block"
  }>
            <div className={`w-auto mx-auto flex rounded-lg items-center group-[.menu-sub-active]:bg-[#f3f3f3] group-[.menu-sub-active]:border-gray-100 border border-transparent transition-all duration-[300ms] px-4 py-2`}>
            <div className='hidden group-[.menu-sub-active]:block'>
              <ActiveComponent/> 
            </div>
            <div className='block group-[.menu-sub-active]:hidden'> 
              <ImageComponent/>
            </div>
            <div className={'ml-3'}>
                <p className={`text-[13px] whitespace-nowrap font-regular tracking-tight transition-all duration-[300ms] text-black group-[.active]:text-black w-auto opacity-100`}>{data.label}</p>
            </div>
        </div>
        {data.label === 'Notifications' && authInfo.notificationCheck ? <div className='absolute top-0 z-10 right-0 h-full w-full flex items-center justify-end px-4 '>
          <div className='h-[16px] w-[16px] rounded-full bg-[#ff0000] bg-opacity-50 p-[4px] flex items-center justify-center'>
            <div className='h-full w-full rounded-full bg-[#ff0000]'>

            </div>
          </div>
        </div> : null}
        </NavLink>
        </div>
}