import React, { useEffect, useState } from 'react'
import Header from '../../components/Header'
import SearchInput from '../../components/SearchInput'
import { authAxios } from '../../helpers/axios'
import { toast } from 'react-toastify'
import FilterSelect from '../../components/FilterSelect'
import { useNavigate } from 'react-router-dom'
import { base64ToBlob, dataURItoBlob, getDateFormat } from '../../helpers/utils'
import { ErrorToast, InfoToast } from '../../helpers/toasters'
import SortIcon from '../../images/sort.svg'
import RightIcon from '../../images/right.svg'
import FilterIcon from '../../images/filter.svg'
import RupeeIcon from '../../images/rupee.svg'
import { LIMIT } from '../../helpers/constants'
import ManageProject from './components/ManageProject'
import ReactDataGrid from 'react-data-grid';
import { PiPlusBold, PiUserPlus } from 'react-icons/pi'
import { FiFilter } from 'react-icons/fi'
import { useSelector } from 'react-redux'
import { IoFilterCircleOutline } from 'react-icons/io5'
import Pagination from '../../components/Pagination'

export default function Project() {

    const [searchText,setSearchText] = useState('')
    const [projects,setProjects] = useState([])
    const navigation = useNavigate()
    const [showCreate,setShowCreate] = useState({status:false,edit:null})

    const [cityFilters,setCityFilters] = useState([])
    const [routeFilters,setRouteFilters] = useState([])
    const [sort,setSort] = useState('name');
    const [offset,setOffset] = useState(0);
    const [count,setCount] = useState(0)

    const [filters,setFilters] = useState({routeId:null,country:null,status:null})
    const [showFilters,setShowFilters] = useState(false)
    const authInfo = useSelector(state=>state.auth)

    const columns = [
        // { key: 'id', name: 'Sample ID', frozen: true, width: 150},
        { key: 'testItemName', name: 'Test Item' ,width: 250,renderCell:({row})=><p >{row.tids.testItemName}</p>},
        { key: 'sponsor', name: 'Sponsor' ,width: 250,renderCell:({row})=><p>{row.sponsor.name}</p>},
        { key: 'consultant', name: 'Consultant' ,width: 250,renderCell:({row})=><p>{row.consultant ? row.consultant.name : ''}</p>},
        { key: 'mode', name: 'Mode' ,width: 100,renderCell:({row})=>{return row.draft ? <p className='text-orange-400 font-medium'>Draft</p> : <p className='text-green-600 font-medium'>Live</p>}},
        { key: 'createdAt', name: 'Created On',width:200,renderCell:({row})=><p>{getDateFormat(row.createdAt)}</p>},
        { key: 'sponsorName', name: 'Sponsor Name' ,width: 150,frozen:true,renderCell:({row})=><p>{row.sponsor.name}</p>},
      ];
    

    const [selectedFilters,setSelectedFilters] = useState({city:'',route:''})
    const RightContent = ()=>
    {
        return <>
        <div className='flex justify-end'>
                <Pagination count={count} setOffset={setOffset} offset={offset}/>
            <div className="flex items-center mr-2">
                <button className='rounded-md bg-[#f3f3f6] hover:bg-[#efefef] transition-all p-2.5 flex items-center justify-center' onClick={()=>setShowCreate({status:true,edit:false})}><FiFilter fontSize={20} lightingColor={'#fff'} color='#182159'/></button>
            </div>
            <div className="mr-2">
                <SearchInput value={searchText} setValue={(value)=>setSearchText(value)} placeholder={'Search Project'} label={'Search Project'} />
            </div>
            {authInfo.role === 'tfm' ? <div className="flex items-center">
                <button className='rounded-md bg-[#e6e6e6] hover:bg-[#c3c3c3] transition-all p-2.5 flex items-center justify-center' onClick={()=>setShowCreate({status:true,edit:false})}><PiPlusBold fontSize={20} lightingColor={'#fff'} color='#182159'/></button>
            </div> : null}
            </div>
            </>
    }

    const onSortPress = (type)=>
    {
        if (sort === type) {
            if(sort === `-${type}`) setSort(type)
            else setSort(`-${type}`);
          } else {
            setSort(type);
          }
    }

    async function getProjects(){

        try 
        {
            let query = `&limit=${LIMIT}&offset=${offset}&orderBy=${sort}&mode=all`;
            if(searchText) query+= `&search=${searchText}`;
            if(filters.country) query+=`&country=${filters.country.id}`
                // if(filters.routeId) query+=`&routeId=${filters.routeId.id}`
                // if(filters.status) query+=`&status=${filters.status}`
                if(sort.status) query+=`&sort=${'-createdAt'}`
            let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/project?populate=true${query}`)
            if(res.data)
            {
                setProjects(res.data.projects)
                setCount(res.data.totalCount)
            }
        } catch (error) {
            toast('Error getting projects')
        }
    }
    useEffect(()=>
    {
        getProjects();
    },[sort,searchText,offset])


    const onSubmit = async(e,data)=>
    {
        try 
        {
            e.preventDefault()

            let res;
            if(showCreate.edit)
            {
                res = await authAxios.put(`${process.env.REACT_APP_BASE_URL}/project/${showCreate.edit}`,data) 
                InfoToast('Project Updated')
                
            }
            else
            {
                const formData = new FormData();
                console.log('formdata',data.file)
                formData.append('file', data.file);
                formData.append('testItemName',data.testItemName)
                formData.append('sponsorId',data.sponsorId)
                formData.append('sponsorId',data.sponsorId)
                formData.append('uniqueNumber',data.uniqueNumber)
                formData.append('tests',data.tests)
                res = await authAxios.post(`${process.env.REACT_APP_BASE_URL}/project`,data,{
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }})
                InfoToast('Project Created')
            }
            if(res.data)
            {
                await getProjects()
                setShowCreate({status:false,edit:null})
            }
            else toast('error updating project')
        } catch (error) {
            // console.log(error.response.data.error[0])
            ErrorToast(error.response.data.error[Object.keys(error.response.data.error)[0]])
        }
    }


  return (
    <>
        <Header title={'Project Management'} RightContent={RightContent}/>
        <div className='flex flex-1 bg-white w-full overflow-scroll h-full'>
        <ReactDataGrid
        rowHeight={40}
        onCellClick={(args)=>navigation(`/project/${args.row.id}`)}
        rows={projects}
        className='w-full h-full'
      columns={columns}
      enableCellSelect={false}
    />
        </div>
        { showCreate.status ? <ManageProject onClose={setShowCreate} onSubmit={onSubmit} edit={showCreate.edit}/> : null}
    </>
  )
}

