import React, { useEffect, useState } from 'react'
import Header from '../../components/Header'
import SearchInput from '../../components/SearchInput'
import { authAxios } from '../../helpers/axios'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import ManageConsultant from './components/ManageConsultant'
import FilterIcon from '../../images/filter.svg'
import FilterActiveIcon from '../../images/filteractive.svg'
import SortIcon from '../../images/sort.svg'
import RightIcon from '../../images/right.svg'
import { LIMIT } from '../../helpers/constants'
import { findCountryByCode, getDateFormat } from '../../helpers/utils'
import {Tooltip} from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css'
import { PiUserSwitch } from 'react-icons/pi'
import ReactDataGrid from 'react-data-grid'
import { ErrorToast } from '../../helpers/toasters'


export default function Consultant() {

    const [searchText,setSearchText] = useState('')
    const [consultants,setConsultants] = useState([])
    const [showCreate,setShowCreate] = useState({status:false,edit:false})

    const [cityFilters,setCityFilters] = useState([])
    const [routeFilters,setRouteFilters] = useState([])
    const navigate = useNavigate()

    const [selectedFilters,setSelectedFilters] = useState({city:'',route:''})
    const [offset,setOffset] = useState(0);
    const [count,setCount] = useState(0)

    const [filters,setFilters] = useState({routeId:null,country:null,status:null})
    const [showFilters,setShowFilters] = useState(false)
    const [sort,setSort] = useState('name');

    const columns = [
        { key: 'uniqueNumber', name: 'Consultant ID', frozen: true, width: 150 },
        { key: 'name', name: 'Name' ,width: 250},
        { key: 'countryCode', name: 'Country' ,width: 150,renderCell:({row})=><p>{findCountryByCode(row.countryCode).name}</p>},
        { key: 'city', name: 'City' ,width: 200},
        { key: 'totalStudy', name: 'Total Study' ,width: 100},
        { key: 'contactPerson', name: 'Contact Person' ,width: 200},
        { key: 'contactNumber', name: 'Contact Number' ,width: 250},
      ];

      

    const onFilterSubmit = (filters)=>
    {
        try 
        {
            setFilters({...filters})
            setCount(5)
            setOffset(0)
            setShowFilters(false)
        } catch (error) {
            toast('Order Filtered')
        }
    }

    const clearFilter = ()=>
    {
        try 
        {
            setFilters({routeId:null,country:null,status:null,distributorId:null,retailerId:null,teamId:null,salesRepId:null,startDate:null,endDate:null})
            setShowFilters(false)
        } catch (error) {
            toast('Order Filtered')
        }
    }

    const onSortPress = (type)=>
    {
        if (sort === type) {
            if(sort === `-${type}`) setSort(type)
            else setSort(`-${type}`);
          } else {
            setSort(type);
          }
    }

    const RightContent = ()=>
    {
        return <div className='flex'>
            <div className='flex w-full border rounded-full py-2.5 items-center h-full justify-center text-center mr-2'>
                    <div className='px-2 hover:bg-[#f6f6f6] h-full cursor-pointer flex items-center flex-1 justify-center' onClick={()=>{if (offset > 0) {
                            setOffset(offset - LIMIT);
                        }}}>
                        <img src={RightIcon} className='w-[20px] h-[20px] rotate-180'/>
                    </div>
                    <p className='text-xs whitespace-nowrap w-[80px] justify-center'>{offset + 1}-{Math.min(offset + LIMIT, count)} of {count}</p>
                    <div className='px-2 hover:bg-[#f6f6f6] h-full cursor-pointer flex items-center flex-1 justify-center' onClick={()=>{if (offset + LIMIT < count) {
                            setOffset(offset + LIMIT);
                        }}}>
                        <img src={RightIcon} className='w-[20px] h-[20px]'/>
                    </div>
                </div>
            <SearchInput value={searchText} setValue={(value)=>setSearchText(value)} placeholder={'Search Consultant'} label={'Search Consultant'} />
          <button type='button' className='btn-md ml-2 whitespace-nowrap' onClick={()=>setShowCreate({status:true,edit:false})}>Add Consultant</button>
        </div>
    }

    useEffect(()=>
    {
        console.log('status',showCreate)
    },[showCreate])
    async function getConsultants(){

        try 
        {
                let query = `&limit=${LIMIT}&offset=${offset}&orderBy=${sort}`;
                if(searchText) query+= `&search=${searchText}`;
                if(filters.country) query+=`&country=${filters.country.id}`
                // if(filters.routeId) query+=`&routeId=${filters.routeId.id}`
                // if(filters.status) query+=`&status=${filters.status}`
                // if(sort.status) query+=`&status=${filters.status}`
                let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/consultant?populate=true${query}`)

            // let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/distributor?populate=true`)
            if(res.data)
            {
                setConsultants(res.data)
                setCount(res.data.count)
            } 
        } catch (error) {
            toast('Error getting consultants')
        }
    }
    useEffect(()=>
    {
        getConsultants();
    },[filters,searchText,offset,sort])

    // useEffect(()=>
    // {
    //     async function getCities(){
    //         let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/city`)
    //         setCityFilters(res.data.data)
    //     }
    //     getCities()
    // },[])

    // useEffect(()=>
    // {
    //     async function getRoutes(){
    //         let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/route?routeCity=${selectedFilters.city}`)
    //         if(res.data.data)  setRouteFilters(res.data.data)
    //     }
    //     if(selectedFilters.city !== '') getRoutes()
    //     else {
    //         setRouteFilters([]);
    //         setSelectedFilters(data=>({...data,route:''}))
    //     }
    // },[selectedFilters.city])

    const onSubmit = async(e,data)=>
    {
        try 
        {
            e.preventDefault()
            console.log(data)
            let res = await authAxios.post(`${process.env.REACT_APP_BASE_URL}/consultant`,{...data}) 
            await getConsultants()
            setShowCreate(false)
        } catch (error) {
            console.log(error)
            ErrorToast(error.response.data.name)
        }
    }

  return (
    <>
        <Header title={'Consultants'} RightContent={RightContent}/>
        {/* <div className='flex justify-between items-center py-2 border-[#e3e3e3] bg-white'>
            <div className='flex'>
                <SearchInput value={searchText} setValue={(value)=>setSearchText(value)} placeholder={'Search Consultant'} label={'Search Sales Rep'} />
                <div className='flex w-full border rounded-md py-2.5 items-center h-full justify-center text-center'>
                    <div className='px-2 hover:bg-[#f6f6f6] h-full cursor-pointer flex items-center flex-1 justify-center' onClick={()=>{if (offset > 0) {
                            setOffset(offset - LIMIT);
                        }}}>
                        <img src={RightIcon} className='w-[20px] h-[20px] rotate-180'/>
                    </div>
                    <p className='text-xs whitespace-nowrap w-[80px] justify-center'>{offset + 1}-{Math.min(offset + LIMIT, count)} of {count}</p>
                    <div className='px-2 hover:bg-[#f6f6f6] h-full cursor-pointer flex items-center flex-1 justify-center' onClick={()=>{if (offset + LIMIT < count) {
                            setOffset(offset + LIMIT);
                        }}}>
                        <img src={RightIcon} className='w-[20px] h-[20px]'/>
                    </div>
                </div>
            <div className='flex justify-end h-[100%] self-stretch items-center px-4 border rounded-sm border-[#e3e3e3] hover:bg-[#f6f6f6] cursor-pointer' onClick={()=>setShowFilters(true)}>
            {(filters.cityId || filters.routeId || filters.status) ? <img src={FilterActiveIcon} className={`w-[24px] h-[24px]`} alt='Filter' /> : <img src={FilterIcon} className={`w-[24px] h-[24px]`} alt='Filter' /> }
            </div>
            </div>
            <div>
                <button className='btn-xs' onClick={()=>setShowCreate({status:true,edit:false})}>Create consultant <PiUserSwitch fontSize={16} className='ml-2'/></button>
            </div>
        </div> */}
        <div className='flex flex-1 bg-white w-full overflow-scroll h-full'>
        <ReactDataGrid
        rowHeight={40}
        onCellClick={(args)=>navigate(`/consultants/${args.row.id}`)}
        rows={consultants}
        className='w-full h-full'
      columns={columns}
      enableCellSelect={false}
    />
        </div>
        { showCreate.status == true ? <ManageConsultant onClose={setShowCreate} onSubmit={onSubmit}/> : null}
    </>
  )
}


