import React, { useEffect, useState } from 'react'
import Header from '../../components/Header'
import SearchInput from '../../components/SearchInput'
import { authAxios } from '../../helpers/axios'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import {  getDateFormat } from '../../helpers/utils'
import { ErrorToast, InfoToast } from '../../helpers/toasters'
import { LIMIT, PROJECT_STATUS_SAMPLE_REQUESTED, PROJECT_STATUS_TECH_INFO } from '../../helpers/constants'
import ReactDataGrid from 'react-data-grid';
// import ManageTechRequest from './components/ManageTechRequest'

export default function QaAssignRequest() {

    const [searchText,setSearchText] = useState('')
    const [projects,setProjects] = useState([])
    const navigation = useNavigate()
    const [showCreate,setShowCreate] = useState({status:false,edit:null})

    const [cityFilters,setCityFilters] = useState([])
    const [routeFilters,setRouteFilters] = useState([])
    const [sort,setSort] = useState('-createdAt');
    const [offset,setOffset] = useState(0);
    const [count,setCount] = useState(0)

    // const columns = [
    //     // { key: 'id', name: 'Sample ID', frozen: true, width: 150},
    //     { key: 'testItemName', name: 'Test Item' ,width: 250,renderCell:({row})=><p >{row.tids.testItemName}</p>},
    //     { key: 'sponsor', name: 'Sponsor' ,width: 250,renderCell:({row})=><p>{row.sponsor.name}</p>},
    //     { key: 'consultant', name: 'Consultant' ,width: 150,renderCell:({row})=><p>{row.consultant ? row.consultant.name : ''}</p>},
    //     { key: 'status', name: 'Status' ,width: 200,renderCell:({row})=><p className={`text-xs ${row.qaAssigned ? 'bg-green-300' : 'bg-gray-300'} rounded-full px-3 py-1 inline-block text-center mx-auto`}>{row.qaAssigned ? "QA Assigned" : 'Not Assigned'}</p>},
    //     { key: 'createdAt', name: 'Created On',width:200,renderCell:({row})=><p>{getDateFormat(row.createdAt)}</p>},
    //     { key: 'sponsorName', name: 'Sponsor Name' ,width: 150,frozen:true,renderCell:({row})=><p>{row.sponsor.name}</p>},
    //   ];

      const columns = [
        // { key: 'id', name: 'Sample ID', frozen: true, width: 150},
        { key: 'studyNumber', name: 'Study Number' ,frozen:true,width: 150,renderCell:({row})=><p >{row.consultantId ? row.consultant.uniqueNumber : row.sponsor.uniqueNumber}/{row.studyNumber}</p>},
        { key: 'studyName', name: 'Study Name' ,frozen:true,width: 250,renderCell:({row})=><p >{row.test.name}</p>},
        { key: 'testItemName', name: 'Test Item' ,width: 200,renderCell:({row})=><p >{row.project.tids.testItemName}</p>},
        { key: 'status', name: 'Status' ,width: 200,renderCell:({row})=><p className={`text-xs ${row.qaAssigned ? 'bg-green-300' : 'bg-gray-300'} rounded-full px-3 py-1 inline-block text-center mx-auto`}>{row.qaAssigned ? "QA Assigned" : 'Not Assigned'}</p>},
        { key: 'sdApproval', name: 'SD Approval',width:200,renderCell:({row})=><p>{row.sdApproved ? 'Approved' : 'Not Yet Approved'}</p>},
        { key: 'sponsor', name: 'Sponsor' ,width: 200,renderCell:({row})=><p>{row.sponsor.name}</p>},
        { key: 'sdAssigned', name: 'Study Director',width:150,renderCell:({row})=><p>{row.sdAssigned ? row.sd.name : '-'}</p>},
        { key: 'consultant', name: 'Consultant' ,width: 250,renderCell:({row})=><p>{row.consultant ? row.consultant.name : ''}</p>},
        { key: 'createdAt', name: 'Created On',width:200,renderCell:({row})=><p>{getDateFormat(row.createdAt)}</p>},
      ];
    

    const [selectedFilters,setSelectedFilters] = useState({city:'',route:''})
    const RightContent = ()=>
    {
        return <>
        <div className='flex justify-end'>
            <div className="mr-2">
                <SearchInput value={searchText} setValue={(value)=>setSearchText(value)} placeholder={'Search Request'} label={'Search Request'} />
            </div>
            <div className="flex items-center">
                {/* <button className='rounded-full' onClick={()=>setShowCreate({status:true,edit:false})}><TbSquareRoundedPlusFilled fontSize={40} lightingColor={'#fff'} color='#182159' className='ml-2'/></button> */}
            </div>
            </div>
            </>
    }

    const onSortPress = (type)=>
    {
        if (sort === type) {
            if(sort === `-${type}`) setSort(type)
            else setSort(`-${type}`);
          } else {
            setSort(type);
          }
    }

    async function getProjects(){

        try 
        {
            let query = `&limit=${LIMIT}&offset=${offset}&orderBy=${sort}`;
                // query+= `&status=${PROJECT_ST}`;
                if(searchText) query+= `&search=${searchText}`;
            let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/study?populate=true${query}`)
            if(res.data)
            {
                setProjects(res.data.studies)
                setCount(res.data.totalCount)
            }
        } catch (error) {
            toast('Error getting projects')
        }
    }
    useEffect(()=>
    {
        getProjects();
    },[sort,searchText,offset])


    const onSubmit = async(e,data)=>
    {
        try 
        {
            e.preventDefault()

            let res;
            if(showCreate.edit)
            {
                res = await authAxios.put(`${process.env.REACT_APP_BASE_URL}/project/${showCreate.edit}`,data) 
                InfoToast('Project Updated')
                
            }
            else
            {
                res = await authAxios.post(`${process.env.REACT_APP_BASE_URL}/project/complete-request`,data)
                InfoToast('Project Request Completed')
            }
            if(res.data)
            {
                await getProjects()
                setShowCreate({status:false,edit:null})
            }
            else toast('error updating project')
        } catch (error) {
            // console.log(error.response.data.error[0])
            ErrorToast(error.response.data.error[Object.keys(error.response.data.error)[0]])
        }
    }


  return (
    <>
        <Header title={'QA Assign Request Management'} RightContent={RightContent}/>
        <div className='flex flex-1 bg-white w-full overflow-scroll h-full'>
        <ReactDataGrid
        rowHeight={40}
        onCellClick={(args)=>navigation(`/qa-assign-request/${args.row.id}`)}
        rows={projects}
        className='w-full h-full'
      columns={columns}
      enableCellSelect={false}
    />
        </div>
        {/* { showCreate.status ? <ManageTechRequest onClose={setShowCreate} onSubmit={onSubmit} edit={showCreate.edit}/> : null} */}
    </>
  )
}

