import React, { useEffect, useState } from 'react'
import SearchInput from '../../../components/SearchInput'
import { authAxios } from '../../../helpers/axios'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { LIMIT } from '../../../helpers/constants'
import { findCountryByCode, getDateFormat } from '../../../helpers/utils'
import 'react-tooltip/dist/react-tooltip.css'
import { ErrorToast } from '../../../helpers/toasters'
import ReactDataGrid from 'react-data-grid'
import { PiPlusBold } from 'react-icons/pi'


export default function ConsultantSponsors({id}) {

    const [searchText,setSearchText] = useState('')
    const [sponsors,setSponsors] = useState([])
    const [showCreate,setShowCreate] = useState({status:false,edit:false})

    const [cityFilters,setCityFilters] = useState([])
    const [routeFilters,setRouteFilters] = useState([])
    const navigate = useNavigate()

    const [selectedFilters,setSelectedFilters] = useState({city:'',route:''})
    const [offset,setOffset] = useState(0);
    const [count,setCount] = useState(0)

    const [filters,setFilters] = useState({routeId:null,country:null,status:null})
    const [showFilters,setShowFilters] = useState(false)
    const [sort,setSort] = useState('name');

    const columns = [
        { key: 'uniqueNumber', name: 'Sponsor ID', frozen: true, width: 150 },
        { key: 'name', name: 'Name' ,width: 250},
        { key: 'countryCode', name: 'Country' ,width: 150,renderCell:({row})=><p>{findCountryByCode(row.countryCode).name}</p>},
        { key: 'city', name: 'City' ,width: 200},
        { key: 'totalStudy', name: 'Total Study' ,width: 100},
        { key: 'contactPerson', name: 'Contact Person' ,width: 200},
        { key: 'contactNumber', name: 'Contact Number' ,width: 250},
      ];
      

    const onFilterSubmit = (filters)=>
    {
        try 
        {
            setFilters({...filters})
            setCount(5)
            setOffset(0)
            setShowFilters(false)
        } catch (error) {
            toast('Order Filtered')
        }
    }

    const clearFilter = ()=>
    {
        try 
        {
            setFilters({routeId:null,country:null,status:null,distributorId:null,retailerId:null,teamId:null,salesRepId:null,startDate:null,endDate:null})
            setShowFilters(false)
        } catch (error) {
            toast('Order Filtered')
        }
    }

    const onSortPress = (type)=>
    {
        if (sort === type) {
            if(sort === `-${type}`) setSort(type)
            else setSort(`-${type}`);
          } else {
            setSort(type);
          }
    }

    const RightContent = ()=>
    {
        return <>
        <div className='flex justify-end'>
            <div className="mr-2">
                <SearchInput value={searchText} setValue={(value)=>setSearchText(value)} placeholder={'Search Sponsor'} label={'Search Sponsor'} />
            </div>
            <div className="flex items-center">
                <button className='rounded-full' onClick={()=>setShowCreate({status:true,edit:false})}><PiPlusBold fontSize={40} lightingColor={'#fff'} color='#182159' className='ml-2'/></button>
            </div>
            </div>
            </>
    }

    useEffect(()=>
    {
        console.log('status',showCreate)
    },[showCreate])
    async function getSponsors(){

        try 
        {
                let query = `&limit=${LIMIT}&offset=${offset}&orderBy=${sort}`;
                if(searchText) query+= `&search=${searchText}`;
                query+= `&consultantId=${id}`;
                let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/sponsor?populate=true${query}`)

            // let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/distributor?populate=true`)
            if(res.data)
            {
                setSponsors(res.data)
                setCount(res.data.count)
            } 
        } catch (error) {
            toast('Error getting sponsors')
        }
    }
    useEffect(()=>
    {
        getSponsors();
    },[filters,searchText,offset,sort])

    const onSubmit = async(e,data)=>
    {
        try 
        {
            e.preventDefault()
            console.log(data)
            let res = await authAxios.post(`${process.env.REACT_APP_BASE_URL}/sponsor`,{...data}) 
            if(res.data)
            {
                await getSponsors()
                setShowCreate(false)
            }
            else ErrorToast('error creating sponsor')
        } catch (error) {
            console.log(error)
            ErrorToast(error.response)
        }
    }

  return (
    <>
        <div className='block mt-4 flex-1 bg-white w-full overflow-scroll'>
        <ReactDataGrid
        rowHeight={40}
        onCellClick={(args)=>navigate(`/sponsors/${args.row.id}`)}
        rows={sponsors}
        className='w-full'
      columns={columns}
      enableCellSelect={false}
    />
        </div>
        {/* { showCreate.status == true ? <ManageSponsor onClose={setShowCreate} onSubmit={onSubmit}/> : null} */}
    </>
  )
}
