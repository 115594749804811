import React, { useEffect, useState } from 'react'
import Header from '../../components/Header'
import SearchInput from '../../components/SearchInput'
import { authAxios } from '../../helpers/axios'
import { toast } from 'react-toastify'
import Popup from '../../components/Popup'
import Input from '../../components/Input'
import FilterSelect from '../../components/FilterSelect'
import Select from '../../components/Select'
import Map from '../../components/Map'
import { useParams } from 'react-router-dom'
import TabGroup from '../../components/TabGroup'
import ManageSponsor from './components/ManageProject'
import { ErrorToast, InfoToast } from '../../helpers/toasters'
import Loader from '../../components/Loader'
import ReactDataGrid from 'react-data-grid';
import { getDateFormat } from '../../helpers/utils'
import { useSelector } from 'react-redux'
import LiveProject from './components/LiveProject'

export default function ProjectInfo() {

    const {id} = useParams();
    const [projectInfo,setProjectInfo] = useState([])
    const [showEdit,setShowEdit] = useState(false)
    const [orders,setOrders] = useState([])
    const [activeTab,setActiveTab] = useState('study')
    const [loading,setLoading] = useState(true)
    const [stocks,setStocks] = useState([])
    const [submitting,setSubmitting] = useState(false)
    const authInfo = useSelector((state)=>state.auth)
    const [showLive,setShowLive] = useState(false)

    const RightContent = ()=>
    {
        return <div>
          <button type='button' disabled={submitting} className='btn-md' onClick={()=>onSubmit(true)}>{!submitting ? 'Export Project' : 'Exporting Project...'}</button>
        {projectInfo?.project?.draft && authInfo.role === 'tfm' ?<button type='button' className='btn-md ml-4' onClick={()=>setShowLive(true)}>Move to Live</button> : null}
        </div>
    }

    async function getProjectInfo(){

        try 
        {
            let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/project/${id}?populate=true`)
            if(res.data) setProjectInfo(res.data)
            setLoading(false)
        // let Ordersres = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/orders?distributorId=${id}&populate=true`)
        // if(Ordersres.data.data) setOrders(Ordersres.data.data)
    } catch (error) {
        setLoading(false)
        toast('Error getting Consultant Info')
        }
    }
    useEffect(()=>
    {
        getProjectInfo();
    },[])

    const onSubmit = async()=>
    {
        try 
        {
            setSubmitting(true)
            // e.preventDefault()
            let response = await authAxios.post(`${process.env.REACT_APP_BASE_URL}/project/export/${id}`,{},{responseType:'blob'}) 
            // let response = await axios.post(`${process.env.REACT_APP_BASE_URL}/export`,{},{responseType:'blob'}) 
            // Create a temporary URL for the blob data
                const url = window.URL.createObjectURL(new Blob([response.data]));

                // Create a temporary anchor element
                const anchor = document.createElement('a');
                anchor.href = url;
                anchor.setAttribute('download', 'output.pdf'); // Set the filename for download

                // Append the anchor to the document body and trigger a click event to start download
                document.body.appendChild(anchor);
                anchor.click();

                // Clean up by removing the anchor and revoking the URL
                document.body.removeChild(anchor);
                window.URL.revokeObjectURL(url);
                setSubmitting(false)
            // else toast('error exporting project')
        } catch (error) {
            setSubmitting(false)
            toast(error.response.data)
        }
    }

    const onLive = async(e)=>
    {
        try 
        {
            e.preventDefault()
            setSubmitting(true)
            let response = await authAxios.post(`${process.env.REACT_APP_BASE_URL}/project/live/${id}`) 
            setShowLive(false)
            setSubmitting(false)
        } catch (error) {
            setShowLive(false)
            setSubmitting(false)
            ErrorToast(error.response.data.name)
        }
    }


    const columns = [
        // { key: 'id', name: 'Sample ID', frozen: true, width: 150},
        { key: 'studyNumber', name: 'Study Number' ,width: 150,renderCell:({row})=><p>{projectInfo.project.consultantId && projectInfo.project.consultant.useOwnNumber ? projectInfo.project.consultant.uniqueNumber : projectInfo.project.sponsor.uniqueNumber}/{row.studyNumber}</p>},
        { key: 'testName', name: 'Study Name' ,width: 350,renderCell:({row})=><p>{row.test.name }</p>},
        { key: 'sd', name: 'Study Director' ,width: 200,renderCell:({row})=><p>{row.sdAssigned ? row.sd.name : 'Not Assigned'}</p>},
        { key: 'sdApproval', name: 'SD Approval' ,width: 150,renderCell:({row})=><p>{row.sdApproved ? 'Approved' : 'Not Yet Approved'}</p>},
        { key: 'sampleRequired', name: 'Sample Required' ,width: 100,renderCell:({row})=><p>{row.sampleRequired ? row.sampleRequired : 'Not Specified Yet'}</p>},
        { key: 'sdApprovedOn', name: 'Approved On',renderCell:({row})=><p>{row.sdApprovedOn ? getDateFormat(row.sdApprovedOn) : '-'}</p>}
        // { key: 'expectedDeliveryDate', name: 'Expected Delivery Date',renderCell:({row})=><p>{row.sdApprovedOn ? getDateFormat(row.sdApprovedOn) : '-'}</p>}
      ];
    

  return (
    !loading ? <>
        <Header  parentLink='projects' parentTitle='Projects' title={projectInfo.name} RightContent={RightContent}/>
        <div className='block flex-1 overflow-scroll bg-white w-full'>
            <div className='w-full border-b border-b-gray-300 pb-10'>
                <p className='text-xs uppercase text-gray-400 font-medium mb-4'>Project Information</p>
            
            <div className='grid grid-cols-12 w-full'>
                <div className='col-span-3'>
                    <label>Sponsor</label>
                    <p className='text-sm'>{projectInfo.project?.sponsor?.name} ({projectInfo.project.sponsor.uniqueNumber})</p>
                </div>
                <div className='col-span-3'>
                    <label>Consultant</label>
                    <p className='text-sm'>{projectInfo.project?.consultantId ? `${projectInfo.project.consultant.name} (${projectInfo.project.consultant.uniqueNumber})` : '-'}</p>
                </div>
                <div className='col-span-3'>
                    <label>Sponsor</label>
                    <p className='text-sm'>{projectInfo.project.sponsor?.name}</p>
                </div>
            </div>
            </div>

            <div className='w-full border-b border-b-gray-300 py-10 pt-6'>
                <p className='text-xs uppercase text-gray-400 font-medium mb-4'>Studies Information</p>
                <div className='w-full'>
                <ReactDataGrid
                    rowHeight={40}
                    rows={projectInfo.studies}
                    className='w-full'
                columns={columns}
                enableCellSelect={false}
                />

                </div>
            </div>

            <div>

            </div>
            
        </div>
        { showEdit ? <ManageSponsor onClose={setShowEdit} onSubmit={onSubmit} update={true} updateData={projectInfo}/> : null}
        { showLive ? <LiveProject onClose={setShowLive} onSubmit={onLive}/> : null}
    </> : <Loader/>
  )
}

