import React, { useEffect, useState } from 'react'
import Header from '../../components/Header'
import SearchInput from '../../components/SearchInput'
import { authAxios } from '../../helpers/axios'
import { toast } from 'react-toastify'
import Popup from '../../components/Popup'
import Input from '../../components/Input'
import FilterSelect from '../../components/FilterSelect'
import Select from '../../components/Select'
import Map from '../../components/Map'
import { useNavigate, useParams } from 'react-router-dom'
import TabGroup from '../../components/TabGroup'
import { ErrorToast, InfoToast } from '../../helpers/toasters'
import Loader from '../../components/Loader'
import ReactDataGrid from 'react-data-grid';
import { getDateFormat } from '../../helpers/utils'
import { useSelector } from 'react-redux'
import { PROJECT_STATUS_SD_APPROVAL, STUDY_STATUS_APPROVED,STUDY_STATUS_CREATED } from '../../helpers/constants'
import SlidePopup from '../../components/SlidePopup'
import ManageQaAssignRequest from './components/ManageQaAssignRequest'

export default function StudyInfo() {

    const {id} = useParams();
    const [projectInfo,setProjectInfo] = useState([])
    const [showEdit,setShowEdit] = useState(false)
    const [showVerify,setShowVerify] = useState(false)
    const [showRequest,setShowRequest] = useState(false)
    const authInfp = useSelector(state=>state.auth)
    const [orders,setOrders] = useState([])
    const authInfo = useSelector(state=>state.auth)
    const [activeTab,setActiveTab] = useState('study')
    const [loading,setLoading] = useState(true)
    const [stocks,setStocks] = useState([])
    const navigate = useNavigate()

    const RightContent = ()=>
    {
        return <div>
          {authInfo.role === 'qa-head' && !projectInfo.qaAssigned ? <button type='button' className='btn-md' onClick={()=>setShowEdit(true)}>Assign QA</button>: null}
        </div> 
    }

    async function getProjectInfo(){

        try 
        {
            let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/study/${id}?populate=true`)
            if(res.data) setProjectInfo(res.data)
            setLoading(false)
        // let Ordersres = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/orders?distributorId=${id}&populate=true`)
        // if(Ordersres.data.data) setOrders(Ordersres.data.data)
    } catch (error) {
        setLoading(false)
        toast('Error getting Consultant Info')
        }
    }
    useEffect(()=>
    {
        getProjectInfo();
    },[])

    const onSubmit = async(e,data)=>
    {
        try 
        {
            e.preventDefault()
            let res = await authAxios.post(`${process.env.REACT_APP_BASE_URL}/study/assign-qa/${id}`,data) 
            if(res.data)
            {
                await getProjectInfo()
                navigate('/qa-assign-request')
                setShowEdit(false)
            }
            else toast('error updating distributor')
        } catch (error) {
            toast(error.response.data)
        }
    }
    
    const onQaVerfiy = async(e,data)=>
    {
        try 
        {
            e.preventDefault()
            let res = await authAxios.post(`${process.env.REACT_APP_BASE_URL}/study/verify/${id}`) 
            if(res.data)
            {
                await getProjectInfo()
                navigate('/study')
                setShowEdit(false)
            }
            else toast('error updating distributor')
        } catch (error) {
            toast(error.response.data)
        }
    }

    const onSampleRequest = async(e,data)=>
    {
        try 
        {
            e.preventDefault()
            let res = await authAxios.post(`${process.env.REACT_APP_BASE_URL}/study/request-sample/${id}`,data) 
            if(res.data)
            {
                await getProjectInfo()
                navigate('/qa-assign-request')
                setShowEdit(false)
            }
            else toast('error updating distributor')
        } catch (error) {
            toast(error.response.data)
        }
    }


    const columns = [
        // { key: 'id', name: 'Sample ID', frozen: true, width: 150},
        { key: 'studyNumber', name: 'Study Number' ,width: 150,renderCell:({row})=><p>{projectInfo.consultantId ? projectInfo.consultant.uniqueNumber : projectInfo.sponsor.uniqueNumber}/{row.studyNumber}</p>},
        { key: 'testName', name: 'Study Name' ,width: 350,renderCell:({row})=><p>{row.test.name }</p>},
        { key: 'sd', name: 'Study Director' ,width: 200,renderCell:({row})=><p>{row.sdAssigned ? row.sd.name : 'Not Assigned'}</p>},
        { key: 'sdApproval', name: 'SD Approval' ,width: 150,renderCell:({row})=><p>{row.sdApproved ? 'Approved' : 'Not Yet Approved'}</p>},
        { key: 'sampleRequired', name: 'Sample Required' ,width: 100,renderCell:({row})=><p>{row.sampleRequired ? row.sdApproved : '-'}</p>},
        { key: 'sdApprovedOn', name: 'Approved On',renderCell:({row})=><p>{row.sdApprovedOn ? getDateFormat(row.sdApprovedOn) : '-'}</p>}
        // { key: 'expectedDeliveryDate', name: 'Expected Delivery Date',renderCell:({row})=><p>{row.sdApprovedOn ? getDateFormat(row.sdApprovedOn) : '-'}</p>}
      ];
    

  return (
    !loading ? <>
        <Header  parentLink='study' parentTitle='Study' title={projectInfo.name} RightContent={RightContent}/>
        <div className='block flex-1 overflow-scroll bg-white w-full'>
            <div className='w-full border-b border-b-gray-300 pb-10'>
                <p className='text-xs uppercase text-gray-400 font-medium mb-4'>Sponsor Information</p>
            
            <div className='grid grid-cols-12 w-full'>
                <div className='col-span-3'>
                    <label>Sponsor</label>
                    <p className='text-sm'>{projectInfo.sponsor?.name} ({projectInfo.sponsor.uniqueNumber})</p>
                </div>
                <div className='col-span-3'>
                    <label>Consultant</label>
                    <p className='text-sm'>{projectInfo.consultantId ? `${projectInfo.consultant.name} (${projectInfo.consultant.uniqueNumber})` : '-'}</p>
                </div>
            </div>
            </div>

            <div className='w-full border-b border-b-gray-300 py-10 pt-6'>
                <p className='text-xs uppercase text-gray-400 font-medium mb-4'>Sample Information</p>
                <div className='grid grid-cols-12 gap-y-6 w-full'>
                    <div className='col-span-3'>
                        <label>Status</label>
                        <p className='text-sm'>{projectInfo.sdApproved ? 'Approved' :'Not Approved'}</p>
                    </div>
                    <div className='col-span-3'>
                        <label>Test Item Name</label>
                        <p className='text-sm'>{projectInfo.project?.tids?.testItemName}</p>
                    </div>
                    <div className='col-span-3'>
                        <label>Test Name</label>
                        <p className='text-sm'>{projectInfo.test?.name}</p>
                    </div>
                    <div className='col-span-3'>
                        <label>Sterility Type</label>
                        <p className='text-sm'>{projectInfo.project?.tids?.sterilityType}</p>
                    </div>
                    <div className='col-span-3'>
                        <label>Acceptable to Cut</label>
                        <p className='text-sm capitalize'>{projectInfo.project?.tids?.acceptableToCut ? 'Yes' : 'No'}</p>
                    </div>
                    <div className='col-span-6'>
                        <label>Appearance</label>
                        <p className='text-sm'>{projectInfo.project?.tids?.appearance}</p>
                    </div>
                    <div className='col-span-6'>
                        <label>Description</label>
                        <p className='text-sm'>{projectInfo.project?.tids?.description}</p>
                    </div>
                    <div className='col-span-3'>
                        <label>Sample Storage Condition</label>
                        <p className='text-sm'>{projectInfo.project?.tids?.sampleStorageCondition}</p>
                    </div>
                    <div className='col-span-3'>
                        <label>GLR Storage Condition</label>
                        <p className='text-sm'>{projectInfo.project?.tids?.glrStorageCondition}</p>
                    </div>
                    {projectInfo.project?.tids?.testItemImage ? <div className='col-span-3'>
                        <label>Sample Item Image</label>
                        <img src={`${process.env.REACT_APP_STATIC_URL}/${projectInfo.project?.tids?.testItemImage}`}/>
                    </div> : null}
                </div>
            </div>

            <div>

            </div>
            
        </div>
        { showEdit ? <ManageQaAssignRequest onClose={setShowEdit} onSubmit={onSubmit}/> : null}
    </> : <Loader/>
  )
}